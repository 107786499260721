import { Component, OnDestroy } from '@angular/core';
import { EffectInfosDictionary } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { ComparisonEntryDefinition, ComparisonLcaEntryDefinition, ComparisonLcaRowDefinition, ComparisonRowDefinition } from 'src/app/util/analyses-util/recyclability-table-definitions';
import { Observable, Subscription, firstValueFrom, forkJoin } from 'rxjs';
import { EvaluationGenerationDto } from 'src/app/data-transfer/entities/evaluation-entities/evaluation-generation-dto';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { LifeCycleResult } from 'src/app/model/evaluations/life-cycle-result';
import { PackagingParentDto } from 'src/app/data-transfer/entities/packaging-parent-dto';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { TranslateService } from '@ngx-translate/core';
import { SimpleDialogData, SimpleAlertDialogComponent} from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { DialogActions } from 'src/app/model/dictionary';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditsService } from 'src/app/services/credits-service';
import { RecyclabilityDataSource, RecyclabilityResult } from 'src/app/model/evaluations/recyclability-result';
import { EnvironmentalEffectsEvaluationResultDto } from 'src/app/data-transfer/entities/evaluation-entities/environmental-effects-evaluation-result-dto';
import { RecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/recyclability-result-dto';

@Component({
  selector: 'app-compare-packaging-part',
  templateUrl: './compare-packaging-part.component.html',
  styleUrls: ['./compare-packaging-part.component.scss']
})
export class ComparePackagingPartComponent implements OnDestroy {

  analysesDataLoaded!: Promise<boolean>;
  recTableData: ComparisonRowDefinition[][] = [];
  lcaTableData: ComparisonLcaRowDefinition[][] = [];

  volumeAvailable = false;
  weightAvailable = false;
  newLcaAllowed: boolean[] = [];

  protected routeDataSubscription?: Subscription;
  private dialogSubscription?: Subscription;
  private creditsSubscription?: Subscription;
  private evalSubscription?: Subscription;

  constructor(
    protected analysisApiService: AnalysisApiService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    protected creditsService: CreditsService,
    protected spinner: NgxSpinnerService
  ) { }

  protected setDefaultRecyclabilityData(recyclabilityDto: RecyclabilityResultDto) {
    recyclabilityDto.percentageRatingA = 0;
    recyclabilityDto.percentageRatingB = 0;
    recyclabilityDto.percentageRatingC = 0;
    recyclabilityDto.percentageRatingD = 0;
    recyclabilityDto.percentageRatingX = 0;
    recyclabilityDto.percentageRatingUnknown = 100;
  }

  protected getRelevantEffectKeys(lcaDataSource: LifeCycleResult[]): string[] {
    const allCategories = lcaDataSource.map(x =>
      x.effectRelevances.filter(effect => effect.isRelevant).map(y => y.key));
    const allCategoriesNoDuplicates = [...new Set<string>(([] as string[]).concat(...allCategories))];
    return allCategoriesNoDuplicates;
  }

  protected getAllEvaluatedCountryCodes(packagingPartItems: PackagingParentDto[]): string[] {
    let countryCodes: string[] = [];
    packagingPartItems.forEach(packagingPart => countryCodes = countryCodes.concat(packagingPart.distributionCountries));
    return [...new Set(([] as string[]).concat(...countryCodes))];
  }

  protected createRecyclabilityAnalysis(id: number, version: number, packagingPart: number) {
    this.spinner.show();
    if (!this.creditsService.areRecyclabilityCreditsRunningLow()) {
      this.createRecyclability(id, version, packagingPart);
      return;
    }
    this.creditsSubscription = this.analysisApiService.getRecyclabilityCreditsRequirement(id, packagingPart, version)
      .subscribe(creditsRequired => {
        const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, this.getCreditsDialogConfig(creditsRequired));
        this.dialogSubscription = dialogRef.afterClosed().subscribe(creditsConfirmation => {
          if (creditsConfirmation.event === DialogActions.REJECT) { this.spinner.hide(); return; }
          if(creditsRequired > this.creditsService.creditsCount.lcaCredits) {
            this.spinner.hide();
            this.stopWhenCreditsInsufficient();
            return;
          }
          this.createRecyclability(id, version, packagingPart);
        });
      });
  }

  protected createLifecycleAnalysis(id: number, version: number, packagingPart: number) {
    this.spinner.show();
    if (!this.creditsService.areLcaCreditsRunningLow()) {
      this.createLifeCycle(id, version, packagingPart);
      return;
    }
    this.creditsSubscription = this.analysisApiService.getLifeCycleCreditsRequirement(id, packagingPart, version)
      .subscribe(creditsRequired => {
        const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, this.getCreditsDialogConfig(creditsRequired));
        this.dialogSubscription = dialogRef.afterClosed().subscribe(creditsConfirmation => {
          if (creditsConfirmation.event === DialogActions.REJECT) { this.spinner.hide(); return; }
          if(creditsRequired > this.creditsService.creditsCount.lcaCredits) {
            this.spinner.hide();
            this.stopWhenCreditsInsufficient();
            return;
          }
          this.createLifeCycle(id, version, packagingPart);
        });
      });
  }

  private createRecyclability(id: number, version: number, packagingPart: number) {
    const dto: EvaluationGenerationDto = { id, version };
    this.evalSubscription = this.analysisApiService.getRecyclabilityEvaluationResult(
      dto, packagingPart).subscribe(_ => {
        window.location.reload();
      });
  }

  private createLifeCycle(id: number, version: number, packagingPart: number) {
    const dto: EvaluationGenerationDto = { id, version };
    this.evalSubscription = this.analysisApiService.getLifeCycleEvaluationResult(
      dto, packagingPart).subscribe(_ => {
        window.location.reload();
      });
  }

  private getCreditsDialogConfig(creditsRequired: number) {
    const data: SimpleDialogData = {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('dataEvaluation.performEvaluations.messages.evalCost',
      { count: creditsRequired })], icon: 'info'};
    return getDialogConfig(data, '350px');
  }

  protected async setAnalysisPossibleData(
    recDataSource: RecyclabilityResult[][], lcaDataSource: LifeCycleResult[][], packagingPart: number
  ) {
    const recPossibleList: Observable<boolean>[] = [];
    const lcaPossibleList: Observable<boolean>[] = [];
    recDataSource[0].forEach(packaging => {
      if (packaging && packaging.packagingPartId != null && packaging.packagingPartVersion != null) {
        recPossibleList.push(this.analysisApiService.isRecyclabilityAnalysisPossible(
          packaging.packagingPartId, false, packagingPart, packaging.packagingPartVersion));
        lcaPossibleList.push(this.analysisApiService.isLifeCycleAnalysisPossible(
          packaging.packagingPartId, false, packagingPart, packaging.packagingPartVersion));
      }
    });

    if(recPossibleList.length > 0) {
      const testRec: boolean[] = await firstValueFrom(forkJoin(recPossibleList));
      recDataSource.forEach(countryEvalList => {
        for (let index = 0; index < countryEvalList.length; index++) {
          const countryEvalAtIndex = countryEvalList[index];
          if (countryEvalAtIndex) {
            countryEvalAtIndex.isRecPossible = testRec[index];
          }
        }
      });
    }

    if(lcaPossibleList.length > 0) {
      const testLca: boolean[] = await firstValueFrom(forkJoin(lcaPossibleList));
      lcaDataSource.forEach(countryEvalList => {
        for (let index = 0; index < countryEvalList.length; index++) {
          countryEvalList[index].isLcaPossible = testLca[index];
        }
      });
     }
  }

  protected getRecTableCellData(
    dataSource: RecyclabilityDataSource[],
    recyclabilityCategoryLabel: string,
    recCountryTableData: ComparisonRowDefinition[],
    isTotal = false
  ) {
    const currentValue = !isTotal ? dataSource.find(x => x.label === recyclabilityCategoryLabel)?.value :
      (dataSource.find(x => x.label === 'A')?.value ?? 0) + (dataSource.find(x => x.label === 'B')?.value ?? 0);
    const currentDisplayValue = `${currentValue}%`;
    const entry: ComparisonEntryDefinition = {
      value: currentValue ?? 0,
      displayValue: currentDisplayValue,
      color: 'lightgrey',
      tooltip: currentDisplayValue
    };
    let tableRow: ComparisonRowDefinition | undefined = recCountryTableData.find(x => x.key === recyclabilityCategoryLabel);
    if (!tableRow) {
      const recyclabilityCategory = dataSource.find(x => x.label === recyclabilityCategoryLabel);
      tableRow = {
        label: !isTotal ? (`${recyclabilityCategory?.label} (${recyclabilityCategory?.name})` ?? '') :
          this.translateService.instant('analysis.analysisComparison.recyclability.totalRecyclable'),
        key: !isTotal ? recyclabilityCategory?.label ?? '' : 'total',
        entries: []
      };
      recCountryTableData.push(tableRow);
    }
    tableRow.entries.push(entry);
  }

  protected getLcaTableCellData(
    effectsTotal: EnvironmentalEffectsEvaluationResultDto, effectCategory: string,
    effectRelevances: EffectInfosDictionary[], lcaCountryTableData: ComparisonLcaRowDefinition[],
    volume: number, weight: number
  ) {
    const currentValue = effectsTotal ? effectsTotal[effectCategory as keyof EnvironmentalEffectsEvaluationResultDto] : 0;
    const currentDisplayValue = currentValue ? currentValue.toExponential(2) : '--';
    const entry: ComparisonLcaEntryDefinition = {
      value: currentValue,
      displayValue: currentDisplayValue,
      color: 'lightgrey',
      tooltip: currentDisplayValue,
      weight,
      volume
    };
    let tableRow: ComparisonLcaRowDefinition | undefined = lcaCountryTableData.find(x => x.key === effectCategory);
    if (!tableRow) {
      const effect = effectRelevances.find(x => x.key === effectCategory);
      tableRow = {
        label: `${effect?.name} (${effect?.unit})`,
        key: effectCategory,
        isRelevant: false,
        entries: []
      };
      lcaCountryTableData.push(tableRow);
    }
    tableRow.entries.push(entry);
  }

stopWhenCreditsInsufficient(): void {
    const data = {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('warnings.insufficientCredits')], icon: 'info'
    };
    this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(data));
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.creditsSubscription?.unsubscribe();
    this.evalSubscription?.unsubscribe();
  }
}
