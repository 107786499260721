import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, Validators, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CustomFieldsDialogComponent, CustomFieldsDialogResult } from 'src/app/components/dialogs/custom-fields-dialog/custom-fields-dialog.component';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { CustomFieldAssignmentsDto } from 'src/app/data-transfer/entities/custom-field-assignments-dto';
import { CustomFieldProfileDto } from 'src/app/data-transfer/entities/custom-field-profile-dto';
import { PackagingSystemApiService } from 'src/app/data-transfer/services/packaging-system-api-service';
import { DialogActions } from 'src/app/model/dictionary';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { getDialogConfig } from 'src/app/util/dialog-util';

@Component({
  selector: 'app-packaging-system-html-template',
  templateUrl: './packaging-system-html-template.component.html',
  styleUrls: ['./packaging-system-html-template.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PackagingSystemHtmlTemplateComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PackagingSystemHtmlTemplateComponent,
      multi: true,
    },
  ]
})
export class PackagingSystemHtmlTemplateComponent implements OnChanges, OnDestroy, ControlValueAccessor {

  @Input() assemblyCountries: CountryDto[] = [];
  @Input() distributionCountries: CountryDto[] = [];
  @Input() displayErrors = false;
  @Input() canEditForm: boolean = true;

  packagingSystemForm!: FormGroup;
  minimalValue = 0.001;
  maxCommentLength = 250;

  showDimensionsInfo = false;
  allDistrCountryNames = '';

  customFieldProfiles: CustomFieldProfileDto[] = [];

  private formSubscription?: Subscription;
  private gtinSubscription?: Subscription;

  constructor(
    private fb: FormBuilder,
    private gtinCheckService: GtinCheckService,
    private countriesService: CountriesService,
    private packagingSystemApiService: PackagingSystemApiService,
    private dialog: MatDialog
  ) { }

  private initCustomFields() {
    this.packagingSystemApiService.getCustomFieldProfiles().subscribe(profiles => {
        this.customFieldProfiles = profiles;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayErrors && changes.displayErrors.currentValue) {
      this.packagingSystemForm.markAllAsTouched();
    }

    this.initCustomFields();
  }

  writeValue(value: any): void {
    this.packagingSystemForm = this.fb.group({
      comment: [value.comment],
      brandName: [value.brandName ?? null, Validators.required],
      productName: [value.productName ?? null, Validators.required],
      articleNumber: [value.articleNumber ?? null],
      gtin: [value.gtin ?? null],
      assemblyCountry: [value?.assemblyCountry ?? null],
      distributionCountries: [value?.distributionCountries ?? []],
      length: [value.length, Validators.min(this.minimalValue)],
      width: [value.width, Validators.min(this.minimalValue)],
      height: [value.height, Validators.min(this.minimalValue)],
      customFields: [value?.customFields]
    });
    this.formSubscription = this.packagingSystemForm.valueChanges.subscribe(_ => {
      this.onChange(this.packagingSystemForm.value);
      this.onValidationChange();
    });
    this.gtinSubscription = this.packagingSystemForm.controls.gtin.valueChanges.subscribe(async val => {
      this.packagingSystemForm.controls.gtin.setValue(val?.trim(), { emitEvent: false });
      await this.gtinCheckService.checkGtinValidity(this.packagingSystemForm);
      this.packagingSystemForm.controls.gtin.markAsTouched();
    });
    this.allDistrCountryNames = this.getAllDistrCountryNames();
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.packagingSystemForm.disable() : this.packagingSystemForm.enable();
  }

  validate(): ValidationErrors | null {
    if (this.packagingSystemForm?.invalid) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  get packagingForm() { return this.packagingSystemForm.controls; }

  onChange: any = () => { };
  onTouched: any = () => { };
  onValidationChange: any = () => { };
  registerOnChange(fn: any) { this.onChange = fn; }
  registerOnTouched(fn: any) { this.onTouched = fn; }
  registerOnValidatorChange?(fn: () => void): void { this.onValidationChange = fn; }

  private getAllDistrCountryNames() {
    const allDistrCountryCodes: string[] = this.packagingSystemForm.controls.distributionCountries.value;
    const allDistrCountryNames = allDistrCountryCodes.map(x => this.countriesService.getCountryNameByCode(x));
    return allDistrCountryNames.map((item) => item).join(', ');
  }

  showCustomFields() {
    const dialogConfig = getDialogConfig({ profiles: this.customFieldProfiles,
                                           assignments: this.packagingForm.customFields.value ?? new CustomFieldAssignmentsDto(),
                                           canEditForm: this.canEditForm }, '1500px');

    const dialogRef = this.dialog.open<CustomFieldsDialogComponent, any, CustomFieldsDialogResult>
                                    (CustomFieldsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event == DialogActions.CONFIRM) {
        this.packagingForm.customFields.setValue(result.assignments);
      }
    });
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
    this.gtinSubscription?.unsubscribe();
  }
}
