import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { DictionaryHandler } from './../dictionary';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PackagingComponentDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-dto';
import { RemovabilityConditionDto } from 'src/app/data-transfer/entities/removability-condition-dto';
import { PackagingComponentEntryDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-entry-dto';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';
import { ComponentDialogData } from 'src/app/components/dialogs/additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';
import { MultiMaterialLayerDto } from 'src/app/data-transfer/entities/material-entities/multi-material-layer-dto';

export class ComponentTableInfo {
  id = -1;
  label = '';
  text = '';
  columns: { id: string, text: string }[] = [];
}

export abstract class PackagingComponentPrototype {

  constructor(protected dictionaryHandler: DictionaryHandler) { }

  abstract getComponentTableInfo(translate: (text: string, params?: any) => string): ComponentTableInfo;

  // Component -> Form

  abstract getFormFromComponentEntries(
    data: PackagingComponentEntryDto[], handler: ComponentHandler, fb: FormBuilder, isTracked: boolean
  ): FormGroup;

  abstract getFormFromSingleComponentEntry(
    data: PackagingComponentEntryDto, handler: ComponentHandler, fb: FormBuilder, isTracked: boolean
  ): FormGroup;

  // Form -> Component

  abstract getComponentEntriesFromForm(formValues: any): PackagingComponentEntryDto[];

  abstract getSingleComponentEntryFromForm(formValue: any, forInternalUse: boolean): PackagingComponentEntryDto;

  abstract getSingleComponentFromForm(formValue: any, forInternalUse: boolean, component?: PackagingComponentDto): PackagingComponentDto;


  abstract wrapComponentInComponentEntry(data: PackagingComponentDto): PackagingComponentEntryDto;

  abstract getEmptyComponentEntry(): PackagingComponentEntryDto;

  abstract getDialogType(): any;

  abstract getComponentSubtypes(): ComponentSubtypeDto[];

  abstract setComponentSpecificDialogData(dialogData: ComponentDialogData): void;

  abstract setComponentSpecificTableData(formGroup: FormGroup): void;

  // Utils

  createBaseForm(
    component: PackagingComponentDto, formBuilder: FormBuilder, componentSubtypes: ComponentSubtypeDto[], isDisabled = false
  ): FormGroup {
    return formBuilder.group({
      id: [component.id],
      newVersionName: [''],
      comment: [component.comment],
      packagingComponentSubtypeId: [{ value: component.packagingComponentSubtypeId, disabled: isDisabled }, [Validators.required]],
      packagingComponentSubtypeName: [this.getComponentSubtypeName(
        component.packagingComponentSubtypeId, componentSubtypes), Validators.required],
      manufacturer: [{ value: component.manufacturer, disabled: isDisabled }],
      manufacturingCountry: [{ value: component.manufacturingCountry, disabled: isDisabled }, [Validators.required]],
      articleName: [component.articleName],
      articleNumber: [component.articleNumber],
      gtin: [{ value: component.gtin, disabled: isDisabled }],
      printingCoverage: [component.printingCoverage, [Validators.required, Validators.min(0), Validators.max(100)]],
      isRigidComponent: [{ value: component.isRigidComponent ?? null, disabled: isDisabled }, [Validators.required]],
      length: [{ value: component.length, disabled: isDisabled }, Validators.min(0.001)],
      width: [{ value: component.width, disabled: isDisabled }, Validators.min(0.001)],
      height: [{ value: component.height, disabled: isDisabled }, Validators.min(0.001)],
      totalGrammage: [{ value: component.multiMaterial?.totalGrammage, disabled: isDisabled }, Validators.min(0.001)],
      totalWeight: [{ value: component.multiMaterial?.totalWeight, disabled: true }],
      containsNearInfraredBarrier: [{ value: component.containsNearInfraredBarrier?? undefined, disabled: isDisabled }],
      customFields: [component.customFields]
    });
  }

  protected setErrorCountInPackagingComponent(formGroup: FormGroup) {
    let errorCount = 0;
    Object.keys(formGroup.controls).forEach(controlName => {
      errorCount += formGroup.get(controlName)?.errors ? 1 : 0;
    });
    formGroup.controls.errorCount.patchValue(errorCount);
  }

  protected booleanDictionaryKeyToValue(booleanKey: boolean): string {
    if (booleanKey == null) { return ''; }
    return this.dictionaryHandler.getYesNoDictionary().find(x => x.key === booleanKey).value;
  }

  protected getRemovabilityConditionName(removabilityConditionId: number, removabilityConditions: RemovabilityConditionDto[]): string {
    if (removabilityConditions && removabilityConditionId != null) {
      return removabilityConditions.find(x => x.id === removabilityConditionId)?.name ?? '';
    }
    return removabilityConditionId.toString();
  }

  private getComponentSubtypeName(componentSubtypeId: number, componentsList: ComponentSubtypeDto[]): string {
    if (!componentsList || componentSubtypeId == null) { return ''; }
    return componentsList.find(x => x.id === componentSubtypeId)?.name ?? componentSubtypeId.toString();
  }

  createManifestationOverview(layers: MultiMaterialLayerDto[]): string {
    if (!layers) { return ''; }
    const manifestations: string[] = [];

    layers.forEach(layer => {
      manifestations.push(
        manifestations.length > 0 ? (' ' + layer.materialManifestationName) : layer.materialManifestationName ?? '')
    })

    return manifestations.toString();
  }
}
