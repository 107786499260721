import { CustomDropdownFieldOptionDto } from "./custom-dropdown-field-option-dto";
import { CustomFieldAssignmentDto } from "./custom-field-assignment-dto";

export class CustomFieldAssignmentsDto {
    booleanFields: CustomFieldAssignmentDto<boolean>[] = [];
    stringFields: CustomFieldAssignmentDto<string>[] = [];
    decimalFields: CustomFieldAssignmentDto<number>[] = [];
    integerFields: CustomFieldAssignmentDto<number>[] = [];
    dropdownFields: CustomFieldAssignmentDto<CustomDropdownFieldOptionDto>[] = [];
}
