import { getDialogConfig } from 'src/app/util/dialog-util';
import { DeleteDialogData, DeleteItemDialogComponent } from './../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { AssignCreditsDialogComponent } from './../../dialogs/assign-credits-dialog/assign-credits-dialog.component';
import { OrganizationsVisibilityDto } from '../../../data-transfer/entities/organizations-visibility-dto';
import { CreateUserDto } from './../../../data-transfer/entities/create-user-dto';
import { UserDialogComponent } from './../../dialogs/add-user-dialog/add-user-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { UserApiService } from 'src/app/data-transfer/services/user-api-service';
import { AddOrganizationDialogComponent } from './../../dialogs/add-organization-dialog/add-organization-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { OrganizationDto } from 'src/app/data-transfer/entities/organization-dto';
import { UserDto } from 'src/app/data-transfer/entities/user-dto';
import { forkJoin, Subscription } from 'rxjs';
import { CreateOrganizationDto } from 'src/app/data-transfer/entities/create-organization-dto';
import { UserGroupDto } from 'src/app/data-transfer/entities/user-group-dto';
import { UserDetailsDialogData } from 'src/app/util/user-dialog-handler';
import { OrganizationsVisibilityDialogComponent } from '../../dialogs/organizations-visibility-dialog/organizations-visibility-dialog.component';
import { BillingAccountDto } from 'src/app/data-transfer/entities/billing-account-dto';
import { SortMatTableDataSource } from '../../../model/sort-mat-data-source';
import { SimpleConfirmDialogComponent } from '../../dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogActions } from 'src/app/model/dictionary';
import { CreditsDto } from 'src/app/data-transfer/entities/credits-dto';
import { CreditsService } from 'src/app/services/credits-service';
import { FormControl } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { BaseDataApiService } from 'src/app/data-transfer/services/base-data-api-service';
import { EnvironmentalEffectDto } from 'src/app/data-transfer/entities/environmental-effect-dto';
import { ClientDto } from 'src/app/data-transfer/entities/client-dto';
import { AllowedClientsDto } from 'src/app/data-transfer/entities/allowed-clients-dto';
import { CountryAuthorizationDto } from 'src/app/data-transfer/entities/country-authorization-dto';
import { EnvironmentalEffectAuthorizationDto } from 'src/app/data-transfer/entities/environmental-effect-authorization-dto';
import { BulkUserUploadDialogComponent } from '../../dialogs/bulk-user-upload-dialog/bulk-user-upload-dialog.component';
import { ContractDocumentDto } from 'src/app/data-transfer/entities/contract-document-dto';
import { ReportTemplateDto } from 'src/app/data-transfer/entities/report-template-dto';
import { OrganizationSettingsDto } from 'src/app/data-transfer/entities/organization-settings-dto';
import { AuthorizationDialogComponent, AuthorizationDialogResult } from '../../dialogs/authorization-dialog/authorization-dialog.component';
import { ContractPackageDto} from 'src/app/data-transfer/entities/contract-package-dto'
interface CreateOrganizationReturnObject {
  organizationId: number;
  organizationName: string;
  userGroupId: number;
  userGroupName: string;
  billingAccountId: number;
  billingAccountName: string;
  users: CreateOrganizationUserReturnObject[];
}

interface CreateOrganizationUserReturnObject {
  id: number;
  userName: string;
}

@Component({
  selector: 'app-organization-management',
  templateUrl: './organization-management.component.html',
  styleUrls: ['./organization-management.component.scss']
})
export class OrganizationManagementComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('orgsTableSort') orgsTableSort!: MatSort;
  @ViewChild('delOrgsTableSort') delOrgsTableSort!: MatSort;
  @ViewChild('visibilityTableSort') visibilityTableSort!: MatSort;
  @ViewChild('accountsTableSort') accountsTableSort!: MatSort;
  @ViewChild(MatSort) set matSort(_: any) { this.setSortForSubtables(); }

  organizationsDataSource: SortMatTableDataSource<OrganizationDto>;
  deletedOrganizationsDataSource: SortMatTableDataSource<OrganizationDto>;
  usersDataSource: MatTableDataSource<UserDto>;
  deletedUsersDataSource: MatTableDataSource<UserDto>;
  visibilityDataSource: SortMatTableDataSource<OrganizationDto>;
  accountsDataSource: MatTableDataSource<BillingAccountDto>;

  allUserGroups: UserGroupDto[] = [];
  allUsers: UserDto[] = [];
  allOrganizations: OrganizationDto[] = [];
  allBillingAccounts: BillingAccountDto[] = [];
  allCountries: CountryDto[] = [];
  allEnvironmentalEffects: EnvironmentalEffectDto[] = [];
  allContractPackages: ContractPackageDto[] = [];
  allClients: ClientDto[] = [];
  allContractDocuments: ContractDocumentDto[] = [];
  allPackagingUnitReportTemplates: ReportTemplateDto[] = [];
  allPackagingComponentReportTemplates: ReportTemplateDto[] = [];

  organizationsColumns = ['name'];
  usersColumns = ['name', 'email', 'isPremiumUser', 'actions'];
  visibilityColumns = ['name'];
  accountsColumns = ['name', 'recyclabilityCredits' ,'lcaCredits', 'edit'];

  clickedOrganizationRow: OrganizationDto | null = null;
  sortOrganizationsColumn = 'name';

  isContractSignedControl = new FormControl(true, {nonNullable: true});
  isCostCalculationAllowedControl = new FormControl(true, {nonNullable: true});
  isMaxUserCountActivatedControl = new FormControl(true, {nonNullable: true});
  maxUserCountControl: FormControl<number | null> = new FormControl( { value: null, disabled: true }, {nonNullable: false});

  allowedClientsDropdown: FormControl<number[] | null> = new FormControl([]);

  private routeDataSubscription?: Subscription;
  private dialogSubscription?: Subscription;
  private createOrgSubscription?: Subscription;
  private createUserSubscription?: Subscription;
  private getVisibilitySubscription?: Subscription;
  private setVisibilitySubscription?: Subscription;
  private setCreditsSubscription?: Subscription;
  private deleteSubscription?: Subscription;
  private restoreSubscription?: Subscription;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userApiService: UserApiService,
    private translateService: TranslateService,
    private creditsService: CreditsService,
    private snackbarService: SnackbarService,
    private baseDataApiService: BaseDataApiService
  ) {
    this.organizationsDataSource = new SortMatTableDataSource<OrganizationDto>(this.locale);
    this.deletedOrganizationsDataSource = new SortMatTableDataSource<OrganizationDto>(this.locale);
    this.usersDataSource = new MatTableDataSource<UserDto>();
    this.deletedUsersDataSource = new MatTableDataSource<UserDto>();
    this.visibilityDataSource = new SortMatTableDataSource<OrganizationDto>(this.locale);
    this.accountsDataSource = new MatTableDataSource<BillingAccountDto>();
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.allOrganizations = data.allOrganizations;
      this.setOrganizationsTableData();
      this.allUserGroups = data.allUserGroups;
      this.allUsers = data.allUsers;
      this.allBillingAccounts = data.allBillingAccounts;
      this.organizationsDataSource.sortingDataAccessor =
        (org, sortHeaderId) => (org[sortHeaderId as keyof OrganizationDto] as string)?.toLocaleLowerCase(this.locale);
    });

    this.baseDataApiService.getCountries().subscribe(countries => {
      this.allCountries = countries;
    });

    this.baseDataApiService.getContractPackages().subscribe(contractPackage => {
      this.allContractPackages = contractPackage;
    });

    this.baseDataApiService.getEnvironmentalEffects().subscribe(environmentalEffects => {
      this.allEnvironmentalEffects = environmentalEffects;
    });

    this.baseDataApiService.getClients().subscribe(clients => {
      this.allClients = clients
    });

    this.baseDataApiService.getContractDocuments().subscribe(contractDocuments => {
      this.allContractDocuments = contractDocuments
    });

    this.baseDataApiService.getPackagingUnitReportTemplates().subscribe(templates => {
      this.allPackagingUnitReportTemplates = templates
    });

    this.baseDataApiService.getPackagingComponentReportTemplates().subscribe(templates => {
      this.allPackagingComponentReportTemplates = templates
    });
  }

  private setOrganizationsTableData(): void {
    this.organizationsDataSource.data = this.allOrganizations.filter((x: OrganizationDto) => !x.isDeleted);
    this.deletedOrganizationsDataSource.data = this.allOrganizations.filter((x: OrganizationDto) => x.isDeleted);
    this.organizationsDataSource._updateChangeSubscription();
    this.deletedOrganizationsDataSource._updateChangeSubscription();
    this.clickedOrganizationRow = null;
  }

  ngAfterViewInit() {
    this.organizationsDataSource.sort = this.orgsTableSort;
    this.deletedOrganizationsDataSource.sort = this.delOrgsTableSort;
  }

  setSortForSubtables() {
    this.visibilityDataSource.sort = this.visibilityTableSort;
    this.accountsDataSource.sort = this.accountsTableSort;
  }

  addOrganization() {
    const existingOrganizationsNames = this.organizationsDataSource.data.map(x => x.name);
    const dialogConfig = getDialogConfig({ allUsers: this.allUsers, existingOrganizationsNames }, '700px');
    const dialogRef = this.dialog.open(AddOrganizationDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const organization: CreateOrganizationDto = {
        name: result.organizationName,
        initialCredits: result.credits,
        users: result.users
      };
      this.createOrgSubscription = this.userApiService.createOrganization(organization, true).subscribe({
        next: (orgObject: CreateOrganizationReturnObject) => {
          const message = this.translateService.instant('dataManagement.manageOrganizations.messages.orgCreatedMessage');
          this.openInfoDialog(message, true, 'check');

          const newOrganizationDto: OrganizationDto = {
            name: orgObject.organizationName,
            id: orgObject.organizationId,
            isDeleted: false,
            isContractSigned: true,
            performCostCalculations: false,
            maxUserCount: null,
          };
          this.organizationsDataSource.data.push(newOrganizationDto);
          this.organizationsDataSource._updateChangeSubscription();

          const userGroup: UserGroupDto = {
            id: orgObject.userGroupId,
            name: orgObject.userGroupName,
            organizationId: orgObject.organizationId,
            securityPrincipalId: undefined,
          };
          this.allUserGroups.push(userGroup);

          const newUsersDto: UserDto[] = [];
          result.users.forEach((user: CreateUserDto) => {
            newUsersDto.push({
              id: orgObject.users.find(x => x.userName === user.userName)?.id ?? -1,
              name: user.userName,
              email: user.email,
              organization: newOrganizationDto,
              securityPrincipal: undefined,
              userGroups: [],
              isDeleted: false,
              isPremiumUser: false,
            });
          });
          this.allUsers = this.allUsers.concat(newUsersDto);

          const newAccount: BillingAccountDto = {
            id: orgObject.billingAccountId,
            name: orgObject.billingAccountName,
            organizationId: orgObject.organizationId,
            recyclabilityCredits: result.credits.recyclabilityCredits,
            lcaCredits: result.credits.lcaCredits,
          };
          this.allBillingAccounts.push(newAccount);
        },
        error: e => {
          this.openInfoDialog(e.error.error, false);
        },
      });
    });
  }

  private refreshOrganizationData() {
    const organizationsObservable = this.userApiService.getOrganizations();
    const billingAccountsObservable = this.userApiService.getBillingAccounts();
    const userGroupsObservable = this.userApiService.getAllUserGroups();
    const usersObservable = this.userApiService.getUsers();

    const observables = {
      organizations: organizationsObservable,
      billingAccounts: billingAccountsObservable,
      userGroups: userGroupsObservable,
      users: usersObservable
    };

    forkJoin(observables).subscribe((result: {organizations: OrganizationDto[]
                                              billingAccounts: BillingAccountDto[],
                                              userGroups: UserGroupDto[],
                                              users: UserDto[]
                                             }) => {
      this.allOrganizations = result.organizations;
      this.allBillingAccounts = result.billingAccounts;
      this.allUserGroups = result.userGroups;
      this.allUsers = result.users;
      this.setOrganizationsTableData();
    });
  }

  bulkUserUpload() {
    const dialogConfig = getDialogConfig({ allClients: this.allClients,
                                           allContractPackages: this.allContractPackages,
                                           allOrganizations: this.allOrganizations,
                                           allPackagingUnitReportTemplates: this.allPackagingUnitReportTemplates,
                                           allPackagingComponentReportTemplates: this.allPackagingComponentReportTemplates }, '700px');

    const dialogRef = this.dialog.open(BulkUserUploadDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(_ => {
      this.refreshOrganizationData();
    });
  }

  updateOrganizationSettings() {
    const settingsDto: OrganizationSettingsDto = {
      organizationId: this.clickedOrganizationRow?.id ?? -1,
      isContractSigned: this.isContractSignedControl.value,
      maxUserCount: this.isMaxUserCountActivatedControl.value ? this.maxUserCountControl.value : null,
      performCostCalculations: this.isCostCalculationAllowedControl.value
    }

    const observables = [];

    const selectedAllowedClientIds = this.allowedClientsDropdown.value ?? [];
    const allowedClientsDto: AllowedClientsDto = {
      organizationId: this.clickedOrganizationRow?.id ?? -1,
      allowedClientIds: this.allClients.filter(client => selectedAllowedClientIds.includes(client.id)).map(client => client.id)
    }

    observables.push(this.userApiService.setAllowedClients(allowedClientsDto, true));
    observables.push(this.userApiService.updateOrganizationSettings(settingsDto, true));

    const observable = forkJoin(observables);

    observable.subscribe(() => {
      if (this.clickedOrganizationRow == null) { return; }
      this.clickedOrganizationRow.maxUserCount = settingsDto.maxUserCount;
      this.clickedOrganizationRow.isContractSigned = settingsDto.isContractSigned;
      this.clickedOrganizationRow.performCostCalculations = settingsDto.performCostCalculations;
      this.snackbarService.showInfo('Settings saved', 2000);
    });
  }

  deleteOrganization(organization: OrganizationDto) {
    const dialogData: DeleteDialogData = {
      dialogHeader: this.translateService.instant('dataManagement.userData.texts.removeOrganization.title', { name: organization.name }),
      dialogText: this.translateService.instant('dataManagement.userData.texts.removeOrganization.confirmation')
    };
    const dialogConfig = getDialogConfig(dialogData, '500px');
    dialogConfig.data = dialogData;
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      this.deleteSubscription = this.userApiService.deleteOrganization(organization.id ?? -1, true).subscribe({
        next: _ => {
          organization.isDeleted = true;
          this.setOrganizationsTableData();
        },
        error: e => {
          console.error('An error occurred, ', e.error);
          this.openInfoDialog(e.error, false);
        },
      });
    });
  }

  restoreOrganization(organization: OrganizationDto) {
    const dialogData: SimpleDialogData = {
      title: this.translateService.instant('dataManagement.userData.texts.restoreOrganization.title', { name: organization.name }),
      messages: [this.translateService.instant('dataManagement.userData.texts.restoreOrganization.confirmation')], icon: 'info'
    };
    const dialogConfig = getDialogConfig(dialogData, '500px');
    dialogConfig.data = dialogData;
    const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      this.restoreSubscription = this.userApiService.restoreOrganization(organization.id ?? -1, true).subscribe({
        next: _ => {
          organization.isDeleted = false;
          this.setOrganizationsTableData();
        },
        error: e => {
          console.error('An error occurred, ', e.error);
          this.openInfoDialog(e.error, false);
        },
      });
    });
  }

  addUser() {
    const namesUsed = new Set<string>(this.allUsers.map(userItem => userItem.name.toLowerCase()));
    const data = new UserDetailsDialogData(namesUsed, DialogActions.ADD);
    const dialogRef = this.dialog.open(UserDialogComponent, getDialogConfig(data));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult.event === DialogActions.REJECT) { return; }
      const userGroupId = this.allUserGroups.find(x => x.organizationId === this.clickedOrganizationRow?.id)?.id ?? -1;
      const user: CreateUserDto = dialogResult.data;
      user.userGroupId = userGroupId;
      this.createUserSubscription = this.userApiService.createUser(user, true).subscribe({
        next: userId => {
          this.openInfoDialog(this.translateService.instant('dataManagement.userData.texts.userSaved'), true, 'save');
          const newUserDto: UserDto = {
            id: userId,
            name: user.userName,
            email: user.email,
            organization: this.organizationsDataSource.data.find(x => x.id === this.clickedOrganizationRow?.id),
            securityPrincipal: undefined,
            userGroups: [],
            isDeleted: false,
            isPremiumUser: false,
          };
          this.usersDataSource.data.push(newUserDto);
          this.usersDataSource._updateChangeSubscription();
          this.allUsers.push(newUserDto);
        },
        error: _ => {
          this.openInfoDialog(this.translateService.instant('dataManagement.userData.texts.userNotSaved'), false);
        },
      });
    });
  }

  updatePremiumUserFlags() {
    const users = this.allUsers.filter(user => user.organization?.id === this.clickedOrganizationRow?.id);

    const observables = [];

    for (const user of users) {
      if (user.id) {
        observables.push(this.userApiService.setPremiumUser(user.id, user.isPremiumUser, true));
      }
    }

    forkJoin(observables).subscribe({
      next: _ => {
        this.openInfoDialog(this.translateService.instant('dataManagement.manageOrganizations.messages.usersSavedSuccessfully'), true);
      },
      error: _ => {
        this.openInfoDialog(this.translateService.instant('dataManagement.manageOrganizations.messages.usersSavedWithoutSuccess'), false);
      },
    });
  }

  manageVisibility() {
    const dialogRef = this.dialog.open(OrganizationsVisibilityDialogComponent,
      getDialogConfig({
        allOrganizations: this.allOrganizations,
        selectedOrganizations: this.visibilityDataSource.data,
        targetOrganization: this.clickedOrganizationRow
      }, '700px'));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult.event === DialogActions.REJECT || !this.clickedOrganizationRow) { return; }
      const visibilityObject: OrganizationsVisibilityDto = {
        organizationId: this.clickedOrganizationRow.id,
        visibleOrganizationIds: dialogResult.data?.map((x: OrganizationDto) => x.id)
      };
      this.setVisibilitySubscription = this.userApiService.setOrganizationVisibility(visibilityObject, true).subscribe({
        next: _ => {
          this.openInfoDialog(this.translateService.instant('dataManagement.manageOrganizations.messages.visibilitySetMessage'), true);
          this.visibilityDataSource.data = dialogResult.data;
        },
        error: _ => {
          this.openInfoDialog(this.translateService.instant('dataManagement.manageOrganizations.errors.visibilitySetError'), false);
        },
      });
    });
  }

  editCredits(billingAccount: BillingAccountDto) {
    const dialogRef = this.dialog.open(AssignCreditsDialogComponent, getDialogConfig(
      { creditsCount: new CreditsDto(billingAccount.recyclabilityCredits,billingAccount.lcaCredits)}));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult.event === DialogActions.REJECT) { return; }
      this.setCreditsSubscription = this.userApiService.setBillingAccountCredits(billingAccount.id, dialogResult.data).subscribe({
        next: _ => {
          this.openInfoDialog(this.translateService.instant('dataManagement.manageOrganizations.messages.creditsSuccess'), true);
          const account = this.accountsDataSource.data.find(x => x.id === billingAccount.id);
          if (!account) {
            throw new Error('OrganizationManagementComponent: billing account not found');
          }
          account.recyclabilityCredits = billingAccount.recyclabilityCredits + dialogResult.data.recyclabilityCredits;
          account.lcaCredits = billingAccount.lcaCredits + dialogResult.data.lcaCredits;
          this.creditsService.setCreditsCount();
        },
        error: _ => {
          this.openInfoDialog(this.translateService.instant('dataManagement.manageOrganizations.errors.creditsError'), false);
        },
      });
    });
  }

  editAuthorizations(billingAccount: BillingAccountDto) {
    const countryAuthorizationsObservable = this.userApiService.getCountryAuthorizations(billingAccount.id);
    const environmentalEffectAuthorizationsObservable = this.userApiService.getEnvironmentalEffectsAuthorizations(billingAccount.id);

    forkJoin([countryAuthorizationsObservable, environmentalEffectAuthorizationsObservable]).subscribe({
      next: ([countryAuthorizations, environmentalEffectAuthorizations]) => {
        const dialogConfig = getDialogConfig(
          {
            countryAuthorizations: countryAuthorizations,
            environmentalEffectAuthorizations: environmentalEffectAuthorizations,
            allCountries: this.allCountries,
            allEnvironmentalEffects: this.allEnvironmentalEffects,
          },
          '700px'
        );

        const dialogRef = this.dialog.open<AuthorizationDialogComponent, any, AuthorizationDialogResult>(
          AuthorizationDialogComponent,
          dialogConfig
        );

        dialogRef.afterClosed().subscribe(result => {
          if (result?.event == DialogActions.CONFIRM) {
            this.updateAuthorizations(billingAccount.id, result.countryAuthorizations, result.environmentalEffectsAuthorizations);
          }
        });
      },
      error: _ => {
        this.openInfoDialog(this.translateService.instant('dataManagement.manageOrganizations.errors.authorizationError'), false);
      },
    });
  }

  private updateAuthorizations(billingAccountId: number,
                               countryAuthorizations: CountryAuthorizationDto[],
                               environmentalEffectAuthorizations: EnvironmentalEffectAuthorizationDto[]) {
    const countryAuthorizationsObservable = this.userApiService.setCountryAuthorizations(billingAccountId, countryAuthorizations, true);
    const environmentalEffectAuthorizationsObservable
            = this.userApiService.setEnvironmantalEffectsAuthorizations(billingAccountId, environmentalEffectAuthorizations, true);

    countryAuthorizationsObservable.subscribe({
      next: _ => {},
      error: e => {
        this.openInfoDialog(
          this.translateService.instant('dataManagement.manageOrganizations.errors.countryAuthorizationSaveError') + e.error,
          false
        );
      },
    });

    environmentalEffectAuthorizationsObservable.subscribe({
      next: _ => {},
      error: e => {
        this.openInfoDialog(
          this.translateService.instant('dataManagement.manageOrganizations.errors.environmentalEffectsAuthorizationSaveError') + e.error,
          false
        );
      },
    });
  }

  private openInfoDialog(messageToDisplay: string, isSuccess: boolean, icon?: string) {
    let data: SimpleDialogData;
    if (isSuccess) {
      data = {
        title: this.translateService.instant('common.text.success'),
        messages: [messageToDisplay], icon: icon ?? 'info'
      };
    } else {
      data = {
        title: this.translateService.instant('common.text.error'),
        messages: [messageToDisplay], icon: icon ?? 'error'
      };
    }
    this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(data));
  }

  onOrganizationClicked(row: OrganizationDto) {
    if (this.clickedOrganizationRow === row) {
      this.clickedOrganizationRow = null;
      this.usersDataSource = new MatTableDataSource<UserDto>();
      this.deletedUsersDataSource = new MatTableDataSource<UserDto>();
      this.visibilityDataSource = new SortMatTableDataSource<OrganizationDto>(this.locale);
      this.accountsDataSource = new MatTableDataSource<BillingAccountDto>();
    } else {
      this.clickedOrganizationRow = row;
      const usersInSelectedOrg = this.allUsers.filter(user => user.organization?.id === this.clickedOrganizationRow?.id);
      this.usersDataSource.data = usersInSelectedOrg.filter(user => !user.isDeleted);
      this.deletedUsersDataSource.data = usersInSelectedOrg.filter(user => user.isDeleted);
      this.accountsDataSource.data = this.allBillingAccounts.filter(x => x.organizationId === this.clickedOrganizationRow?.id);

      this.getVisibilitySubscription = this.userApiService.getOrganizationVisibility(row.id).subscribe(result => {
        this.visibilityDataSource.data = result;
      });

      this.userApiService.getAllowedClients(row.id).subscribe(allowedClients => {
        this.allowedClientsDropdown.setValue(allowedClients.map(x => x.id));
      });

      this.isContractSignedControl.setValue(row.isContractSigned);
      this.isCostCalculationAllowedControl.setValue(row.performCostCalculations);
      this.isMaxUserCountActivatedControl.setValue(row.maxUserCount != null);
      if (row.maxUserCount != null) {
        this.maxUserCountControl.enable();
        this.maxUserCountControl.setValue(row.maxUserCount);
      } else {
        this.maxUserCountControl.setValue(null);
      }
      this.manageDeletedOrgElementsVisibility(this.clickedOrganizationRow);

      this.isMaxUserCountActivatedControl.valueChanges.subscribe(_ => {
        if (_) {
          this.maxUserCountControl.enable();
        } else {
          this.maxUserCountControl.disable();
        }
      });
    }
  }

  private manageDeletedOrgElementsVisibility(organization: OrganizationDto) {
    if (organization.isDeleted) {
      const isPremiumUserIndex = this.usersColumns.findIndex(x => x === 'isPremiumUser');
      if (isPremiumUserIndex !== -1) { this.usersColumns.splice(isPremiumUserIndex, 1); }
      const actionsIndex = this.usersColumns.findIndex(x => x === 'actions');
      if (actionsIndex !== -1) { this.usersColumns.splice(actionsIndex, 1); }
      const editIndex = this.accountsColumns.findIndex(x => x === 'edit');
      if (editIndex !== -1) { this.accountsColumns.splice(editIndex, 1); }
    } else {
      const isPremiumUserIndex = this.usersColumns.findIndex(x => x === 'isPremiumUser');
      if (isPremiumUserIndex === -1) { this.usersColumns.push('isPremiumUser'); }
      const actionsIndex = this.usersColumns.findIndex(x => x === 'actions');
      if (actionsIndex === -1) { this.usersColumns.push('actions'); }
      const editIndex = this.accountsColumns.findIndex(x => x === 'edit');
      if (editIndex === -1) { this.accountsColumns.push('edit'); }
    }
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.createOrgSubscription?.unsubscribe();
    this.createUserSubscription?.unsubscribe();
    this.getVisibilitySubscription?.unsubscribe();
    this.setVisibilitySubscription?.unsubscribe();
    this.setCreditsSubscription?.unsubscribe();
    this.deleteSubscription?.unsubscribe();
    this.restoreSubscription?.unsubscribe();
  }
}
