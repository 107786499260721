<mat-menu #analysesMenu="matMenu" [xPosition]="menuDirectionX" yPosition="above">
  <ng-template matMenuContent>
    <div *ngIf="(packagingUnitLoaded | async); else showSpinner">
      <button mat-menu-item *ngIf="!isUserValidator || hasRecyclabilityResult"
        [matMenuTriggerFor]="analysesRecyclability">{{ 'analysis.recyclabilityAnalysis.title' | translate }}
      </button>
      <button mat-menu-item *ngIf="!isUserValidator || hasLifeCycleResult" [matMenuTriggerFor]="analysesLca"
        [disabled]="!isLcaAllowed()" [matTooltip]="!isLcaAllowed()? ('warnings.NoPremiumUserAndEnvironmentalEffects' | translate) : ''"
        matTooltipPosition = "left">
        {{ 'analysis.lifecycleAnalysis.title' | translate }}
      </button>
      <button mat-menu-item *ngIf="!isUserValidator" [matMenuTriggerFor]="analysesPlasticTax"
        [disabled]="!isCCAllowed()" [matTooltip]="!isCCAllowed()? ('warnings.OrganizationInsufficientPermissions' | translate) : ''"
        matTooltipPosition = "left">
        {{ 'analysis.expenseCalculation.plasticTax.title' | translate }}
      </button>
      <button mat-menu-item *ngIf="!isUserValidator" [matMenuTriggerFor]="analysesLicenseFee"
        [disabled]="!isCCAllowed()" [matTooltip]="!isCCAllowed()? ('warnings.OrganizationInsufficientPermissions' | translate) : ''"
        matTooltipPosition = "left">
        {{ 'analysis.expenseCalculation.licenseFee.title' | translate }}
      </button>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #analysesRecyclability="matMenu" [xPosition]="menuDirectionX">
  <button [disabled]="!isAnalysisAllowed" mat-menu-item [matMenuTriggerFor]="newAnalysis" *ngIf="!isUserValidator"
    [matMenuTriggerData]="recyclabilityAnalysisMenu">{{ 'home.packagingUnitHome.createNewAnalysis' | translate }}
  </button>
  <div *ngIf="versionsWithRecCount > 0">
    <div *ngIf="(recMenuItemsLoaded | async); else showSpinner">
      <button mat-menu-item [matMenuTriggerFor]="packagingVersions" [matMenuTriggerData]="recyclabilityAnalysisMenu">{{
        'home.packagingUnitHome.openExistingAnalysis' | translate }}
      </button>
    </div>
  </div>
</mat-menu>

<mat-menu #analysesLca="matMenu" [xPosition]="menuDirectionX">
  <button [disabled]="!isAnalysisAllowed" mat-menu-item [matMenuTriggerFor]="newAnalysis" *ngIf="!isUserValidator"
    [matMenuTriggerData]="lcaAnalysisMenu">{{ 'home.packagingUnitHome.createNewAnalysis' | translate }}
  </button>
  <div *ngIf="versionsWithLcaCount > 0">
    <div *ngIf="(lcaMenuItemsLoaded | async); else showSpinner">
      <button mat-menu-item [matMenuTriggerFor]="packagingVersions" [matMenuTriggerData]="lcaAnalysisMenu">{{
        'home.packagingUnitHome.openExistingAnalysis' | translate }}
      </button>
    </div>
  </div>
</mat-menu>

<mat-menu #analysesPlasticTax="matMenu" [xPosition]="menuDirectionX">
  <button [disabled]="!isAnalysisAllowed" mat-menu-item [matMenuTriggerFor]="newAnalysis" *ngIf="!isUserValidator"
    [matMenuTriggerData]="plasticTaxMenu">{{ 'home.packagingUnitHome.createNewAnalysis' | translate }}
  </button>
  <div *ngIf="versionsWithPlasticTaxCount > 0">
    <div *ngIf="(taxMenuItemsLoaded | async); else showSpinner">
      <button mat-menu-item [matMenuTriggerFor]="packagingVersions" [matMenuTriggerData]="plasticTaxMenu">{{
        'home.packagingUnitHome.openExistingAnalysis' | translate }}
      </button>
    </div>
  </div>
</mat-menu>

<mat-menu #analysesLicenseFee="matMenu" [xPosition]="menuDirectionX">
  <button [disabled]="!isAnalysisAllowed" mat-menu-item [matMenuTriggerFor]="newAnalysis" *ngIf="!isUserValidator"
    [matMenuTriggerData]="licenseFeeMenu">{{ 'home.packagingUnitHome.createNewAnalysis' | translate }}
  </button>
  <div *ngIf="versionsWithLicenseFeeCount > 0">
    <div *ngIf="(feeMenuItemsLoaded | async); else showSpinner">
      <button mat-menu-item [matMenuTriggerFor]="packagingVersions" [matMenuTriggerData]="licenseFeeMenu">{{
        'home.packagingUnitHome.openExistingAnalysis' | translate }}
      </button>
    </div>
  </div>
</mat-menu>

<mat-menu #newAnalysis="matMenu" [xPosition]="menuDirectionX">
  <ng-template matMenuContent let-aliasMenuItems="menuItems" let-analysisType="analysisType" *ngIf="!isUserValidator">
    <button mat-menu-item *ngFor="let item of aliasMenuItems" (click)="createAnalysis(analysisType, item.versionId)"
      [disabled]="!isAnalysisAllowed">
      Version {{ item.versionId }}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #packagingVersions="matMenu" [xPosition]="menuDirectionX">
  <ng-template matMenuContent let-aliasMenuItems="menuItems.filter(x=> x.analysisVersions.length > 0)">
    <button mat-menu-item *ngFor="let item of aliasMenuItems" [matMenuTriggerFor]="analysisVersions"
      [matMenuTriggerData]="item">
      Version {{ item.versionId }}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #analysisVersions="matMenu" [xPosition]="menuDirectionX">
  <ng-template matMenuContent let-aliasMenuItems="analysisVersions" let-analysisType="analysisType"
    let-version="versionId">
    <button mat-menu-item *ngFor="let item of aliasMenuItems"
      (click)="navigateToAnalysis(item.analysisVersionId, analysisType, version)">
      {{ item.displayDate }}
    </button>
  </ng-template>
</mat-menu>

<ng-template #showSpinner>
  <div mat-menu-item>
    <mat-spinner diameter="24" color="accent" class="spinner"></mat-spinner>
    <span class="spinner-text">{{ 'common.text.searching' | translate }}...</span>
  </div>
</ng-template>
