import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ComponentTreeLevel, PackagingPartTreeComponent, PackagingUnitTreeLevel } from 'src/app/components/shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { PackagingUnitTypeService } from 'src/app/navigation/services/packaging-unit-type-service';

@Component({
  selector: 'app-packaging-unit-tree',
  templateUrl: './packaging-unit-tree.component.html',
  styleUrls: ['./packaging-unit-tree.component.scss']
})
export class PackagingUnitTreeComponent extends PackagingPartTreeComponent implements OnInit {

  @Input() packagingUnit!: PackagingUnitDto;

  selectedNode: PackagingUnitTreeLevel | ComponentTreeLevel | null = null;
  nestedTreeControl = new NestedTreeControl<PackagingUnitTreeLevel>(node => node.children);

  constructor(
    protected packagingUnitTypeService: PackagingUnitTypeService,
    protected componentTypeService: ComponentTypeService
  ) {
    super(packagingUnitTypeService, componentTypeService);
    this.dataSource = new MatTreeNestedDataSource<PackagingUnitTreeLevel>();
  }

  ngOnInit(): void {
    this.initTree();
  }

  private initTree() {
    const treeData: PackagingUnitTreeLevel[] = this.buildTree(this.packagingUnit);
    this.setSelectedLevel(treeData[0]);
    this.dataSource.data = null;
    this.dataSource.data = treeData;
    this.nestedTreeControl.dataNodes = treeData;
    this.nestedTreeControl.expandAll();
  }

  setSelectedLevel(node: PackagingUnitTreeLevel | ComponentTreeLevel) {
    if (this.selectedNode && this.selectedNode.id === node.id && this.selectedNode.index === node.index) {
      this.selectedNode = null;
    } else {
      this.selectedNode = node;
    }
    this.nodeSelected.emit(this.selectedNode);
  }

  public getPackagingPartImage(node: PackagingUnitTreeLevel | ComponentTreeLevel) {
    return super.getPackagingPartImage(node);
  }

  public getImageTooltip(node: PackagingUnitTreeLevel) {
    return super.getImageTooltip(node);
  }

  private buildTree(packagingUnit: PackagingUnitDto): PackagingUnitTreeLevel[] {
    const unitChildren: ComponentTreeLevel[] = this.getComponentTreeLevelsForUnit(packagingUnit);
    const unitNode: PackagingUnitTreeLevel = {
      id: packagingUnit.id ?? -1,
      name: `${packagingUnit.brandName} - ${packagingUnit.productName}`,
      quantity: 1,
      type: PackagingPart.Unit,
      index: 0,
      packagingUnit: packagingUnit,
      children: unitChildren
    };
    return [unitNode];
  }
}
