import { MultiMaterialCompositeDto } from '../../../data-transfer/entities/material-entities/multi-material-composite-dto';
import { PackagingComponentDto } from '../../../data-transfer/entities/component-entities/packaging-component-dto';
import { AuthService } from '../../../services/auth-service';
import { Router, ActivatedRoute } from '@angular/router';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PackagingUnitInfoDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';
import { MatTableDataSource } from '@angular/material/table';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';
import { PackagingSystemInfoDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { MultiMaterialCompositeImportWrapper } from '../../dialogs/selection-dialog-materials/selection-dialog-materials.component';

export const HOME_SPLITTER_WIDTH = 'homeSplitterWidth';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('lastEdited', { read: ElementRef }) lastEdited!: ElementRef;
  @ViewChild('quickLinks', { read: ElementRef }) quickLinks!: ElementRef;

  packagingSystemsIconSource = '';
  packagingUnitsIconSource = '';
  componentsIconSource = '';
  materialsIconSource = '';
  dataManagementIconSource = '';
  dataEvaluationIconSource = '';
  downloadsIconSource = '';
  websiteIconSource = '';

  isValidator: boolean;
  isAdmin: boolean;

  packagingSystemsDataSource: MatTableDataSource<PackagingSystemInfoDto>;
  packagingUnitsDataSource: MatTableDataSource<PackagingUnitInfoDto>;
  componentsDataSource: MatTableDataSource<PackagingComponentDto>;
  materialsDataSource: MatTableDataSource<MultiMaterialCompositeImportWrapper>;

  displayedColumnsPackagingSystems = ['id', 'tracking', 'brandName', 'productName', 'articleNumber', 'action'];
  displayedColumnsPackagingUnits = ['id', 'tracking', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'action'];
  displayedColumnsComponents = ['id', 'tracking', 'packagingComponentCategoryName', 'packagingComponentSubtypeName',
    'articleName', 'articleNumber', 'action'];
  displayedColumnsMaterials = ['id', 'tracking', 'articleName', 'articleNumber', 'manufacturerName', 'action'];

  private routeDataSubscription?: Subscription;
  private themeSubscription?: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.isValidator = this.authService.isUserValidator();
    this.isAdmin = this.authService.isUserAdmin();
    this.packagingSystemsDataSource = new MatTableDataSource<PackagingSystemInfoDto>();
    this.packagingUnitsDataSource = new MatTableDataSource<PackagingUnitInfoDto>();
    this.componentsDataSource = new MatTableDataSource<PackagingComponentDto>();
    this.materialsDataSource = new MatTableDataSource<MultiMaterialCompositeImportWrapper>();
  }

  ngAfterViewInit(): void {
    this.restoreWidth();
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      if (data.lastViewedPackagingSystems) {
        this.packagingSystemsDataSource.data = data.lastViewedPackagingSystems;
      }
      if (data.lastViewedPackagingUnits) {
        this.packagingUnitsDataSource.data = data.lastViewedPackagingUnits;
      }
      if (data.lastViewedComponents) {
        this.componentsDataSource.data = data.lastViewedComponents;
      }
      if (data.lastViewedMaterials) {
        this.materialsDataSource.data = data.lastViewedMaterials;
      }
    });
    this.setImagePaths();
  }

  private setImagePaths() {
    const pathBuilder = (path: string) => `${PROJECT_IMAGES_PATH}elements/${path}_homepage.svg`;
    this.packagingSystemsIconSource = pathBuilder('packaging_system');
    this.packagingUnitsIconSource = pathBuilder('packaging_unit');
    this.componentsIconSource = pathBuilder('component');
    this.materialsIconSource = pathBuilder('composite_material');
    this.dataManagementIconSource = pathBuilder('directory');
    this.dataEvaluationIconSource = pathBuilder('evaluation');
    this.downloadsIconSource = pathBuilder('download');
    this.websiteIconSource = pathBuilder('website');
  }

  packagingSystemsClicked() {
    this.router.navigate([PathResolveService.getPackagingSystemHomepagePath(true)]);
  }

  packagingUnitsClicked() {
    this.router.navigate([PathResolveService.getPackagingUnitHomepagePath(true)]);
  }

  componentsClicked() {
    this.router.navigate([PathResolveService.getComponentHomepagePath(true)]);
  }

  materialsClicked() {
    this.router.navigate([PathResolveService.getMaterialHomepagePath(true)]);
  }

  websiteClicked() {
    const link = localStorage.getItem('language') === 'de' ?
      'https://packaging-cockpit.com/kontakt/' :
      'https://packaging-cockpit.com/en/contact/';
    window.open(link, '_blank', 'noreferrer');
  }

  public resize(event: MouseEvent) {
    const xPos = Number(event.clientX);
    const fullwidth = window.innerWidth;
    const percent = Math.round((100 * (xPos / fullwidth)) * 10) / 10;
    const element1 = this.lastEdited.nativeElement;
    const element2 = this.quickLinks.nativeElement;
    element1.style.maxWidth = `calc(${percent}% - 8px)`;
    element2.style.maxWidth = `calc(${100 - percent}% - 8px)`;
    this.saveWidth(percent);
  }

  private saveWidth(percent: number) {
    localStorage.setItem(HOME_SPLITTER_WIDTH, JSON.stringify(percent));
  }

  private restoreWidth() {
    if (this.isValidator || this.isAdmin) { return; }
    const item = localStorage.getItem(HOME_SPLITTER_WIDTH);
    const element1 = this.lastEdited.nativeElement;
    const element2 = this.quickLinks.nativeElement;
    if (item && element1 && element2) {
      const percent: number = JSON.parse(item);
      element1.style.maxWidth = `calc(${percent}% - 8px)`;
      element2.style.maxWidth = `calc(${100 - percent}% - 8px)`;
    }
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }
}
