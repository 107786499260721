import { MainBodyEntryDto } from '../component-entities/main-body-entry-dto';
import { InlayEntryDto } from '../component-entities/inlay-entry-dto';
import { SleeveEntryDto } from '../component-entities/sleeve-entry-dto';
import { PackagingAidEntryDto } from '../component-entities/packaging-aid-entry-dto';
import { LabelEntryDto } from '../component-entities/label-entry-dto';
import { ClosureEntryDto } from '../component-entities/closure-entry-dto';
import { InMoldLabelEntryDto } from '../component-entities/in-mold-label-entry-dto';
import { PackagingUnitParentDto } from './packaging-unit-parent-dto';
import { CustomFieldAssignmentsDto } from '../custom-field-assignments-dto';

export class PackagingUnitDto extends PackagingUnitParentDto {
    id!: number;
    mainBody?: MainBodyEntryDto;
    closures: ClosureEntryDto[] = [];
    labels: LabelEntryDto[] = [];
    packagingAids: PackagingAidEntryDto[] = [];
    sleeves: SleeveEntryDto[] = [];
    inlays: InlayEntryDto[] = [];
    inMoldLabels: InMoldLabelEntryDto[] = [];
    isTransportPackaging: boolean | null = null;
    isPackagingPartOfProduct: boolean | null = null;
    isMultiUsePackaging: boolean | null = null;
    customFields: CustomFieldAssignmentsDto | null = null;
}
