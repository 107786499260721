<div class="outer-container">
  <mat-card>
    <mat-card-title class="grid-item-title">{{ 'dataManagement.exportProfiles.title' | translate }}</mat-card-title>
    <mat-card-content class="table-container-wide">
      <mat-tab-group>
        <mat-tab label="{{ 'dataManagement.exportProfiles.titlePackagingSystems' | translate }}">
          <div class="profiles-table-container">
            <app-export-profiles-table [dataSource]="packagingSystemProfilesDataSource"
              [displayedColumns]="displayedColumns" [isAdmin]="isAdmin"
              (editProfileEvent)="openPackagingSystemDialog(dialogActions.EDIT, $event)"
              (deleteProfileEvent)="deleteProfile($event, null, null, null)">
            </app-export-profiles-table>
            <button (click)="openPackagingSystemDialog(dialogActions.ADD)" mat-raised-button color="primary">
              {{ 'dataManagement.exportProfiles.buttons.add' | translate }}
            </button>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.exportProfiles.titlePackagingUnits' | translate }}">
          <div class="profiles-table-container">
            <app-export-profiles-table [dataSource]="packagingUnitProfilesDataSource"
              [displayedColumns]="displayedColumns" [isAdmin]="isAdmin"
              (editProfileEvent)="openPackagingUnitDialog(dialogActions.EDIT, $event)"
              (deleteProfileEvent)="deleteProfile(null, $event, null, null)">
            </app-export-profiles-table>
            <button (click)="openPackagingUnitDialog(dialogActions.ADD)" mat-raised-button color="primary">
              {{ 'dataManagement.exportProfiles.buttons.add' | translate }}
            </button>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.exportProfiles.titleComponents' | translate }}" class="table-container">
          <div class="profiles-table-container">
            <app-export-profiles-table [dataSource]="packagingComponentProfilesDataSource"
              [displayedColumns]="displayedColumns" [isAdmin]="isAdmin"
              (editProfileEvent)="openPackagingComponentDialog(dialogActions.EDIT, $event)"
              (deleteProfileEvent)="deleteProfile(null, null, $event, null)">
            </app-export-profiles-table>
            <button (click)="openPackagingComponentDialog(dialogActions.ADD)" mat-raised-button color="primary">
              {{ 'dataManagement.exportProfiles.buttons.add' | translate }}
            </button>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.exportProfiles.titleMaterials' | translate }}" class="table-container">
          <div class="profiles-table-container">
            <app-export-profiles-table [dataSource]="compositeMaterialProfilesDataSource"
              [displayedColumns]="displayedColumns" [isAdmin]="isAdmin"
              (editProfileEvent)="openCompositeMaterialDialog(dialogActions.EDIT, $event)"
              (deleteProfileEvent)="deleteProfile(null, null, null, $event)">
            </app-export-profiles-table>
            <button (click)="openCompositeMaterialDialog(dialogActions.ADD)" mat-raised-button color="primary">
              {{ 'dataManagement.exportProfiles.buttons.add' | translate }}
            </button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
