import { Subscription } from 'rxjs';
import { MaterialManifestationDto } from 'src/app/data-transfer/entities/material-manifestation-dto';
import { CountryDto } from './../../../data-transfer/entities/country-dto';
import { MaterialFunctionDto } from '../../../data-transfer/entities/material-function-dto';
import { ColorDto } from './../../../data-transfer/entities/color-dto';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, ViewChild, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { MultiMaterialTableComponent } from '../../shared-components/multi-material-table/multi-material-table.component';
import { CompositeMaterialSevice } from 'src/app/services/material-services/composite-material-service';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';

export interface ComponentDialogData {
  packagingUnitTypeId: number;
  action: number;
  canEditForm: boolean;
  isUserValidator: boolean;
  isPreview: boolean;
  isTracked: boolean;
  allColors: ColorDto[];
  allMaterialFunctions: MaterialFunctionDto[];
  allManifestations: MaterialManifestationDto[];
  manufacturingCountries: CountryDto[];
  baseForm: FormGroup;
  componentSubtypes?: ComponentSubtypeDto[];
}
@Component({
  selector: 'app-additional-packaging-components-parent-dialog',
  templateUrl: './additional-packaging-components-parent-dialog.component.html',
  styleUrls: ['./additional-packaging-components-parent-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalPackagingComponentsParentDialogComponent implements OnDestroy {

  @ViewChild(MultiMaterialTableComponent) private materialsTable!: MultiMaterialTableComponent;

  action!: number;
  allColors!: ColorDto[];
  allMaterialFunctions!: MaterialFunctionDto[];
  allManifestations!: MaterialManifestationDto[];
  manufacturingCountries!: CountryDto[];
  selectedManufacturingCountry!: string;
  componentSubtypes: ComponentSubtypeDto[] = [];

  callerId = -1;
  packagingUnitTypeId = -1;
  canEditForm = true;
  isTracked = false;
  isEditing!: boolean;
  disableTracked!: boolean;
  isUserValidator = false;
  isPreview = false;
  compositeMaterial!: MultiMaterialCompositeDto;
  compositeMaterialBackup!: MultiMaterialCompositeDto;
  dialogActions = DialogActions;

  private totalGrammageSubscription?: Subscription;
  private gtinSubscription?: Subscription;
  private manufCountrySubscription?: Subscription;
  protected typeSubscription?: Subscription;

  constructor(
    protected dialogRef: MatDialogRef<AdditionalPackagingComponentsParentDialogComponent>,
    protected gtinCheckService: GtinCheckService
  ) { }

  protected setCommonComponentData(data: any) {
    const originalMaterial = data.baseForm.value.multiMaterial ?? CompositeMaterialSevice.createEmptyCompositeMaterial();
    this.compositeMaterial = JSON.parse(JSON.stringify(originalMaterial));
    this.compositeMaterialBackup = JSON.parse(JSON.stringify(originalMaterial));

    this.action = data.action;
    this.allMaterialFunctions = data.allMaterialFunctions;
    this.allColors = data.allColors;
    this.allManifestations = data.allManifestations;
    this.manufacturingCountries = data.manufacturingCountries;
    this.packagingUnitTypeId = data.packagingUnitTypeId;
    this.canEditForm = data.canEditForm;
    this.componentSubtypes = data.componentSubtypes;

    this.isTracked = data.isTracked;
    this.isEditing = data.action === DialogActions.EDIT;
    this.isUserValidator = data.isUserValidator;
    this.isPreview = data.isPreview;
    this.disableTracked = this.isEditing ? this.isTracked : false;
  }

  protected createFormCopy(originalForm: FormGroup): FormGroup {
    const copiedForm = new FormGroup({});
    Object.keys(originalForm.controls).forEach((controlName: string) => {
      const newControl = new FormControl({
        value: originalForm.controls[controlName].value,
        disabled: originalForm.controls[controlName].disabled
      },
        originalForm.controls[controlName].validator);
      copiedForm.addControl(controlName, newControl);
    });
    return copiedForm;
  }

  protected subscribeToValueChanges(form: FormGroup) {
    this.totalGrammageSubscription = form.controls.totalGrammage.valueChanges.subscribe(newTotalGrammage =>
      this.compositeMaterial.totalGrammage = newTotalGrammage);
    this.gtinSubscription = form.controls.gtin.valueChanges.subscribe(async val => {
      form.controls.gtin.setValue(val?.trim(), { emitEvent: false });
      await this.gtinCheckService.checkGtinValidity(form);
      form.controls.gtin.markAsTouched();
    });
    this.manufCountrySubscription = form.controls.manufacturingCountry.valueChanges.subscribe(newCountry =>
      this.selectedManufacturingCountry = newCountry);
  }

  async doAction(form: FormGroup) {
    form.controls.multiMaterial.patchValue(this.compositeMaterial);
    form.updateValueAndValidity();
    await this.gtinCheckService.checkGtinValidity(form);
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
    const materialsValid = this.materialsTable.areMaterialLayersValid();
    if (form.invalid || !materialsValid) {
      return;
    } else {
      this.dialogRef.close({ event: this.action, data: form });
    }
  }

  totalWeightChanged(newTotalWeight: number, form: FormGroup): void {
    form.controls.totalWeight.patchValue(newTotalWeight);
    this.compositeMaterial.totalWeight = newTotalWeight;
  }

  closeDialog() {
    this.compositeMaterial = this.compositeMaterialBackup;
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  ngOnDestroy(): void {
    this.totalGrammageSubscription?.unsubscribe();
    this.gtinSubscription?.unsubscribe();
    this.manufCountrySubscription?.unsubscribe();
    this.typeSubscription?.unsubscribe();
  }
}
