import { AggregatedEvaluationsTextDataUtil } from 'src/app/util/analyses-util/aggregated-evaluations-text-data-util';
import { DeleteItemDialogComponent } from './../../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { RenameDialogComponent, RenameDialogType } from './../../../dialogs/rename-dialog/rename-dialog.component';
import { MomentLocaleUtil } from '../../../../util/moment-locale-util';
import { PackagingCollectiveQuantityDto } from '../../../../data-transfer/entities/packaging-unit-entities/packaging-quantity-dto';
import {
  AggregatedQuantitiesDialogComponent
} from './../../../dialogs/aggregated-quantities-dialog/aggregated-quantities-dialog.component';
import { PackagingUnitNavigationService } from '../../../../navigation/services/navigation-services/packaging-unit-navigation.service';
import { AggregateEvaluationInputDto, PackagingUnitIdVersion } from './../../../../data-transfer/entities/aggregate-evaluation-input-dto';
import { CountriesService } from './../../../../navigation/services/countries-service';
import { ColorThemeService, COLOR_THEME_DARK } from './../../../../navigation/services/color-theme-service';
import { AggregateEvaluationRecyclabilityOutputDto, AggregateEvaluationWrapper } from './../../../../data-transfer/entities/aggregate-evaluation-recyclability-output-dto';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, AfterViewChecked, ElementRef } from '@angular/core';
import { AggregateEvaluationLifecycleOutputDto } from 'src/app/data-transfer/entities/aggregate-evaluation-lifecycle-output-dto';
import { AggregatedAnalysisApiService } from 'src/app/data-transfer/services/aggregated-analysis-api-service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-view-aggregate-evaluations',
  templateUrl: './view-aggregate-evaluations.component.html',
  styleUrls: ['./view-aggregate-evaluations.component.scss']
})
export class ViewAggregateEvaluationsComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('recEvalCard', { read: ElementRef }) recyclabilityCard!: ElementRef;

  allRecEvaluations: AggregateEvaluationWrapper[] = [];
  allLcaEvaluations: AggregateEvaluationWrapper[] = [];

  selectedRecEvaluation: AggregateEvaluationRecyclabilityOutputDto | null = null;
  selectedLcaEvaluation: AggregateEvaluationLifecycleOutputDto | null = null;

  packagingUnitIdsVersions: PackagingUnitIdVersion[] = [];
  countryNames = '';
  dateFrom = '';
  dateTo = '';
  quantities!: PackagingCollectiveQuantityDto;

  isDarkTheme = false;
  isScrollingEnabled = false;

  private routeDataSubscription?: Subscription;
  private evalSubscription?: Subscription;
  private dialogSubscription?: Subscription;
  private deletionSubscription?: Subscription;
  private themeSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private aggAnalysisApiService: AggregatedAnalysisApiService,
    private colorThemeService: ColorThemeService,
    private countriesService: CountriesService,
    private packagingUnitNavigationService: PackagingUnitNavigationService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private textDataUtil: AggregatedEvaluationsTextDataUtil,
    private cd: ChangeDetectorRef
  ) {
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
    });
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.allRecEvaluations = data.allAggregatedRecEvaluations;
      this.allLcaEvaluations = data.allAggregatedLcaEvaluations;

      this.allRecEvaluations.forEach(x => this.formatDate(x));
      this.allLcaEvaluations.forEach(x => this.formatDate(x));
    });
  }

  ngAfterViewChecked() {
    if (this.recyclabilityCard) {
      const objDivElement = this.recyclabilityCard.nativeElement;
      this.isScrollingEnabled = objDivElement?.scrollHeight > objDivElement?.clientHeight;
      this.cd.detectChanges();
    }
  }

  scrollToTable() {
    if (this.recyclabilityCard) {
      const objDivElement = this.recyclabilityCard.nativeElement;
      let scrollHeight = objDivElement.scrollHeight;
      scrollHeight = 650;
      objDivElement.scroll({
        top: scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  showRecEvaluationDetails(recEvaluationWrapper: AggregateEvaluationWrapper) {
    this.spinner.show();
    this.evalSubscription = this.aggAnalysisApiService.getAggregateRecyclabilityById(recEvaluationWrapper.aggregationResultId)
      .subscribe(recResult => {
        this.aggAnalysisApiService.getAggregateRecEvalQuantities(recEvaluationWrapper.aggregationResultId).subscribe(quantities => {
          this.selectedRecEvaluation = recResult;
          this.setAnalysisData(recResult.request, quantities);
          this.spinner.hide();
        });
      });
  }

  showLcaEvaluationDetails(lcaEvaluationWrapper: AggregateEvaluationWrapper) {
    this.spinner.show();
    this.evalSubscription = this.aggAnalysisApiService.getAggregateLifecycleById(lcaEvaluationWrapper.aggregationResultId)
      .subscribe(lcaResult => {
        this.aggAnalysisApiService.getAggregateLcaEvalQuantities(lcaEvaluationWrapper.aggregationResultId)
          .subscribe(quantities => {
            this.selectedLcaEvaluation = lcaResult;
            this.setAnalysisData(lcaResult.request, quantities);
            this.spinner.hide();
          });
      });
  }

  private setAnalysisData(input: AggregateEvaluationInputDto, quantities: PackagingCollectiveQuantityDto) {
    this.packagingUnitIdsVersions = input.packagingUnits;
    this.countryNames = input.countries.map(x => this.countriesService.getCountryNameByCode(x)).join(', ');
    this.dateFrom = MomentLocaleUtil.getTranslatedMonthYear(input.yearFrom, input.monthFrom - 1);
    this.dateTo = MomentLocaleUtil.getTranslatedMonthYear(input.yearTo, input.monthTo - 1);
    this.quantities = quantities;
  }

  private formatDate(wrapper: AggregateEvaluationWrapper) {
    const date = new Date(wrapper.evaluationReferenceTimestamp);
    wrapper.evaluationReferenceTimestamp = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  navigateToPackaging(packagingId: number) {
    this.packagingUnitNavigationService.navigateToPackagingUnit(packagingId);
  }

  showQuantities() {
    if (!this.quantities) { return; }
    const dialogConfig = getDialogConfig(this.quantities.packagingUnitQuantities.filter(x => x.quantity > 0));
    this.dialog.open(AggregatedQuantitiesDialogComponent, dialogConfig);
  }

  editRecEvalName(evaluation: AggregateEvaluationWrapper) {
    const dialogRef = this.openRenameDialog(evaluation.name);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      this.aggAnalysisApiService.renameAggregateEvaluationRec(evaluation.aggregationResultId, result.data.name).subscribe(_ => {
        const recEval = this.allRecEvaluations.find(x => x.aggregationResultId === evaluation.aggregationResultId);
        if (recEval) { recEval.name = result.data.name; }
      });
    });
  }

  editLcaEvalName(evaluation: AggregateEvaluationWrapper) {
    const dialogRef = this.openRenameDialog(evaluation.name);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      this.aggAnalysisApiService.renameAggregateEvaluationLca(evaluation.aggregationResultId, result.data.name).subscribe(_ => {
        const lcaEval = this.allLcaEvaluations.find(x => x.aggregationResultId === evaluation.aggregationResultId);
        if (lcaEval) { lcaEval.name = result.data.name; }
      });
    });
  }

  deleteRecEval(evaluation: AggregateEvaluationWrapper) {
    const dialogRef = this.openDeleteDialog();
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      this.deletionSubscription = this.aggAnalysisApiService.deleteAggregateRecEval(evaluation.aggregationResultId).subscribe({
        next: _ => {
          const index = this.allRecEvaluations.indexOf(evaluation);
          if (index != null) {
            this.allRecEvaluations.splice(index, 1);
          }
        },
        error: _ => {},
      });
    });
  }

  deleteLcaEval(evaluation: AggregateEvaluationWrapper) {
    const dialogRef = this.openDeleteDialog();
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) {
        return;
      }
      this.deletionSubscription = this.aggAnalysisApiService.deleteAggregateLcaEval(evaluation.aggregationResultId).subscribe({
        next: _ => {
          const index = this.allLcaEvaluations.indexOf(evaluation);
          if (index != null) {
            this.allLcaEvaluations.splice(index, 1);
          }
        },
        error: _ => {},
      });
    });
  }

  private openRenameDialog(evaluationName: string): MatDialogRef<RenameDialogComponent> {
    const dialogConfig = getDialogConfig({ name: evaluationName, type: RenameDialogType.ViewEvaluation });
    return this.dialog.open(RenameDialogComponent, dialogConfig);
  }

  private openDeleteDialog(): MatDialogRef<DeleteItemDialogComponent> {
    const dialogConfig = getDialogConfig(this.textDataUtil.getDeleteEvalConfirmationDialogData());
    return this.dialog.open(DeleteItemDialogComponent, dialogConfig);
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.evalSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.deletionSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }
}
