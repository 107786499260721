import { Observable, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { EnvironmentalEffectDto } from '../data-transfer/entities/environmental-effect-dto';
import { CountryDto } from '../data-transfer/entities/country-dto'

@Injectable({ providedIn: 'root' })
export class LicenseService {

  private _isLcaAllowed: boolean;
  private _isCCAllowed: boolean;
  private _allowedEffects: EnvironmentalEffectDto[];
  private _allowedCountries: CountryDto[];

  constructor(
    private http: HttpClient,
  ) {
    this._isLcaAllowed = false;
    this._isCCAllowed = false
    this._allowedEffects = [];
    this._allowedCountries = [];
    this.getLcaAllowed();
    this.getCCAllowed();
    this.getAuthorizedEnvironmentalEffects();
    this.getAuthorizedCountries();
  }

  get isLcaAllowed() : boolean {
    return this._isLcaAllowed
  }

  get isCCAllowed() : boolean {
    return this._isCCAllowed
  }

  get allowedEffects() : EnvironmentalEffectDto[] {
    return this._allowedEffects
  }

  get allowedCountries() : CountryDto[] {
    return this._allowedCountries
  }

  public async refreshingLicenses() {
      await this.getLcaAllowed();
      await this.getAuthorizedEnvironmentalEffects();
      await this.getCCAllowed();
      this.getAuthorizedCountries();
  }

  public async getAuthorizedEnvironmentalEffectsFromTimeStampAsync(timestamp : string) : Promise<EnvironmentalEffectDto[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('timestamp', timestamp);
    const url =  `${environment.webApiAddress}/api/licensing/authorized-environmental-effects`;
    return firstValueFrom(this.http.get<EnvironmentalEffectDto[]>(url, { params }));
  }

  public async getAuthorizedCountriesFromTimeStampAsync(timestamp : string) : Promise<CountryDto[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('timestamp', timestamp);
    const url =  `${environment.webApiAddress}/api/licensing/authorized-countries`;
    return firstValueFrom(this.http.get<CountryDto[]>(url, { params }));
  }

  private async getLcaAllowed() {
    const url = `${environment.webApiAddress}/api/licensing/is-lca-analysis-allowed`;
    const result = await this.http.get<boolean>(url).toPromise();
    if (result !== undefined) {
      this._isLcaAllowed = result;
    }
  }

  private async getCCAllowed() {
    const url = `${environment.webApiAddress}/api/licensing/is-cost-calculation-allowed`;
    const result = await this.http.get<boolean>(url).toPromise();
    if (result !== undefined) {
      this._isCCAllowed = result;
    }
  }

  private async getAuthorizedEnvironmentalEffects() {
    const url = `${environment.webApiAddress}/api/licensing/authorized-countries`;
    const result = await this.http.get<CountryDto[]>(url).toPromise();
    if (result !== undefined) {
      this._allowedCountries = result;
    }
  }

  private async getAuthorizedCountries() {
    const url = `${environment.webApiAddress}/api/licensing/authorized-environmental-effects`;
    const result = await this.http.get<EnvironmentalEffectDto[]>(url).toPromise();
    if (result !== undefined) {
      this._allowedEffects = result;
    }
  }
}
