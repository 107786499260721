import { CustomFieldAssignmentsDto } from '../custom-field-assignments-dto';
import { PackagingParentDto } from '../packaging-parent-dto';
import { PackagingUnitDto } from '../packaging-unit-entities/packaging-unit-dto';

export class PackagingSystemDto extends PackagingParentDto {
    id!: number;
    packagingSystems: PackagingSystemChildSystem[] = [];
    packagingUnits: PackagingSystemChildUnit[] = [];
    customFields: CustomFieldAssignmentsDto | null = null;
}

export class PackagingSystemChildSystem {
    index!: number;
    quantity!: number;
    underlyingPackagingSystem!: PackagingSystemDto;
}

export class PackagingSystemChildUnit {
    index!: number;
    quantity!: number;
    underlyingPackagingUnit!: PackagingUnitDto;
}
