import { CustomFieldAssignmentsDto } from "../custom-field-assignments-dto";
import { PackagingParentDto } from "../packaging-parent-dto";

export class PackagingSystemUpdateDto extends PackagingParentDto {
  id?: number;
  packagingSystems?: PackagingEntityInHierarchyDto[];
  packagingUnits?: PackagingEntityInHierarchyDto[];
  customFields: CustomFieldAssignmentsDto | null = null;
}

export class PackagingEntityInHierarchyDto {
  id?: number;
  version?: number;
  quantity?: number;
  index?: number;
}
