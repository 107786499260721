<mat-toolbar *ngIf="itemId != null && !isPreview && !(isPackagingUnit && isGeneric)">
  <div id="menu-row">
    <button mat-button (click)="snav.toggle()" type="button" class="toolbar-text">
      <mat-icon>menu</mat-icon>
      {{ 'sidenav.title' | translate }}
    </button>
    <div class="wrapped-toolbar-text toolbar-text">
      {{ 'packagingUnit.fields.productName' | translate }}: {{ getProductNameText() }}
    </div>
    <div class="wrapped-toolbar-text toolbar-text" *ngIf="getArticleNumberText() !== ''; else emptyDiv">
      {{ 'packagingUnit.fields.articleNumber' | translate }}: {{ getArticleNumberText() }}
    </div>
    <ng-template #emptyDiv>
      <div></div>
    </ng-template>
  </div>
</mat-toolbar>

<mat-drawer-container>
  <mat-drawer #snav [mode]="'side'">
    <h3 class="header-margin">{{ 'sidenav.masterData' | translate }}</h3>
    <mat-nav-list>
      <a mat-list-item *ngIf="!isUserValidator && isWriteAllowed; else viewPackaging" (click)="navigateToItem()">
        <span matLine>{{ (isPackagingUnit ? 'packagingUnit.editPackagingUnit' :
          'packagingSystem.editPackagingSystem') | translate }}</span>
        <span matLine class="hint">{{ 'home.packagingUnitHome.latestVersion' | translate }}</span>
      </a>
      <ng-template #viewPackaging>
        <a mat-list-item (click)="navigateToItem()">
          <span matLine>{{ (isPackagingUnit ? 'packagingUnit.viewPackagingUnit' :
            'packagingSystem.viewPackagingSystem') | translate }}</span>
          <span matLine class="hint">{{ 'home.packagingUnitHome.latestVersion' | translate }}</span>
        </a>
      </ng-template>
      <a mat-list-item *ngIf="!isCurrentVersion" (click)="navigateToItem(versionId)">
        <span matLine>{{ (isPackagingUnit ? 'packagingUnit.viewPackagingUnit' :
          'packagingSystem.viewPackagingSystem') | translate }}</span>
        <span matLine class="hint">{{ 'home.packagingUnitHome.historyVersion' | translate }}</span>
      </a>
      <a mat-list-item (click)="downloadPackagingUnitJson()" *ngIf="isPackagingUnit">
        <span matLine>{{ 'sidenav.downloadPackagingUnitText' | translate }}</span>
        <span matLine class="hint">{{ 'sidenav.downloadPackagingUnitHint' | translate }}</span>
      </a>
    </mat-nav-list>
    <div *ngIf="!isUserValidator">
      <h3 class="header-margin">{{ 'sidenav.evaluations' | translate }}</h3>
      <mat-nav-list *ngIf="isPackagingUnit; else systemsNavigation">
        <a mat-list-item [matMenuTriggerFor]="analysesUnits.analysesRecyclability"
          (click)="analysesUnits.loadAnalysesForPackagingUnit()">
          {{ 'analysis.recyclabilityAnalysis.title' | translate }} <mat-icon class="arrow-icon">keyboard_arrow_right
          </mat-icon>
        </a>
        <a mat-list-item [matMenuTriggerFor]="analysesUnits.analysesLca"
          (click)="analysesUnits.loadAnalysesForPackagingUnit()" [disabled]="!isLcaAllowed()"
          [matTooltip]="!isLcaAllowed()? ('warnings.NoPremiumUserAndEnvironmentalEffects' | translate) : ''"
          matTooltipPosition = "left">
          {{ 'analysis.lifecycleAnalysis.title' | translate }} <mat-icon class="arrow-icon">keyboard_arrow_right
          </mat-icon>
        </a>
        <a mat-list-item [matMenuTriggerFor]="analysesUnits.analysesPlasticTax"
          (click)="analysesUnits.loadAnalysesForPackagingUnit()" [disabled]="!isCCAllowed()"
          [matTooltip]="!isCCAllowed()? ('warnings.OrganizationInsufficientPermissions' | translate) : ''"
          matTooltipPosition = "left">
          {{ 'analysis.expenseCalculation.plasticTax.title' | translate }} <mat-icon class="arrow-icon">keyboard_arrow_right
          </mat-icon>
        </a>
        <a mat-list-item [matMenuTriggerFor]="analysesUnits.analysesLicenseFee"
          (click)="analysesUnits.loadAnalysesForPackagingUnit()" [disabled]="!isCCAllowed()"
          [matTooltip]="!isCCAllowed()? ('warnings.OrganizationInsufficientPermissions' | translate) : ''"
          matTooltipPosition = "left">
          {{ 'analysis.expenseCalculation.licenseFee.title' | translate }} <mat-icon class="arrow-icon">keyboard_arrow_right
          </mat-icon>
        </a>
      </mat-nav-list>
      <app-analyses-menu-packaging-units #analysesUnits [packagingUnitId]="itemId"
        [packagingUnitType]="callerTypeId" [versionId]="versionId" [isUserValidator]="isUserValidator"
        [caller]="callerPageId" [hasRecyclabilityResult]="hasRecyclabilityResult"
        [hasLifeCycleResult]="hasLifeCycleResult" [creationTimestamp]="creationTimestamp">
      </app-analyses-menu-packaging-units>
      <ng-template #systemsNavigation>
        <mat-nav-list>
          <a mat-list-item [matMenuTriggerFor]="analysesSystems.analysesRecyclability"
            (click)="analysesSystems.loadAnalysesForPackagingSystem()">
            {{ 'analysis.recyclabilityAnalysis.title' | translate }} <mat-icon class="arrow-icon">keyboard_arrow_right
            </mat-icon>
          </a>
          <a mat-list-item [matMenuTriggerFor]="analysesSystems.analysesLca"
            (click)="analysesSystems.loadAnalysesForPackagingSystem()" [disabled]="!isLcaAllowed()"
            [matTooltip]="!isLcaAllowed()? ('warnings.NoPremiumUserAndEnvironmentalEffects' | translate) : ''"
            matTooltipPosition = "left">
            {{ 'analysis.lifecycleAnalysis.title' | translate }} <mat-icon class="arrow-icon">keyboard_arrow_right
            </mat-icon>
          </a>
        </mat-nav-list>
      </ng-template>
      <app-analyses-menu-packaging-systems #analysesSystems [packagingSystemId]="itemId" [versionId]="versionId"
        [isUserValidator]="isUserValidator" [caller]="callerPageId" [hasRecyclabilityResult]="hasRecyclabilityResult"
        [hasLifeCycleResult]="hasLifeCycleResult" [creationTimestamp]="creationTimestamp">
      </app-analyses-menu-packaging-systems>
    </div>
    <div *ngIf="!isUserValidator && isPackagingUnit">
      <h3 class="header-margin">{{ 'report.title' | translate }}</h3>
      <mat-nav-list>
        <a mat-list-item [matMenuTriggerFor]="reports.reportsMenu" (click)="reports.loadReports()">
          {{ 'report.title' | translate }} <mat-icon class="arrow-icon">keyboard_arrow_right</mat-icon>
        </a>
      </mat-nav-list>
      <app-reports-menu-for-packaging-part #reports [callerItemId]="itemId" [callerPage]="callerPageId"
        [callerLevel]="callerLevelId">
      </app-reports-menu-for-packaging-part>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div>
      <ng-content></ng-content>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
