<mat-card id="outer-card">
  <mat-card-header>
    <mat-card-title>{{ 'report.reports' | translate }}</mat-card-title>
  </mat-card-header>
  <div class="local-scrolling-wrapper">
    <mat-tab-group>
      <mat-tab label="{{ 'packagingUnit.packagingUnits' | translate }}">
        <ng-container *ngTemplateOutlet="reportsTable; context: {
            dataSource: packagingUnitReportsDataSource,
            displayedColumns: packagingUnitDisplayedColumns,
            callerLevel: packagingPartEnum.Unit }">
        </ng-container>
      </mat-tab>
      <mat-tab label="{{ 'component.packagingComponents' | translate }}">
        <ng-container *ngTemplateOutlet="reportsTable; context: {
          dataSource: componentReportsDataSource,
          displayedColumns: componentDisplayedColumns,
          callerLevel: packagingPartEnum.Component }">
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-card>

<ng-template #reportsTable let-dataSource="dataSource" let-displayedColumns="displayedColumns" ,
  let-callerLevel="callerLevel">
  <mat-table [dataSource]="dataSource">
    <div *ngFor="let column of displayedColumns">
      <div *ngIf="column !== 'actions'; else actionColumn">
        <ng-container [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>{{ "report.tableColumn." + column | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element[column]}}</mat-cell>
        </ng-container>
      </div>
      <ng-template #actionColumn>
        <ng-container [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>{{ "report.tableColumn." + column | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button color="primary" (click)="viewReport(element.id, callerLevel)">
              <mat-icon>preview</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="downloadReport(element.id, callerLevel)">
              <mat-icon>save_alt</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="deleteReport(element.id, callerLevel)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
      </ng-template>
    </div>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</ng-template>
