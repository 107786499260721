
<h1 mat-dialog-title>{{ 'dataManagement.customFields.title' | translate }}</h1>

<mat-dialog-content>
  <mat-tab-group>
    <mat-tab *ngFor="let profile of profiles" label="{{profile.name}}">

      <form>
        <div class="grid-container">
          <mat-form-field *ngFor="let field of profile.fields" class="grid-item form-field-wide">
            <mat-label class="label-large">{{ field.name }}</mat-label>

              <ng-container [ngSwitch]="field.type">

                <mat-select *ngSwitchCase="fieldType.Boolean" [formControl]="field.control">
                  <mat-option>{{ 'common.text.noSelection' | translate }}</mat-option>
                  <mat-option *ngFor="let option of booleanValuesDictionary" [value]="option.key">
                    {{option.value}}
                  </mat-option>
                </mat-select>

                <input *ngSwitchCase="fieldType.String" [formControl]="field.control" matInput type="text" autocomplete="off">

                <input *ngSwitchCase="fieldType.Integer" [formControl]="field.control" matInput type="number" autocomplete="off" >

                <input *ngSwitchCase="fieldType.Decimal" [formControl]="field.control" matInput type="number" autocomplete="off">

                <mat-select *ngSwitchCase="fieldType.Dropdown" [formControl]="field.control">
                  <mat-option>{{ 'common.text.noSelection' | translate }}</mat-option>
                  <mat-option *ngFor="let option of field.options" [value]="option.id">
                    {{option.name}}
                  </mat-option>
                </mat-select>

              </ng-container>

              <mat-error *ngIf="field.control.errors">
                <span *ngIf="field.control.errors.notInteger">
                  {{ 'dataManagement.customFields.notInteger' | translate }}
                </span>
              </mat-error>

          </mat-form-field>

        </div>
      </form>

    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions id="buttons-container">
  <button mat-raised-button type="submit" color="primary" (click)="onSaveButtonClicked()" >
    {{ 'common.buttons.save' | translate }}
  </button>

  <button mat-raised-button (click)="onCancelButtonClicked()" >
    {{ 'common.buttons.cancel' | translate }}
  </button>

</mat-dialog-actions>


