import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ColorThemeService } from 'src/app/navigation/services/color-theme-service';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { SimpleDialogData, SimpleAlertDialogComponent } from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { ExpenseCalculationMapPoint } from '../charts/map-expense-calculation/map-expense-calculation.component';
import { ExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';
import { TranslateService } from '@ngx-translate/core';
import { AnalysisParentComponent } from '../analysis-parent/analysis-parent.component';
import { ValidatorApiService } from 'src/app/data-transfer/services/validator-api-service';
import { AuthService } from 'src/app/services/auth-service';
import { CreditsService } from 'src/app/services/credits-service';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { CountriesExpenseAvailabilityDict } from 'src/app/model/expense-countries';
import { DecimalPipe } from '@angular/common';

class MappedExpenseResultDto {
  countryName!: string;
  countryCode!: string;
  amount!: number;
  displayedAmount!: string;
  displayedAmountPerKilo!: string;
  source?: string;
}

@Component({
  selector: 'app-expense-calculation-html-template',
  templateUrl: './expense-calculation-html-template.component.html',
  styleUrls: ['./expense-calculation-html-template.component.scss']
})
export class ExpenseCalculationHtmlTemplateComponent extends AnalysisParentComponent implements OnInit, OnChanges {

  @Input() packagingPart!: PackagingUnitDto | PackagingSystemDto;
  @Input() packagingPartWeight!: number;
  @Input() expenseResult!: ExpenseResultDto[];
  @Input() countriesAvailability!: CountriesExpenseAvailabilityDict[];
  @Input() title!: string;
  @Input() noExpenseText!: string;
  @Input() public packagingPartType!: number;

  chartDataSource!: ExpenseCalculationMapPoint[];

  dataSource: MatTableDataSource<MappedExpenseResultDto>;
  columns: { key: string, header: string }[];
  displayedColumns: string[] = [];
  selectedRow: MappedExpenseResultDto | undefined;

  toFixedFunc = (number: number) => +number.toFixed(4);

  constructor(
    private countriesService: CountriesService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    protected validatorApiService: ValidatorApiService,
    protected colorThemeService: ColorThemeService,
    protected authService: AuthService,
    protected creditsService: CreditsService,
    private decimalPipe: DecimalPipe
  ) {
    super(validatorApiService, colorThemeService, authService, creditsService);
    this.dataSource = new MatTableDataSource<MappedExpenseResultDto>();
    this.columns = [
      { key: 'countryName', header: this.translateService.instant('analysis.expenseCalculation.tableColumns.countryName') },
      { key: 'displayedAmount', header: this.translateService.instant('analysis.expenseCalculation.tableColumns.amount') },
      { key: 'displayedAmountPerKilo', header: this.translateService.instant('analysis.expenseCalculation.tableColumns.amountPerKilo') }]
    this.displayedColumns = this.columns.map(x => x.key);
  }

  ngOnInit(): void {
    super.setPackagingPartData(this.packagingPart);
    this.callerLevelId = this.packagingPartType;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expenseResult) {
      this.dataSource.data = this.mapExpenseResult(this.expenseResult);
      this.chartDataSource = this.dataSource.data.filter(x => x.amount != null && x.amount !== 0)
        .map(x => {
          return { code2: x.countryCode, name: x.countryName, z: x.amount ?? 0 }
        });
    }
  }

  private mapExpenseResult(expenseResult: ExpenseResultDto[]): MappedExpenseResultDto[] {
    const mappedExpenseResult: MappedExpenseResultDto[] = [];
    expenseResult.forEach(x => {
      const isExpenseCalculated = x.expense != null;
      const amountNotRounded = x.expense?.amount ?? 0;
      const amount = this.toFixedFunc(amountNotRounded);
      const packagingWeight = this.packagingPartWeight != null && this.packagingPartWeight !== 0 ? this.packagingPartWeight : 1;
      const amountPerKilo = this.toFixedFunc(amountNotRounded / (packagingWeight / 1000));

      const currencyName = x.expense?.currency.name;
      let currencySymbol: string;
      if (currencyName != null) {
        currencySymbol = currencyName === 'GBP' ? '£' : (currencyName === 'EUR' ? '€'  : currencyName);
      } else {
        currencySymbol = x.evaluatedCountry === 'UK' ? '£' : '€';
      }
      const countryHasExpense = (isExpenseCalculated && this.countriesAvailability.find(country =>
        country.code === x.evaluatedCountry)?.hasTax) ?? false;

      const displayedAmount = countryHasExpense ? `${this.decimalPipe.transform(amount, '1.4-4')} ${currencySymbol}` : this.noExpenseText;
      const displayedAmountPerKilo = countryHasExpense ? `${this.decimalPipe.transform(amountPerKilo, '1.4-4')} ${currencySymbol}` : '--';
      mappedExpenseResult.push(
        {
          countryCode: x.evaluatedCountry,
          countryName: this.getTranslatedCountryCode(x.evaluatedCountry),
          amount: countryHasExpense ? amount : 0,
          displayedAmount,
          displayedAmountPerKilo,
          source: x.expense?.informationSource
        }
      )
    })
    return mappedExpenseResult;
  }

  private getTranslatedCountryCode(countryCode: string) {
    const translatedName = this.countriesService.getCountries().find(y => y.code === countryCode)?.name;
    return translatedName ?? countryCode;
  }

  countryClicked(countryCode: string) {
    const expenseResult = this.dataSource.data.find(x => x.countryCode === countryCode);
    if (!expenseResult) { return; }
    this.selectedRow = expenseResult;
    const dialogData: SimpleDialogData = {
      title: expenseResult.countryName,
      messages: [
        `${this.translateService.instant('analysis.expenseCalculation.tableColumns.amount')}: ${expenseResult.displayedAmount}`,
        `${this.translateService.instant('analysis.expenseCalculation.tableColumns.amountPerKilo')}:
        ${expenseResult.displayedAmountPerKilo}`],
      icon: 'info'
    }
    const dialogConfig: MatDialogConfig = getDialogConfig(dialogData);
    this.dialog.open(SimpleAlertDialogComponent, dialogConfig)
  }
}
