import { PackagingSystemApiService } from 'src/app/data-transfer/services/packaging-system-api-service';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PackagingSystemNavigationService } from './../../../../navigation/services/navigation-services/packaging-system-navigation.service';
import { PackagingSystemChildSystem, PackagingSystemChildUnit, PackagingSystemDto } from './../../../../data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ComponentCanDeactivate } from 'src/app/services/unsaved-changes-guard';
import { AuthService } from 'src/app/services/auth-service';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdateHandler } from 'src/app/services/packaging-services/create-update-handler';
import { ColorThemeService } from 'src/app/navigation/services/color-theme-service';
import { ActivatedRoute } from '@angular/router';
import { VersionDto } from 'src/app/data-transfer/entities/version-dto';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { ChangedPackagingPartDisplayInfo, CreateUpdatePackagingPartComponent } from 'src/app/components/shared-components/parent-components/create-update-packaging-part/create-update-packaging-part.component';
import { PackagingEntityInHierarchyDto, PackagingSystemUpdateDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-update-dto';
import { MatTableDataSource } from '@angular/material/table';
import { PackagingSystemInfoDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { SelectionDialogPackagingSystemsComponent } from 'src/app/components/dialogs/selection-dialog-packaging-systems/selection-dialog-packaging-systems.component';
import { Observable, firstValueFrom, forkJoin } from 'rxjs';
import { SelectionDialogPackagingUnitsComponent } from 'src/app/components/dialogs/selection-dialog-packaging-units/selection-dialog-packaging-units.component';
import { PackagingUnitInfoDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { PackagingUnitApiService } from 'src/app/data-transfer/services/packaging-unit-api-service';
import { DialogActions } from 'src/app/model/dictionary';
import { PackagingSystemTreeLevel, PackagingUnitTreeLevel, TreeLevelParent } from 'src/app/components/shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';

@Component({
  selector: 'app-create-update-packaging-system',
  templateUrl: './create-update-packaging-system.component.html',
  styleUrls: ['./create-update-packaging-system.component.scss']
})
export class CreateUpdatePackagingSystemComponent extends CreateUpdatePackagingPartComponent implements OnInit, ComponentCanDeactivate {

  packagingSystemForm!: FormGroup;
  packagingSystem!: PackagingSystemDto;
  selectedSystemNode: PackagingSystemTreeLevel | null = null;
  selectedUnitNode: PackagingUnitTreeLevel | null = null;
  currentHierarchyFormGroup!: FormGroup;
  formSubmitted = false;
  componentTypes = PackagingComponentTypesEnum;
  private packagingSystemChildrenUpdated = false;

  get currentHierarchyFormGroupQuantityControl(): FormControl {
    return (this.currentHierarchyFormGroup.controls.quantity as FormControl);
  }

  get currentHierarchyFormGroupCurrentUnitControl(): FormControl {
    return (this.currentHierarchyFormGroup.controls.currentUnit as FormControl);
  }

  get currentHierarchyFormGroupCurrentSystemControl(): FormControl {
    return (this.currentHierarchyFormGroup.controls.currentSystem as FormControl);
  }

  get packagingSystemFieldsControl(): FormControl {
    return this.packagingSystemForm.controls.packagingSystemFields as FormControl;
  }

  constructor(
    protected createUpdateHandler: CreateUpdateHandler,
    protected dialog: MatDialog,
    protected cdr: ChangeDetectorRef,
    protected colorThemeService: ColorThemeService,
    protected authService: AuthService,
    protected spinner: NgxSpinnerService,
    protected route: ActivatedRoute,
    protected fileApiService: FileApiService,
    private psApiService: PackagingSystemApiService,
    private puApiService: PackagingUnitApiService,
    private formBuilder: FormBuilder,
    private navigationService: PackagingSystemNavigationService
  ) {
    super(createUpdateHandler, dialog, colorThemeService, authService, cdr, route, spinner, fileApiService);
  }

  ngOnInit(): void {
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      if (params.candidateId) { this.isPreview = true; }
    });
    this.routeDataSubscription = this.route.data.subscribe(data => {
      if (data.packagingSystem) {
        if (this.packagingSystemForm) {
          window.location.reload();
          this.initPackaging();
        }
        this.packagingSystem = data.packagingSystem;
        this.currentDirectoryId = data.packagingSystem.directoryId;
        this.selectedTags = this.allTags.filter(tag => this.packagingSystem.associatedTagIdentifiers?.includes(tag.id ?? -1) ?? false);
      } else {
        this.packagingSystem = new PackagingSystemDto();
      }
      if (data.permissions) {
        this.permissions = data.permissions;
        this.isEditPermitted = this.permissions?.write ?? true;
      }
      this.setChangedPackagingPartsInfo(data.changedSystemIds ?? [], data.changedUnitIds ?? []);

      this.packagingSystemForm = this.formBuilder.group({
        packagingSystemFields: this.formBuilder.control(this.packagingSystem),
        hierarchy: this.formBuilder.group({
          id: this.packagingSystem.id,
          currentSystem: this.packagingSystem,
          childSystems: this.formBuilder.array([]),
          childUnits: this.formBuilder.array([])
        }),
        newVersionName: [''],
      });
      this.packagingSystemForm.controls.hierarchy =
        this.createFormRecursively(this.packagingSystem, this.packagingSystemForm.controls.hierarchy as FormGroup);
      this.currentHierarchyFormGroup = this.getCurrentHierarchyLevelFormGroup();
      this.initPackaging();
    });
  }

  private setChangedPackagingPartsInfo(changedSystemIds: number[], changedUnitIds: number[]) {
    for (const changedId of changedSystemIds) {
      const changedSystem = this.packagingSystem.packagingSystems.find(x => x.underlyingPackagingSystem.id === changedId);
      if (changedSystem) {
        this.changedPackagingPartsInfo.push({
          id: changedSystem.underlyingPackagingSystem.id ?? -1,
          name: changedSystem.underlyingPackagingSystem.brandName,
          packagingPart: PackagingPart.System,
          type: 'packagingSystem',
          subtype: ''
        });
      }
    }
    for (const changedId of changedUnitIds) {
      const changedUnit = this.packagingSystem.packagingUnits.find(x => x.underlyingPackagingUnit.id === changedId);
      if (changedUnit) {
        this.changedPackagingPartsInfo.push({
          id: changedUnit.underlyingPackagingUnit.id ?? -1,
          name: changedUnit.underlyingPackagingUnit.brandName,
          packagingPart: PackagingPart.Unit,
          type: 'packagingUnit',
          subtype: ''
        });
      }
    }
  }

  async acceptPackagingPartChange(packagingPart: ChangedPackagingPartDisplayInfo) {
    const shouldCreateNewVersion = await super.shouldCreateNewVersion(true, PackagingPart.System);
    if (shouldCreateNewVersion == null) { return; }
    const acceptFunction = packagingPart.packagingPart === PackagingPart.System ?
      (systemId: number, changedId: number, shouldCreateNewVersion: boolean) =>
        this.psApiService.acceptPackagingSystemChange(systemId, changedId, shouldCreateNewVersion) :
      packagingPart.packagingPart === PackagingPart.Unit ?
        (systemId: number, changedId: number, shouldCreateNewVersion: boolean) =>
          this.psApiService.acceptPackagingUnitChange(systemId, changedId, shouldCreateNewVersion) : null;
    if (this.packagingSystem.id == null || acceptFunction == null) { return; }
    super.acceptChange(this.packagingSystem.id, packagingPart.id, acceptFunction, shouldCreateNewVersion);
  }

  declinePackagingPartChange(packagingPart: ChangedPackagingPartDisplayInfo) {
    const declineFunction = packagingPart.packagingPart === PackagingPart.System ?
      (systemId: number, changedId: number) => this.psApiService.declinePackagingSystemChange(systemId, changedId) :
      packagingPart.packagingPart === PackagingPart.Unit ?
        (systemId: number, changedId: number) => this.psApiService.declinePackagingUnitChange(systemId, changedId) : null;
    if (this.packagingSystem.id == null || declineFunction == null) { return; }
    super.declineChange(this.packagingSystem.id, packagingPart.id, declineFunction);
  }

  private createFormRecursively(packagingSystem: PackagingSystemDto, hierarchyNode: FormGroup) {
    hierarchyNode.controls.childSystems = this.formBuilder.array([]);
    packagingSystem.packagingSystems.forEach(child => {
      const childSystems = this.formBuilder.array([]);
      const control = this.formBuilder.group({
        id: child.underlyingPackagingSystem.id,
        version: child.underlyingPackagingSystem.version,
        index: child.index,
        quantity: new FormControl(child.quantity, { updateOn: 'blur' }),
        currentSystem: child.underlyingPackagingSystem,
        childSystems
      });
      control.controls.currentSystem.disable();
      (hierarchyNode.controls.childSystems as FormArray).push(control);
      this.addPackagingUnitsToLevel(child.underlyingPackagingSystem, hierarchyNode);
      this.createFormRecursively(child.underlyingPackagingSystem, control);
    });
    this.addPackagingUnitsToLevel(packagingSystem, hierarchyNode);
    return hierarchyNode;
  }

  private addPackagingUnitsToLevel(packagingSystem: PackagingSystemDto, hierarchyNode: FormGroup) {
    hierarchyNode.controls.childUnits = this.formBuilder.array([]);
    packagingSystem.packagingUnits.forEach(child => {
      const packagingUnit = child.underlyingPackagingUnit;
      const unitControl: FormGroup = this.formBuilder.group({
        id: packagingUnit.id,
        version: packagingUnit.version,
        index: child.index,
        quantity: new FormControl(child.quantity, { updateOn: 'blur' }),
        currentUnit: packagingUnit
      });
      if (packagingUnit.mainBody) {
        unitControl.addControl('mainBody', this.formBuilder.control([packagingUnit.mainBody]));
      }
      if (packagingUnit.closures.length > 0) {
        unitControl.addControl('closures', this.formBuilder.control(packagingUnit.closures));
      }
      if (packagingUnit.sleeves.length > 0 || packagingUnit.labels.length > 0 || packagingUnit.inMoldLabels.length > 0) {
        unitControl.addControl('decorations', this.formBuilder.control(
          packagingUnit.sleeves.concat(packagingUnit.labels).concat(packagingUnit.inMoldLabels)));
      }
      if (packagingUnit.inlays.length > 0) {
        unitControl.addControl('inlays', this.formBuilder.control(packagingUnit.inlays));
      }
      if (packagingUnit.packagingAids.length > 0) {
        unitControl.addControl('packagingAids', this.formBuilder.control(packagingUnit.packagingAids));
      }
      unitControl.controls.currentUnit.disable();
      (hierarchyNode.controls.childUnits as FormArray).push(unitControl);
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return (this.packagingSystemForm.pristine && !this.filesEdited)
      || this.isPreview || this.isUserValidator || this.isUserAdmin || this.isHistory;
  }

  async releaseComponent() {
    const releaseFunction = (packagingSystemId: number) => this.psApiService.releasePackagingSystem(packagingSystemId, true);
    super.releaseComponent(this.packagingSystem.id, releaseFunction);
  }

  private initPackaging() {
    this.isHistory = this.packagingSystem.id != null && !this.packagingSystem.isCurrentVersion;
    this.isTracked = this.packagingSystem.hasExternalTracking ?? false;
    const lockFunction = (packagingSystemId: number) => this.psApiService.lockPackagingSystem(packagingSystemId, true);
    this.setUpLockAndHeartbeat(this.packagingSystemForm, lockFunction, this.packagingSystem?.id);
    super.disableFormIfRequired(this.packagingSystemForm);
    this.historyVersions = [{
      id: this.packagingSystem.id,
      versionNumber: this.packagingSystem.version,
      versionName: this.packagingSystem.versionName,
      creationTimestamp: this.packagingSystem.creationTimestamp,
      isCurrentVersion: this.packagingSystem.isCurrentVersion
    }];
  }

  loadHistoryVersions() {
    const versionsFunction = (packagingSystemId: number) => this.psApiService.getPackagingSystemHistoryVersions(packagingSystemId);
    super.loadHistoryVersions(this.packagingSystem.id, versionsFunction);
  }

  onHistoryVersionChange(version: number) {
    const navigationFunction = (packagingSystemId: number, packagingSystemVersion?: number) =>
      this.navigationService.navigateToPackagingSystem(packagingSystemId, packagingSystemVersion);
    super.onHistoryVersionChange(version, navigationFunction);
  }

  editVersionName(packagingSystem: VersionDto) {
    const renameFunction = (id: number, version: number, name: string) =>
      this.psApiService.renamePackagingSystem(id, version, name, true);
    super.editVersionName(packagingSystem, this.packagingSystem, renameFunction);
  }

  onNodeSelected(node: TreeLevelParent | null) {
    this.selectedSystemNode = null;
    this.selectedUnitNode = null;
    if (!node) { return; }
    if (node.type === PackagingPart.System) {
      this.selectedSystemNode = node;
    } else if (node.type === PackagingPart.Unit) {
      this.selectedUnitNode = node;
    }
    this.currentHierarchyFormGroup = this.getCurrentHierarchyLevelFormGroup();
  }

  private getCurrentHierarchyLevelFormGroup(): FormGroup {
    const hierarchyRoot = this.packagingSystemForm.controls.hierarchy as FormGroup;
    let returnNode: FormGroup = this.formBuilder.group({});
    if (this.selectedSystemNode) {
      const id = this.selectedSystemNode?.id;
      returnNode = this.findSystemControlRecursively(id, hierarchyRoot);
      this.quantityChangesSubscription = returnNode.controls.quantity.valueChanges.subscribe((newQuantity: number) => {
        const ps = this.findSystemRecursively(id, this.packagingSystem);
        if (!ps) { return; }
        ps.quantity = newQuantity;
        this.setDisplayedData();
      });
    } else if (this.selectedUnitNode) {
      const id = this.selectedUnitNode?.id;
      returnNode = this.findUnitControlRecursively(id, hierarchyRoot);
      this.quantityChangesSubscription = returnNode.controls.quantity.valueChanges.subscribe((newQuantity: number) => {
        const pu = this.findUnitRecursively(id, this.packagingSystem);
        if (!pu) { return; }
        pu.quantity = newQuantity;
        this.packagingSystem = { ...this.packagingSystem }; // called to update the tree view
        this.setDisplayedData();
      });
    }
    return returnNode;
  }

  private findSystemRecursively(packagingSystemId: number, packagingSystem: PackagingSystemDto): any {
    let returnPackagingSystem: PackagingSystemChildSystem | null = null;
    if (packagingSystemId === packagingSystem.id) { return packagingSystem; }
    const childSystems = packagingSystem.packagingSystems;
    for (const childSystem of childSystems) {
      if (packagingSystemId === childSystem.underlyingPackagingSystem.id) {
        returnPackagingSystem = childSystem;
      } else {
        returnPackagingSystem = this.findSystemRecursively(packagingSystemId, childSystem.underlyingPackagingSystem);
      }
      if (returnPackagingSystem != null) { return returnPackagingSystem; }
    }
  }

  private findUnitRecursively(packagingUnitId: number, packagingSystem: PackagingSystemDto): any {
    let returnPackagingUnit: PackagingSystemChildUnit | null = null;
    const unit = packagingSystem.packagingUnits.find(x => x.underlyingPackagingUnit.id === packagingUnitId);
    if (unit) { return unit; }
    const childSystems = packagingSystem.packagingSystems;
    for (const childSystem of childSystems) {
      const unit = packagingSystem.packagingUnits.find(x => x.underlyingPackagingUnit.id === packagingUnitId);
      if (unit) {
        returnPackagingUnit = unit;
      } else {
        returnPackagingUnit = this.findUnitRecursively(packagingUnitId, childSystem.underlyingPackagingSystem);
      }
      if (returnPackagingUnit != null) { return returnPackagingUnit; }
    }
  }

  private findSystemControlRecursively(nodeId: number, formGroup: FormGroup): FormGroup {
    let returnControl = null;
    if (nodeId === formGroup.value.id) { return formGroup; }
    const childSystems = formGroup.controls.childSystems as FormArray;
    for (const childSystem of childSystems.controls) {
      if (nodeId === childSystem.value.id) {
        returnControl = childSystem;
      } else {
        returnControl = this.findSystemControlRecursively(nodeId, childSystem as FormGroup);
      }
      if (returnControl != null) { return returnControl as FormGroup; }
    }
    return this.formBuilder.group({});
  }

  private findUnitControlRecursively(nodeId: number, formGroup: FormGroup): any {
    let returnControl = null;
    const unit = this.findChildUnitInFormById(nodeId, formGroup);
    if (unit) { return unit; }
    const childSystems = formGroup.controls.childSystems as FormArray;
    for (const childSystem of childSystems.controls) {
      const unit = this.findChildUnitInFormById(nodeId, childSystem as FormGroup);
      if (unit) {
        returnControl = unit;
      } else {
        returnControl = this.findUnitControlRecursively(nodeId, childSystem as FormGroup);
      }
      if (returnControl != null) { return returnControl; }
    }
  }

  private findChildUnitInFormById(nodeId: number, formGroup: FormGroup): FormGroup | null {
    const unit = (formGroup.controls.childUnits as FormArray).controls.find(x => x.value.id === nodeId) as FormGroup;
    return unit ?? null;
  }

  async importPackagingSystem() {
    this.spinner.show();
    const allPackagingSystems = await firstValueFrom(this.psApiService.getAllPackagingSystems());
    const dataSource = new MatTableDataSource<PackagingSystemInfoDto>();
    dataSource.data = this.packagingSystem.id != null ?
      allPackagingSystems.filter(x => x.id !== this.packagingSystem.id) : allPackagingSystems;
    const displayedColumns = ['id', 'brandName', 'productName', 'articleNumber', 'select'];

    const dialogConfig = getDialogConfig({ dataSource, displayedColumns }, '1100px');
    const dialogRef = this.dialog.open(SelectionDialogPackagingSystemsComponent, dialogConfig);
    this.spinner.hide();
    this.dialogSubscription = dialogRef.afterClosed().subscribe(async result => {
      if (result.event === DialogActions.REJECT) { return; }
      const observables: Observable<any>[] = result.selectedPackagingSystems.map((packagingSystem: PackagingSystemInfoDto) => {
        if (packagingSystem.id != null) { return this.psApiService.getPackagingSystem(packagingSystem.id); }
      });

      if (observables.length === 0) {
        this.setDisplayedData();
        return;
      }

      const completePackagingSystems = await firstValueFrom(forkJoin(observables));
      let lastIndex = this.packagingSystem.packagingSystems.length + this.packagingSystem.packagingUnits.length - 1;
      const childPackagingSystems: PackagingSystemChildSystem[] = completePackagingSystems.map(ps => ({
        index: ++lastIndex,
        quantity: 1,
        underlyingPackagingSystem: ps
      }));
      this.packagingSystem = {
        ...this.packagingSystem,
        packagingSystems: this.packagingSystem.packagingSystems.concat(childPackagingSystems)
      };
      this.setDisplayedData();
    });
  }

  async importPackagingUnit() {
    this.spinner.show();
    const allPackagingUnits = await firstValueFrom(this.puApiService.getAllPackagingUnits());

    const dataSource = new MatTableDataSource<PackagingUnitInfoDto>();
    dataSource.data = allPackagingUnits;
    const displayedColumns = ['id', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'select'];

    const dialogConfig = getDialogConfig({ dataSource, displayedColumns }, '1100px');
    const dialogRef = this.dialog.open(SelectionDialogPackagingUnitsComponent, dialogConfig);
    this.spinner.hide();
    this.dialogSubscription = dialogRef.afterClosed().subscribe(async result => {
      if (result.event === DialogActions.REJECT) { return; }
      const observables: Observable<any>[] = result.selectedPackagingUnits.map((packagingUnit: PackagingUnitInfoDto) => {
        if (packagingUnit.id != null) { return this.puApiService.getPackagingUnit(packagingUnit.id); }
      });

      if (observables.length === 0) {
        this.setDisplayedData();
        return;
      }

      const completePackagingUnits: PackagingUnitDto[] = await firstValueFrom(forkJoin(observables));
      let lastIndex = this.packagingSystem.packagingSystems.length + this.packagingSystem.packagingUnits.length - 1;
      const childPackagingUnits: PackagingSystemChildUnit[] = completePackagingUnits.map(pu => ({
        index: ++lastIndex,
        quantity: 1,
        underlyingPackagingUnit: pu
      }));
      this.packagingSystem = {
        ...this.packagingSystem,
        packagingUnits: this.packagingSystem.packagingUnits.concat(childPackagingUnits)
      };
      this.setDisplayedData();
    });
  }

  private setDisplayedData() {
    this.packagingSystemForm.controls.hierarchy =
      this.createFormRecursively(this.packagingSystem, this.packagingSystemForm.controls.hierarchy as FormGroup);
    this.packagingSystemForm.controls.hierarchy.updateValueAndValidity();
    this.packagingSystemChildrenUpdated = true;
  }

  setTags() {
    this.packagingSystem.associatedTagIdentifiers = this.selectedTags.map(tag => tag.id ?? -1);
  }

  isTopLevelNode(): boolean {
    if (this.selectedSystemNode) {
      return this.packagingSystem.packagingSystems.find(x => x.underlyingPackagingSystem.id === this.selectedSystemNode?.id) != null;
    } else if (this.selectedUnitNode) {
      return this.packagingSystem.packagingUnits.find(x => x.underlyingPackagingUnit.id === this.selectedUnitNode?.id) != null;
    }
    return false;
  }

  deleteNode(): void {
    if (!this.selectedSystemNode && !this.selectedUnitNode) { return; }
    let deletedIndex: number;
    if (this.selectedSystemNode) {
      this.packagingSystem = {
        ...this.packagingSystem,
        packagingSystems: this.packagingSystem.packagingSystems.filter(x =>
          x.underlyingPackagingSystem.id !== this.selectedSystemNode?.id)
      }
      deletedIndex = this.selectedSystemNode.index;
      this.selectedSystemNode = null;
    } else if (this.selectedUnitNode) {
      this.packagingSystem = {
        ...this.packagingSystem,
        packagingUnits: this.packagingSystem.packagingUnits.filter(x =>
          x.underlyingPackagingUnit.id !== this.selectedUnitNode?.id)
      }
      deletedIndex = this.selectedUnitNode.index;
      this.selectedUnitNode = null;
    }
    this.packagingSystem.packagingSystems.forEach(ps => {
      if (ps.index > deletedIndex) { ps.index = ps.index - 1; }
    });
    this.packagingSystem.packagingUnits.forEach(pu => {
      if (pu.index > deletedIndex) { pu.index = pu.index - 1; }
    });
    this.setDisplayedData();
  }

  async onSubmit() {
    this.formSubmitted = true;
    if (this.packagingSystemForm.invalid) {
      super.openFormInvalidDialog();
    } else {
      const dirId = this.currentDirectoryId != null ? this.currentDirectoryId : await super.selectDirectory();
      if (dirId == null) { return; }
      this.addNewPackagingSystem(dirId);
    }
  }

  get packagingSystemHirarchy() { return this.packagingSystemForm.controls.hierarchy as FormGroup; }

  private async addNewPackagingSystem(directoryId: number) {
    const createUpdatePackagingSystem = new PackagingSystemUpdateDto();
    const formFields = this.packagingSystemForm.value.packagingSystemFields;
    createUpdatePackagingSystem.id = this.packagingSystem.id;
    createUpdatePackagingSystem.directoryId = directoryId;
    createUpdatePackagingSystem.comment = formFields.comment;
    createUpdatePackagingSystem.brandName = formFields.brandName;
    createUpdatePackagingSystem.productName = formFields.productName;
    createUpdatePackagingSystem.articleNumber = formFields.articleNumber;
    createUpdatePackagingSystem.gtin = formFields.gtin;
    createUpdatePackagingSystem.distributionCountries = formFields.distributionCountries;
    createUpdatePackagingSystem.assemblyCountry = formFields.assemblyCountry;
    createUpdatePackagingSystem.customFields = formFields.customFields;

    createUpdatePackagingSystem.length = formFields.length;
    createUpdatePackagingSystem.width = formFields.width;
    createUpdatePackagingSystem.height = formFields.height;

    await super.updateImagesAndDocuments(this.packagingSystem);
    createUpdatePackagingSystem.images = this.packagingSystem.images;
    createUpdatePackagingSystem.documents = this.packagingSystem.documents;

    createUpdatePackagingSystem.versionName = this.packagingSystemForm.value?.newVersionName ?? null;
    createUpdatePackagingSystem.associatedTagIdentifiers = this.packagingSystem.associatedTagIdentifiers;

    const hierarchyChildSystems = this.packagingSystemHirarchy.controls.childSystems.value;
    createUpdatePackagingSystem.packagingSystems = this.getChildObjectsFromForm(hierarchyChildSystems);

    const hierarchyChildUnits = this.packagingSystemHirarchy.controls.childUnits.value;
    createUpdatePackagingSystem.packagingUnits = this.getChildObjectsFromForm(hierarchyChildUnits);

    let shouldCreateNewVersion = true;
    if (createUpdatePackagingSystem.id != null && (!this.packagingSystemForm.pristine || this.packagingSystemChildrenUpdated)) {
      shouldCreateNewVersion = await super.shouldCreateNewVersion(true, PackagingPart.System);
      if (shouldCreateNewVersion == null) { return; }
    }

    const saveFunction = (packagingItem: PackagingSystemDto, shouldCreateNewVersion: boolean) =>
      this.psApiService.putPackagingSystem(packagingItem, shouldCreateNewVersion);
    const navFunction = (id: number, version: number) => this.navigationService.navigateToPackagingSystem(id, version);
    super.putPackagingItem(createUpdatePackagingSystem, this.packagingSystemForm, saveFunction, navFunction, shouldCreateNewVersion);
  }

  private getChildObjectsFromForm(packagingItems: any[]): PackagingEntityInHierarchyDto[] {
    return packagingItems.map(packagingItem => ({
      id: packagingItem.id,
      version: packagingItem.version,
      index: packagingItem.index,
      quantity: packagingItem.quantity
    }));
  }
}
