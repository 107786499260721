import { MINIMAL_PERCENTAGE, MAXIMAL_PERCENTAGE, MINIMAL_MASS_PERCENTAGE } from './../../../services/material-services/material-data-handler';
import { Subscription, firstValueFrom } from 'rxjs';
import { MaterialDto } from './../../../data-transfer/entities/material-dto';
import { MaterialFunctionDto } from '../../../data-transfer/entities/material-function-dto';
import { MaterialDataHandler, MINIMAL_DENSITY, MINIMAL_GRAMMAGE, MINIMAL_THICKNESS, MINIMAL_WEIGHT, STANDARD_DENSITY } from '../../../services/material-services/material-data-handler';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MaterialManifestationDto } from 'src/app/data-transfer/entities/material-manifestation-dto';
import { ManufacturingTypeDto } from 'src/app/data-transfer/entities/manufacturing-type-dto';
import { ColorDto } from 'src/app/data-transfer/entities/color-dto';
import { MaterialApiService } from 'src/app/data-transfer/services/material-api-service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaterialRecyclatePercentage } from 'src/app/data-transfer/entities/material-recyclate-percentage';
import { DialogActions } from 'src/app/model/dictionary';
import { MultiMaterialLayerDto } from 'src/app/data-transfer/entities/material-entities/multi-material-layer-dto';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { CreateUpdateHandler } from 'src/app/services/packaging-services/create-update-handler';

@Component({
  selector: 'app-material-dialog',
  templateUrl: './material-dialog.component.html',
  styleUrls: ['./material-dialog.component.scss']
})
export class MaterialDialogComponent implements OnInit, OnDestroy {

  action: number;
  allMaterialFunctions: MaterialFunctionDto[];
  validMaterials: MaterialDto[] = [];
  validManifestations: MaterialManifestationDto[] = [];
  validManufacturingTypes: ManufacturingTypeDto[] = [];
  validColors: ColorDto[] = [];
  validCountries: CountryDto[] = [];
  callerId: number;
  canEditForm = true;
  showCalculator = false;
  isMassRequired = false;
  isWeightAllowed = true;
  preventEditing: boolean;
  totalWeight: number;
  calculatorTotalWeight: number;
  totalGrammage: number;
  defaultRecyclatePercentage: number | null = null;
  isRecyclateAllowed = true;
  dialogActions = DialogActions;

  addEditMaterialForm!: FormGroup;
  allLayersTableDataSource: MatTableDataSource<MultiMaterialLayerDto>;

  private materialLayer: MultiMaterialLayerDto;
  private recyclatePercentages: MaterialRecyclatePercentage[] = [];
  private packagingUnitTypeId: number;

  private materialsSubscription?: Subscription;
  private manifestationsSubscription?: Subscription;
  private manufTypesSubscription?: Subscription;
  private functionChangeSubscription?: Subscription;
  private materialsChangeSubscription?: Subscription;
  private manifestationsChangeSubscription?: Subscription;
  private materialOriginSubscription?: Subscription;

  get formControls() { return this.addEditMaterialForm.controls; }

  constructor(
    private dialogRef: MatDialogRef<MaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private materialApiService: MaterialApiService,
    private formBuilder: FormBuilder,
    private materialDataHandler: MaterialDataHandler,
    public handler: CreateUpdateHandler,
  ) {
    this.materialLayer = data.materialLayer;
    this.action = data.action;
    this.allMaterialFunctions = data.allMaterialFunctions;
    this.validMaterials = data.validMaterials;
    this.validManifestations = data.validManifestations;
    this.validManufacturingTypes = data.validManufacturingTypes;
    this.callerId = data.callerId;
    this.packagingUnitTypeId = data.packagingUnitTypeId;
    this.canEditForm = data.canEditForm;
    this.isMassRequired = data.isMassRequired;
    this.totalWeight = data.totalWeight;
    this.calculatorTotalWeight = data.calculatorTotalWeight ?? data.totalWeight;
    this.totalGrammage = data.totalGrammage;

    this.preventEditing = data.isTracked && data.action === DialogActions.EDIT;

    this.allLayersTableDataSource = new MatTableDataSource<MultiMaterialLayerDto>();
    this.allLayersTableDataSource.data = data.layersInfo;
  }

  ngOnInit(): void {
    if (this.materialLayer.materialManifestationId == null) { return; }
    this.addEditMaterialForm = this.formBuilder.group({
      functionId: [{ value: this.materialLayer.functionId, disabled: this.preventEditing }, [Validators.required]],
      materialId: [{ value: this.materialLayer.materialId, disabled: this.preventEditing }, [Validators.required]],
      materialManifestationId: [{ value: this.materialLayer.materialManifestationId, disabled: this.preventEditing },
      [Validators.required]],
      manufacturingTypeId: [{ value: this.materialLayer.manufacturingTypeId, disabled: this.preventEditing }],
      thickness: [{ value: this.materialLayer.thickness, disabled: this.preventEditing },
      [Validators.min(MINIMAL_THICKNESS)]],
      grammage: [{ value: this.materialLayer.grammage, disabled: this.preventEditing },
      [Validators.min(MINIMAL_GRAMMAGE)]],
      density: [this.materialLayer.density, [Validators.min(MINIMAL_DENSITY)]],
      massPercentage: [this.materialLayer.massPercentage,
      [Validators.min(MINIMAL_PERCENTAGE), Validators.max(MAXIMAL_PERCENTAGE)]],
      mass: [this.materialLayer.mass, Validators.min(MINIMAL_WEIGHT)],
      recyclatePercentage: this.getRecyclatePercentageControl(
        this.materialLayer.materialManifestationId, this.materialLayer.recyclingMaterialPercentage ?? 0),
      colorId: [this.materialLayer.colorId, [Validators.required, Validators.min(0), Validators.max(100)]],
      materialOriginCountryCode: [{ value: this.materialLayer.materialOriginCountryCode, disabled: this.preventEditing }],
      action: [this.action]
    });
    this.getNewRecyclatePercentages(this.materialLayer.materialOriginCountryCode).then(() => {
      this.disableFormIfRequired();
      this.getValidColorsForManifestation(this.materialLayer.materialManifestationId);
      this.editControlsValueAndEditability(this.materialLayer.functionId);
      this.editRecyclatePercentage(this.addEditMaterialForm.controls.materialManifestationId.value,
        this.addEditMaterialForm.controls.recyclatePercentage.value, false);
      if (this.isMassRequired) {
        this.addEditMaterialForm.controls.mass.addValidators(Validators.required);
      }
      this.functionChangeSubscription = this.addEditMaterialForm.controls.functionId.valueChanges.subscribe(newId => {
        this.getValidMaterials(newId);
        this.editControlsValueAndEditability(newId);
        this.resetFunctionDependentValues();
      });
      this.materialsChangeSubscription = this.addEditMaterialForm.controls.materialId.valueChanges.subscribe(newId => {
        this.getValidManifestations(newId);
        this.resetMaterialDependentValues();
      });
      this.materialOriginSubscription = this.addEditMaterialForm.controls.materialOriginCountryCode.valueChanges.subscribe(newCode => {
        this.getNewRecyclatePercentages(newCode).then(()=> {
          this.editRecyclatePercentage(this.addEditMaterialForm.controls.materialManifestationId.value,
            this.addEditMaterialForm.controls.recyclatePercentage.value, false)
          });
      });
      this.manifestationsChangeSubscription = this.addEditMaterialForm.controls.materialManifestationId.valueChanges.subscribe(newId => {
        this.editRecyclatePercentage(newId, this.addEditMaterialForm.controls.recyclatePercentage.value, false);
        this.getValidManufacturingTypes(newId);
        this.displayStandardDensity(newId);
        this.getValidColorsForManifestation(newId);
        this.resetManifestationDependentValues();
      });
      this.setRecyclatDefaultValue(this.addEditMaterialForm.controls.materialManifestationId.value);
    });
  }

  disableFormIfRequired() {
    if (!this.canEditForm) { this.addEditMaterialForm.disable(); }
  }

  toggleCalculator() {
    this.showCalculator = !this.showCalculator;
    this.dialogRef.updateSize(this.showCalculator ? '100%' : '1300px');
  }
  resetRecyclatDefaultValue() {
    if (this.isResetRecyclatDefaultValueAllowed()) {
      this.addEditMaterialForm.controls.recyclatePercentage.setValue(this.getResetValue());
    }
  }
   isResetRecyclatDefaultValueAllowed() : boolean {
    const resetValue : number|undefined =  this.getResetValue();
    if(resetValue && resetValue != this.addEditMaterialForm.controls.recyclatePercentage.value) {
      return true;
    }
    return false;
  }

  getResetValue () : number|undefined {
    const manifestationId : number = this.addEditMaterialForm.controls.materialManifestationId.value
    const defaultRecyclateEntry = this.recyclatePercentages.find(x => x.materialManifestationId === manifestationId);
    if(defaultRecyclateEntry) {
      let resetValue : number|undefined =  defaultRecyclateEntry.defaultRecyclatePercentage;
      if (defaultRecyclateEntry.assumedRecyclatePercentage) {
        resetValue = defaultRecyclateEntry.assumedRecyclatePercentage;
      }
      return resetValue;
    }
  }
  private editRecyclatePercentage(manifestationId: number | null, recyclatePercentage: number, shouldDefaultReplaceCurrentValue: boolean) {
    if (!this.canEditForm) { return; }
    if (manifestationId == null || manifestationId === -1 || !this.validManifestations) {
      this.defaultRecyclatePercentage = null;
      this.isRecyclateAllowed = true;
      this.addEditMaterialForm.controls.recyclatePercentage.enable();
      return;
    }
    this.isRecyclateAllowed = this.isRecyclateAllowedForManifestation(manifestationId);
    if (!this.isRecyclateAllowed) {
      this.addEditMaterialForm.controls.recyclatePercentage.disable();
    }
    const defaultRecyclateEntry = this.recyclatePercentages.find(x => x.materialManifestationId === manifestationId);
    if (defaultRecyclateEntry) {
      this.defaultRecyclatePercentage = defaultRecyclateEntry.assumedRecyclatePercentage;
    }
    let recyclateDisplayValue = 0;
    if (this.defaultRecyclatePercentage && shouldDefaultReplaceCurrentValue) {
      recyclateDisplayValue = this.defaultRecyclatePercentage;
    } else {
      if (this.isRecyclateAllowed) {
        recyclateDisplayValue = recyclatePercentage;
      }
    }
    this.addEditMaterialForm.controls.recyclatePercentage.setValue(recyclateDisplayValue);
    this.setRecyclatDefaultValue(manifestationId);
  }

  private getRecyclatePercentageControl(manifestationId: number, recyclatePercentage: number): FormControl {
    if (manifestationId == null) { throw new Error('ManifestationId ID not defined'); }
    this.isRecyclateAllowed = this.isRecyclateAllowedForManifestation(manifestationId);
    if (this.isRecyclateAllowed) {
      return this.formBuilder.control({
        value: recyclatePercentage,
        disabled: this.preventEditing
      },
        [Validators.required, Validators.min(0), Validators.max(100)]);
    } else {
      return this.formBuilder.control({ value: 0, disabled: true }, [Validators.min(0), Validators.max(100)]);
    }
  }

  private async getNewRecyclatePercentages(countryCode? : string) {
    this.recyclatePercentages = await firstValueFrom(this.materialApiService.getRecyclateRercentagesForCountry(countryCode));
  }

  private setRecyclatDefaultValue(manifestationId : number) {
    if(!this.materialLayer.recyclingMaterialPercentage) {
      const defaultRecyclateEntry = this.recyclatePercentages.find(x => x.materialManifestationId === manifestationId);
      if (defaultRecyclateEntry && defaultRecyclateEntry.defaultRecyclatePercentage && !defaultRecyclateEntry.assumedRecyclatePercentage) {
        this.addEditMaterialForm.controls.recyclatePercentage.setValue(defaultRecyclateEntry.defaultRecyclatePercentage);
      } else if ( defaultRecyclateEntry && defaultRecyclateEntry.assumedRecyclatePercentage) {
        this.addEditMaterialForm.controls.recyclatePercentage.setValue(defaultRecyclateEntry.assumedRecyclatePercentage);
      }

    }
  }

  private isRecyclateAllowedForManifestation(manifestationId: number): boolean {
    if (this.validManifestations && manifestationId !== -1) {
      return this.validManifestations.find(x => x.id === manifestationId)?.isRecyclateUsagePossible ?? true;
    }
    return true;
  }

  private getValidMaterials(functionId: number) {
    this.materialsSubscription = this.materialApiService.getValidMaterials(functionId, this.callerId, this.packagingUnitTypeId)
      .subscribe(result => {
        if (result && result.length > 0) {
          this.validMaterials = result;
        }
      });
  }

  private getValidColorsForManifestation(manifestationId: number | undefined) {
    if (this.validManifestations && manifestationId != null) {
      this.validColors = this.validManifestations.find(x => x.id === manifestationId)?.validColors ?? [];
    }
  }

  private getValidManifestations(materialId: number) {
    const functionId = this.addEditMaterialForm.controls.functionId.value;
    if (functionId == null || materialId == null) { return; }
    this.manifestationsSubscription = this.materialApiService.getValidManifestations(
      materialId, functionId, this.callerId, this.packagingUnitTypeId).subscribe(
        manifestations => this.validManifestations = manifestations);
  }

  private getValidManufacturingTypes(manifestationId: number) {
    const functionId = this.addEditMaterialForm.controls.functionId.value;
    if (functionId == null || manifestationId == null) { return; }
    this.manufTypesSubscription = this.materialApiService.getValidManufacturingTypes(
      manifestationId, functionId, this.callerId, this.packagingUnitTypeId)
      .subscribe(result => { this.validManufacturingTypes = result; });
  }

  private resetFunctionDependentValues() {
    this.addEditMaterialForm.patchValue({
      materialId: null,
      materialManifestationId: null,
      manufacturingTypeId: null,
      colorId: null,
      density: null
    });
  }

  private resetMaterialDependentValues() {
    this.addEditMaterialForm.patchValue({
      materialManifestationId: null,
      manufacturingTypeId: null,
      colorId: null,
      density: null
    });
  }

  private resetManifestationDependentValues() {
    this.addEditMaterialForm.patchValue({
      manufacturingTypeId: null,
      colorId: null
    });
  }

  doAction() {
    if (this.addEditMaterialForm.controls.functionId.value === -1) {
      this.addEditMaterialForm.patchValue({ functionId: undefined });
    }
    if (this.addEditMaterialForm.controls.materialId.value === -1) {
      this.addEditMaterialForm.patchValue({ materialId: undefined });
    }

    this.addEditMaterialForm.markAllAsTouched();

    if (this.addEditMaterialForm.invalid) { return; }
    this.dialogRef.close({
      event: this.action, data: { layer: this.addEditMaterialForm.getRawValue(), totalWeight: this.calculatorTotalWeight }
    });
  }

  layerMassCalculated(mass: number) {
    this.addEditMaterialForm.controls.mass.patchValue(mass);
  }

  calculatorTotalMassChanged(calcTotalWeight: number) {
    this.calculatorTotalWeight = calcTotalWeight;
  }

  calculatorLayerGrammageChanged(layerGrammage: number) {
    this.addEditMaterialForm.controls.grammage.patchValue(layerGrammage);
  }

  calculatorLayerThicknessChanged(layerThickness: number) {
    this.addEditMaterialForm.controls.thickness.patchValue(layerThickness);
  }

  calculatorLayerDensityChange(layerDensity: number) {
    this.addEditMaterialForm.controls.density.patchValue(layerDensity);
  }

  calculatorLayerPercentageChange(layerPercentage: number) {
    this.addEditMaterialForm.controls.massPercentage.patchValue(layerPercentage);
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  onReset() {
    this.addEditMaterialForm.reset();
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.addEditMaterialForm.disable() : this.addEditMaterialForm.enable();
  }

  private editControlsValueAndEditability(functionId: number | undefined) {
    if (!this.canEditForm) { return; }
    if (this.allMaterialFunctions && functionId != null) {
      this.isWeightAllowed = this.allMaterialFunctions.find(x => x.id === functionId)?.allowWeightInput ?? false;
      this.materialDataHandler.setControlsValueAndEditability(
        this.addEditMaterialForm, 'mass', this.isWeightAllowed, this.addEditMaterialForm.controls.mass.value, MINIMAL_WEIGHT);
      this.materialDataHandler.setControlsValueAndEditability(
        this.addEditMaterialForm, 'thickness', this.isWeightAllowed, this.addEditMaterialForm.controls.thickness.value, MINIMAL_THICKNESS);
      this.materialDataHandler.setControlsValueAndEditability(
        this.addEditMaterialForm, 'density', this.isWeightAllowed, this.addEditMaterialForm.controls.density.value, MINIMAL_DENSITY);
      this.materialDataHandler.setControlsValueAndEditability(
        this.addEditMaterialForm, 'grammage', this.isWeightAllowed, this.addEditMaterialForm.controls.grammage.value, MINIMAL_GRAMMAGE);
      this.materialDataHandler.setControlsValueAndEditability(
        this.addEditMaterialForm, 'massPercentage', this.isWeightAllowed,
        this.addEditMaterialForm.controls.massPercentage.value, MINIMAL_MASS_PERCENTAGE);
    }
    this.showCalculator = false;
  }

  private displayStandardDensity(manifestationId: number) {
    if (this.isWeightAllowed) {
      const densityFromManifestation = this.validManifestations.find(x => x.id === manifestationId)?.density;
      const density = densityFromManifestation ?? STANDARD_DENSITY;
      this.addEditMaterialForm.patchValue({ density });
    }
  }

  ngOnDestroy(): void {
    this.materialsSubscription?.unsubscribe();
    this.manifestationsSubscription?.unsubscribe();
    this.manufTypesSubscription?.unsubscribe();
    this.functionChangeSubscription?.unsubscribe();
    this.materialsChangeSubscription?.unsubscribe();
    this.manifestationsChangeSubscription?.unsubscribe();
    this.materialOriginSubscription?.unsubscribe();
  }
}
