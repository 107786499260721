import { MatDialog } from '@angular/material/dialog';
import { MaterialExportInfoDto } from './../../../../data-transfer/entities/material-export-info-dto';
import { ComponentExportInfoDto } from './../../../../data-transfer/entities/component-export-info-dto';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { ExportInfoDto } from 'src/app/data-transfer/entities/export-info-dto';
import { UnitExportInfoDto } from 'src/app/data-transfer/entities/unit-export-info-dto';
import { SystemExportInfoDto } from 'src/app/data-transfer/entities/system-export-info-dto';
import { ActivatedRoute } from '@angular/router';
import { ExportListCollection } from 'src/app/data-transfer/resolvers/export-list-resolver';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { Observable, forkJoin } from 'rxjs';
import { ImportExportApiService } from 'src/app/data-transfer/services/import-export-api-service';
import { map } from 'rxjs/operators';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { DialogActions } from 'src/app/model/dictionary';
import { TranslateService } from '@ngx-translate/core';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';

@Component({
  selector: 'app-export-list',
  templateUrl: './export-list.component.html',
  styleUrls: ['./export-list.component.scss'],
})
export class ExportListComponent implements OnInit {
  packagingPartEnum = PackagingPart;

  // data for packaging systems
  systemDisplayedColumns: string[] = [
    'packagingId',
    'productName',
    'version',
    'status',
    'tracking',
    'exportTimestamp',
    'exportProfile',
    'action',
  ];
  systemOrganizationIds: number[] = [];
  exportedSystemsByOrganization = new Map<number, MatTableDataSource<SystemExportInfoDto>>();

  // data for packaging units
  unitDisplayedColumns: string[] = [
    'packagingId',
    'productName',
    'version',
    'status',
    'tracking',
    'exportTimestamp',
    'exportProfile',
    'action',
  ];
  unitOrganizationIds: number[] = [];
  exportedUnitsByOrganization = new Map<number, MatTableDataSource<UnitExportInfoDto>>();

  // data for packaging components
  componentDisplayedColumns: string[] = [
    'packagingId',
    'articleName',
    'version',
    'status',
    'tracking',
    'exportTimestamp',
    'exportProfile',
    'action',
  ];
  componentOrganizationIds: number[] = [];
  exportedComponentsByOrganization = new Map<number, MatTableDataSource<ComponentExportInfoDto>>();

  // data for packaging materials
  materialDisplayedColumns: string[] = [
    'packagingId',
    'articleName',
    'version',
    'status',
    'tracking',
    'exportTimestamp',
    'exportProfile',
    'action',
  ];
  materialOrganizationIds: number[] = [];
  exportedMaterialsByOrganization = new Map<number, MatTableDataSource<MaterialExportInfoDto>>();

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private importExportApiService: ImportExportApiService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      const exportListCollection: ExportListCollection = data.exportLists;

      this.setSystemData(exportListCollection.systemExportList);
      this.setUnitData(exportListCollection.unitExportList);
      this.setComponentData(exportListCollection.componentExportList);
      this.setMaterialData(exportListCollection.materialExportList);
    });
  }

  changeTracking(exportInfo: ExportInfoDto<any, any>, packagingPart: PackagingPart) {
    const dialogConfig = getDialogConfig(this.getChangeTrackingConfirmationDialogData());
    const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult.event === DialogActions.REJECT) {
        return;
      }

      this.importExportApiService.setTrackable(exportInfo.importCandidateId, !exportInfo.tracking, packagingPart).subscribe(() => {
        this.reloadExportList();
      });
    });
  }

  deleteImport(exportInfo: ExportInfoDto<any, any>, packagingPart: PackagingPart) {
    const dialogConfig = getDialogConfig(this.getDeleteConfirmationDialogData());
    const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult.event === DialogActions.REJECT) {
        return;
      }

      this.importExportApiService.deleteImportCandidate(exportInfo.importCandidateId, packagingPart).subscribe(() => {
        this.reloadExportList();
      });
    });
  }

  reloadExportList() {
    const exportListsObservable: Observable<any>[] = [];
    exportListsObservable.push(this.importExportApiService.getExportList(PackagingPart.System));
    exportListsObservable.push(this.importExportApiService.getExportList(PackagingPart.Unit));
    exportListsObservable.push(this.importExportApiService.getExportList(PackagingPart.Component));
    exportListsObservable.push(this.importExportApiService.getExportList(PackagingPart.Material));
    const exportLists = forkJoin(exportListsObservable).pipe(
      map(exportListsArray => {
        const exportListCollection = new ExportListCollection();
        exportListCollection.systemExportList = exportListsArray[0];
        exportListCollection.unitExportList = exportListsArray[1];
        exportListCollection.componentExportList = exportListsArray[2];
        exportListCollection.materialExportList = exportListsArray[3];
        return exportListCollection;
      })
    );

    exportLists.subscribe(exportListCollection => {
      this.setSystemData(exportListCollection.systemExportList);
      this.setUnitData(exportListCollection.unitExportList);
      this.setComponentData(exportListCollection.componentExportList);
      this.setMaterialData(exportListCollection.materialExportList);
    });
  }

  private getDeleteConfirmationDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('dataManagement.exportList.dialog.delete')],
      icon: 'info',
    };
  }

  private getChangeTrackingConfirmationDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('dataManagement.exportList.dialog.changeTracking')],
      icon: 'info',
    };
  }

  private setSystemData(data: SystemExportInfoDto[]) {
    this.setExportedByOrganization<SystemExportInfoDto>(data, this.exportedSystemsByOrganization);
    this.systemOrganizationIds = [];
    this.systemOrganizationIds = [...new Set(data.map(_ => _.exportedToOrganization.id))].sort();
  }

  private setUnitData(data: UnitExportInfoDto[]) {
    this.setExportedByOrganization<UnitExportInfoDto>(data, this.exportedUnitsByOrganization);
    this.unitOrganizationIds = [];
    this.unitOrganizationIds = [...new Set(data.map(_ => _.exportedToOrganization.id))].sort();
  }

  private setComponentData(data: ComponentExportInfoDto[]) {
    this.setExportedByOrganization<ComponentExportInfoDto>(data, this.exportedComponentsByOrganization);
    this.componentOrganizationIds = [];
    this.componentOrganizationIds = [...new Set(data.map(_ => _.exportedToOrganization.id))].sort();
  }

  private setMaterialData(data: MaterialExportInfoDto[]) {
    this.setExportedByOrganization<MaterialExportInfoDto>(data, this.exportedMaterialsByOrganization);
    this.materialOrganizationIds = [];
    this.materialOrganizationIds = [...new Set(data.map(_ => _.exportedToOrganization.id))].sort();
  }

  private setExportedByOrganization<T extends ExportInfoDto<any, any>>(data: T[], exportedDataSourceByOrganization: Map<number, MatTableDataSource<T>>) {
    const exportedByOrganization = new Map<number, T[]>();
    for (const exportInfo of data) {
      const organizationId = exportInfo.exportedToOrganization.id;
      if (exportedByOrganization.has(organizationId)) {
        exportedByOrganization.get(organizationId)?.push(exportInfo);
      } else {
        exportedByOrganization.set(organizationId, [exportInfo]);
      }
    }

    for (const [organizationId, exportInfos] of exportedByOrganization) {
      if (exportedDataSourceByOrganization.has(organizationId)) {
        const dataSource = exportedDataSourceByOrganization.get(organizationId);
        if (dataSource) {
          dataSource.data = exportInfos;
        }
      } else {
        exportedDataSourceByOrganization.set(organizationId, new MatTableDataSource<T>(exportInfos));
      }
    }
  }
}
