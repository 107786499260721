<h4 mat-dialog-title *ngIf="action === 0">{{ 'dataManagement.tags.tagsManagement.dialog.newTag' | translate }}</h4>
<h4 mat-dialog-title *ngIf="action === 1">{{ 'dataManagement.tags.tagsManagement.dialog.editTag' | translate }}</h4>

<div mat-dialog-content>
  <form [formGroup]="form">
    <div>
      {{ 'dataManagement.tags.tagsManagement.dialog.selectColor' | translate }}
      <button class="color-button" mat-raised-button (click)="isPickerOpen = !isPickerOpen" type="button"
      cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        {{ (isPickerOpen ? 'common.buttons.close' : 'common.buttons.open') | translate }}
      </button>
      {{ 'dataManagement.tags.tagsManagement.dialog.defineCustomColor' | translate }}
      <mat-form-field class="palette-form-field"><input matInput type="color" formControlName="color"
          class="palette-input"></mat-form-field>
    </div>
    <!-- Preview: -->
    <div class="preview-container">
      <mat-card class="single-tag-container">
        <mat-card-content>
          <mat-form-field appearance="outline" class="tag-form-field">
            <mat-label>{{ 'dataManagement.tags.tagsManagement.dialog.tagName' | translate }}</mat-label>
            <mat-chip [ngStyle]="{'background-color': form.controls.color.value}">
              <div class="single-tag">
                <input autocomplete="off" matInput formControlName="name" (keydown.backspace)="$event.stopImmediatePropagation()">
              </div>
            </mat-chip>
            <mat-error *ngIf="form.controls.name.errors && form.controls.name.hasError('required')">
              {{ 'dataManagement.tags.tagsManagement.dialog.nameRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="form.controls.name.errors && form.controls.name.hasError('maxlength')">
              {{ 'dataManagement.tags.tagsManagement.dialog.tooLong' | translate }}
            </mat-error>
            <mat-error *ngIf="form.controls.name.errors && form.controls.name.errors?.['forbiddenName']">
              {{ 'dataManagement.tags.tagsManagement.dialog.nameAlreadyExists' | translate }}
            </mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card class="description-container">
        <mat-card-content>
          <mat-form-field appearance="outline" class="tag-form">
            <mat-label>{{ 'dataManagement.tags.tagsManagement.dialog.tagDescription' | translate }}</mat-label>
            <input matInput formControlName="description">
            <mat-error *ngIf="form.controls.description.errors && form.controls.description.hasError('maxlength')">
              {{ 'dataManagement.tags.tagsManagement.dialog.tooLong' | translate }}
            </mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button *ngIf="action === 0" (click)="doAction()" mat-raised-button type="submit" color="primary">
    {{ 'dataManagement.tags.tagsManagement.dialog.newTag' | translate }}</button>
  <button *ngIf="action === 1" (click)="doAction()" mat-raised-button type="submit" color="primary">
    {{ 'common.buttons.save' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isPickerOpen"
  [cdkConnectedOverlayHasBackdrop]="true" cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="isPickerOpen = false">
  <mat-action-list class="example-list colors-list">
    <button mat-list-item *ngFor="let color of allDefaultColors" (click)="selectDefaultColor(color)"
      [ngStyle]="{'background-color': color, 'width': '50px', 'height': '50px', 'margin': '2px'}">
    </button>
  </mat-action-list>
</ng-template>
