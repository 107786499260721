import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParentApiService } from './parent-api-service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PackagingSystemDto } from '../entities/packaging-system-entities/packaging-system-dto';
import { PermissionTypeDto } from '../entities/permission-type-dto';
import { DeletedEntitiesDto } from '../entities/deleted-entities-dto';
import { VersionDto } from '../entities/version-dto';
import { PackagingSystemInfoDto } from '../entities/packaging-system-entities/packaging-system-info-dto';
import { catchError } from 'rxjs/operators';
import { CustomFieldProfileDto } from '../entities/custom-field-profile-dto';

@Injectable({ providedIn: 'root' })
export class PackagingSystemApiService extends ParentApiService {

  constructor(private http: HttpClient) { super(); }

  public getPackagingSystem(packagingSystemId: number, versionId?: number): Observable<PackagingSystemDto> {
    let url = `${environment.webApiAddress}/api/packaging-systems/by-id?id=${packagingSystemId}`;
    if (versionId != null) {
      url = url.concat(`&version=${versionId}`);
    }
    return this.http.get<PackagingSystemDto>(url);
  }

  public getPackagingSystemHistoryVersions(packagingSystemId: number) {
    const url = `${environment.webApiAddress}/api/packaging-systems/history?id=${packagingSystemId}`;
    return this.http.get<VersionDto[]>(url);
  }

  public putPackagingSystem(
    packagingSystem: PackagingSystemDto, shouldCreateNewVersion = true, stayOnPageIfError?: boolean
  ): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems?overwriteExistingVersion=${!shouldCreateNewVersion}`;
    console.log(JSON.stringify(packagingSystem));
    return this.http.put<any>(url, packagingSystem,
      { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public getAllPackagingSystems(): Observable<PackagingSystemInfoDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-systems`;
    return this.http.get<PackagingSystemInfoDto[]>(url);
  }

  public getFilteredPackagingSystems(startTimestamp?: string, endTimestamp?: string): Observable<PackagingSystemInfoDto[]> {
    let params: HttpParams = new HttpParams();
    if (startTimestamp) {
      params = params.set('startTimestamp', startTimestamp);
    }
    if (endTimestamp) {
      params = params.set('endTimestamp', endTimestamp);
    }
    const url = `${environment.webApiAddress}/api/packaging-systems`;
    return this.http.get<PackagingSystemInfoDto[]>(url, { params });
  }

  public getPackagingSystemPermissions(packagingSystemId: number): Observable<PermissionTypeDto> {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingSystemId', packagingSystemId);
    const url = `${environment.webApiAddress}/api/permissions/effective-packaging-system-permissions`;
    return this.http.get<PermissionTypeDto>(url, { params });
  }

  public deletePackagingSystems(
    idsToDelete: DeletedEntitiesDto, deletePermanently: boolean, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems?deletePermanently=${deletePermanently}`;
    return this.http.request('delete', url, {
      body: idsToDelete,
      headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader
    });
  }

  public lockPackagingSystem(packagingSystemId: number, stayOnPageIfError: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems/${packagingSystemId}/lock`;
    return this.http.get<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public releasePackagingSystem(packagingSystemId: number, stayOnPageIfError: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems/${packagingSystemId}/release`;
    return this.http.get<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader })
      .pipe(catchError(this.handleError));
  }

  public renamePackagingSystem(id: number, version: number, name: string, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems/rename-version`;
    let params = new HttpParams().set('id', id);
    params = params.set('version', version);
    params = params.set('name', name);
    return this.http.put<any>(url, {}, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getLastViewedPackagingSystems(count: number): Observable<PackagingSystemDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/latest?count=${count}`;
    return this.http.get<PackagingSystemDto[]>(url);
  }

  public setPackagingSystemTags(id: number, tags: number[], stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems/set-tags`;
    const params = new HttpParams().set('packagingSystemId', id);
    return this.http.put<any>(url, tags, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public addPackagingSystemTags(id: number, tags: number[], stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems/add-tags`;
    const params = new HttpParams().set('packagingSystemId', id);
    return this.http.put<any>(url, tags, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getPackagingSystemPreview(candidateId: number): Observable<PackagingSystemDto> {
    const url = `${environment.webApiAddress}/api/packaging-systems/by-import-candidate?importCandidateId=${candidateId}`;
    return this.http.get<PackagingSystemDto>(url);
  }

  public getPackagingSystemQuantity(
    packagingSystemId: number, year: number, month: number, countryCode: string,
    version?: number, stayOnPageIfError?: boolean
  ): Observable<number> {
    let params: HttpParams = new HttpParams();
    params = params.set('id', packagingSystemId);
    params = params.set('year', year);
    params = params.set('month', month);
    params = params.set('country', countryCode);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/packaging-systems/quantity`;
    return this.http.get<number>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public setPackagingSystemQuantity(
    packagingSystemId: number, year: number, month: number, quantity: number,
    countryCode: string, version?: number, stayOnPageIfError?: boolean
  ) {
    let params: HttpParams = new HttpParams();
    params = params.set('id', packagingSystemId);
    params = params.set('year', year);
    params = params.set('month', month);
    params = params.set('quantity', quantity);
    params = params.set('country', countryCode);
    if (version != null) {
      params = params.set('version', version);
    }
    const url = `${environment.webApiAddress}/api/packaging-systems/quantity`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public addDistributionCountry(packagingSystemId: number, version: number, countryCode: string, stayOnPageIfError?: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('id', packagingSystemId);
    params = params.set('version', version);
    params = params.set('country', countryCode);
    const url = `${environment.webApiAddress}/api/packaging-systems/add-distribution-country`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getChangedSystemIdsInPackagingSystem(packagingSystemId: number): Observable<number[]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/packaging-system-changes?packagingSystemId=${packagingSystemId}`;
    return this.http.get<number[]>(url);
  }

  public getChangedUnitIdsInPackagingSystem(packagingSystemId: number): Observable<number[]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/packaging-unit-changes?packagingSystemId=${packagingSystemId}`;
    return this.http.get<number[]>(url);
  }

  public acceptPackagingSystemChange(
    packagingSystemId: number, changedSystemId: number, shouldCreateNewVersion = true, stayOnPageIfError?: boolean
  ) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingSystemId', packagingSystemId);
    params = params.set('childPackagingSystemId', changedSystemId);
    params = params.set('overwriteExistingVersion', !shouldCreateNewVersion);
    const url = `${environment.webApiAddress}/api/packaging-systems/adopt-packaging-system-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public declinePackagingSystemChange(packagingSystemId: number, changedSystemId: number, stayOnPageIfError?: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingSystemId', packagingSystemId);
    params = params.set('childPackagingSystemId', changedSystemId);
    const url = `${environment.webApiAddress}/api/packaging-systems/ignore-packaging-system-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public acceptPackagingUnitChange(
    packagingSystemId: number, changedUnitId: number, shouldCreateNewVersion = true, stayOnPageIfError?: boolean
  ) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingSystemId', packagingSystemId);
    params = params.set('packagingUnitId', changedUnitId);
    params = params.set('overwriteExistingVersion', !shouldCreateNewVersion);
    const url = `${environment.webApiAddress}/api/packaging-systems/adopt-packaging-unit-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public declinePackagingUnitChange(packagingSystemId: number, changedUnitId: number, stayOnPageIfError?: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingSystemId', packagingSystemId);
    params = params.set('packagingUnitId', changedUnitId);
    const url = `${environment.webApiAddress}/api/packaging-systems/ignore-packaging-unit-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getCustomFieldProfiles(): Observable<CustomFieldProfileDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/custom-field-profiles?`;
    return this.http.get<CustomFieldProfileDto[]>(url);
  }
}
