import { SimpleAlertDialogComponent } from '../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { Subscription } from 'rxjs';
import { FileApiService } from '../../../../data-transfer/services/file-api-service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy } from '@angular/core';
import { SimpleDialogData } from '../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-lca-data-excel-import',
  templateUrl: './lca-data-excel-import.component.html',
  styleUrls: ['./lca-data-excel-import.component.scss']
})
export class LcaDataExcelImportComponent extends FileUploadComponent implements OnDestroy {

  excelFile: File | null = null;

  private excelUploadSubscription?: Subscription;

  constructor(
    protected dialog: MatDialog,
    private translateService: TranslateService,
    private fileApiService: FileApiService
  ) {
    super(dialog);
  }

  uploadExcelFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const excelFile = target.files[0];
      if (!excelFile) { return; }
      const fileType = excelFile.type;
      if (fileType.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') === null) {
        const dialogData = this.getInvalidFormatDialogData(excelFile.name);
        this.showDialog(dialogData);
        return;
      }
      this.excelFile = excelFile;
    }
  }

  importExcelFile(): void {
    if (!this.excelFile) { return; }
    const formData: FormData = super.getNewFilesFormData([this.excelFile], 'updatedCoreDataFile');
    this.excelUploadSubscription = this.fileApiService.saveLcaExcelFile(formData, true).subscribe({
      next: _ => {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getImportSuccessDialogData()));
      },
      error: _ => {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getImportErrorDialogData()));
      },
    });
  }

  private getInvalidFormatDialogData(fileName: string): SimpleDialogData {
    return {
      title: fileName,
      messages: [this.translateService.instant('errors.formatNotSupported')],
      icon: 'error'
    };
  }

  private getImportSuccessDialogData(): SimpleDialogData {
    return {
      title: '',
      messages: [this.translateService.instant('errors.formatNotSupported')],
      icon: 'error'
    };
  }

  private getImportErrorDialogData(): SimpleDialogData {
    return {
      title: '',
      messages: [this.translateService.instant('errors.formatNotSupported')],
      icon: 'error'
    };
  }

  ngOnDestroy(): void {
    this.excelUploadSubscription?.unsubscribe();
  }
}
