import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { Component, Inject, OnInit } from '@angular/core';
import { ADD_WITHOUT_TRACKING, ADD_WITH_TRACKING } from '../selection-dialog-components/selection-dialog-components.component';
import { MaterialApiService } from 'src/app/data-transfer/services/material-api-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogActions } from 'src/app/model/dictionary';
import { MultiMaterialLayerDto } from 'src/app/data-transfer/entities/material-entities/multi-material-layer-dto';
import { firstValueFrom } from 'rxjs';

export interface FlatMaterialLayersList {
  materialId: number;
  allMaterialLayers: MultiMaterialLayerDto[];
}

export interface MultiMaterialCompositeImportWrapper extends MultiMaterialCompositeDto {
  isValidForComponent: boolean;
}

@Component({
  selector: 'app-selection-dialog-materials',
  templateUrl: './selection-dialog-materials.component.html',
  styleUrls: ['./selection-dialog-materials.component.scss']
})
export class SelectionDialogMaterialsComponent implements OnInit {

  dataSource: MatTableDataSource<MultiMaterialCompositeImportWrapper>;
  displayedColumns!: string[];
  selectedMaterials: MultiMaterialCompositeDto[];
  callerId!: number;
  packagingUnitTypeId!: number;
  flatMaterialsList: FlatMaterialLayersList[] = [];

  constructor(
    protected dialogRef: MatDialogRef<SelectionDialogMaterialsComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private materialApiService: MaterialApiService
  ) {
    this.dataSource = new MatTableDataSource<MultiMaterialCompositeImportWrapper>();
    this.selectedMaterials = [];
    if (data) {
      this.dataSource = data.dataSource;
      this.dataSource.data.forEach(x => x.isValidForComponent = true);
      this.displayedColumns = data.displayedColumns;
      this.callerId = data.callerId;
      this.packagingUnitTypeId = data.packagingUnitTypeId;
      this.flatMaterialsList = data.flatMaterialsList;
    }
  }

  ngOnInit() {
    this.setValidityForComponent();
  }

  private async setValidityForComponent() {
    for (const material of this.dataSource.data) {
      const flatMaterialEntry = this.flatMaterialsList.find(x => x.materialId === material.id);
      if (!flatMaterialEntry) { continue; }
      for (const materialLayer of flatMaterialEntry.allMaterialLayers) {
        if (materialLayer.functionId == null || materialLayer.materialId == null) { continue; }
        const validMaterials = await firstValueFrom(this.materialApiService.getValidMaterials(
          materialLayer.functionId, this.callerId, this.packagingUnitTypeId));
        if (!validMaterials.map(x => x.id).includes(materialLayer.materialId)) {
          material.isValidForComponent = false;
        }
      }
    }
  }

  materialsSelected(e: MultiMaterialCompositeDto[]) {
    this.selectedMaterials = e;
  }

  importMaterials(withTracking: boolean) {
    let event: string;
    if (withTracking) {
      event = ADD_WITH_TRACKING;
      this.selectedMaterials.forEach(x => {
        x.underlyingMultiMaterialId = x.id;
        x.hasInternalTracking = true;
      });
    } else {
      event = ADD_WITHOUT_TRACKING;
      this.selectedMaterials.forEach(x => x.underlyingMultiMaterialId = null);
    }
    this.dialogRef.close({ event, data: this.selectedMaterials });
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
