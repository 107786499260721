<app-packaging-navigation [itemId]="packagingPartId" [callerLevelId]="callerLevelId"
  [callerTypeId]="packagingUnitTypeId" [currentDirectoryId]="currentDirectoryId" [brandName]="brandName"
  [currentDirectoryId]="currentDirectoryId" [productName]="productName" [articleNumber]="articleNumber"
  [versionId]="packagingPartVersion" [isCurrentVersion]="isCurrentVersion"
  [hasRecyclabilityResult]="hasRecyclabilityResult" [hasLifeCycleResult]="hasLifeCycleResult"
  [creationTimestamp]="creationTimestamp">

  <div class="outer-container">
    <h1 class="mat-headline-3">{{ 'analysis.lifecycleAnalysis.title' | translate }}</h1>

    <div id="versions-container" *ngIf="!isUserValidator">
      <mat-form-field class="form-field-versions">
        <mat-label>{{ 'home.packagingUnitHome.historyVersion' | translate }}</mat-label>
        <mat-select [(value)]="selectedAnalysisVersion" (selectionChange)="onHistoryVersionChange($event.value)">
          <mat-option *ngFor="let analysisVersion of analysisVersions" [value]="analysisVersion">
            {{ 'analysis.calculatedOn' | translate }}
            {{ getDateTimeString(analysisVersion[0].evaluationReferenceTimestamp) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span id="analysis-id">
        {{ 'analysis.analysisId' | translate }}: <b>{{analysisId}}</b>
        <button id="copy-button" mat-icon-button color="primary" [cdkCopyToClipboard]="analysisId">
          <mat-icon>content_copy</mat-icon>
        </button>
      </span>
    </div>

    <app-normalization #normForm [volumeAvailable]="actualVolume != null" [weightAvailable]="actualWeight != null"
      [actualVolume]="actualVolume" [actualWeight]="actualWeight" (normalizeClick)="doNormalize($event)">
    </app-normalization>

    <mat-tab-group>
      <mat-tab *ngFor="let element of simplifiedLcaDataSource; let countryIndex = index">
        <ng-template mat-tab-label>
          <mat-icon *ngIf="!isCountryAuthorized(element)">lock</mat-icon>
          <span>{{element.countryName}}</span>
        </ng-template>
        <ng-template matTabContent>
          <div *ngIf="isUserValidator">
            <br>
            <alert *ngIf="element.validationState === validationStates.Unknown" type="info">
              {{ 'packagingUnit.messages.notValidated' | translate }}
            </alert>
            <alert *ngIf="element.validationState === validationStates.Positive" type="success">
              {{ 'packagingUnit.messages.validationPassed' | translate }}
            </alert>
            <alert *ngIf="element.validationState === validationStates.Negative" type="danger">
              {{ 'packagingUnit.messages.validationFailed' | translate }}
            </alert>
          </div>
          <div class="buybox" *ngIf="!isCountryAuthorized(element)">
            <app-buying-from-shop  [buyType]="buyType.Country"
              [contentValue]="element.countryName">
            </app-buying-from-shop>
          </div>
          <div *ngIf="isCountryAuthorized(element)">
            <div *ngIf="callerLevelId === packagingPartEnum.System && packagingSystem != null">
              <app-analysis-life-cycle-packaging-system [selectedAnalysisVersion]="getSelectedAnalysisVersionSystem(countryIndex)"
                [packagingSystem]="packagingSystem" [normalizationData]="normalizationData" [isDarkTheme]="isDarkTheme"
                [analyseTime]="this.selectedAnalysisVersion[0].evaluationReferenceTimestamp"
                [allowedEffects]="this.allowedEffects"
                (normalizeLcaResult)="normalizeProvidedLcaData($event)"
                (normalizationDataReset)="resetNormalizationData($event)">
              </app-analysis-life-cycle-packaging-system>
            </div>

            <div *ngIf="callerLevelId === packagingPartEnum.Unit && packagingUnit != null">
              <app-analysis-life-cycle-packaging-unit [selectedAnalysisVersion]="getSelectedAnalysisVersionUnit(countryIndex)"
                [packagingUnit]="packagingUnit" [normalizationData]="normalizationData" [actualWeight]="actualWeight"
                [analyseTime]="this.selectedAnalysisVersion[0].evaluationReferenceTimestamp"
                [isDarkTheme]="isDarkTheme" (normalizeLcaResult)="normalizeProvidedLcaData($event)"
                [allowedEffects]="this.allowedEffects"
                (normalizationDataReset)="resetNormalizationData($event)">
              </app-analysis-life-cycle-packaging-unit>
            </div>

            <alert type="info" class="lca-chart-alert">
              {{ 'analysis.lifecycleAnalysis.streamlinedLca' | translate: { version: ecoinventVersion } }}
            </alert>
            <div *ngIf="isUserValidator" class="button-container validation-button-container">
              <button mat-raised-button color="primary" class="custom-validation-button" type="button"
                (click)="validateAnalysis(true, element)">
                <mat-icon>check</mat-icon>
                {{ 'common.text.valid' | translate }}
              </button>
              <button mat-raised-button color="warn" class="custom-validation-button" type="button"
                (click)="validateAnalysis(false, element)">
                <mat-icon>clear</mat-icon>
                {{ 'common.text.invalid' | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-packaging-navigation>
