import { ComponentReportDto } from './../../../data-transfer/entities/component-entities/component-report-dto';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { PackagingPart } from '../../../model/packaging-part-enum';
import { ReportApiService } from 'src/app/data-transfer/services/report-api-service';
import { BaseDataApiService } from 'src/app/data-transfer/services/base-data-api-service';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PackagingUnitReportDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-report-dto';
import { FormControl, Validators } from '@angular/forms';
import { ReportGenerationDto } from 'src/app/data-transfer/entities/report-generation-dto';
import { DialogActions } from 'src/app/model/dictionary';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-report-generation-dialog',
  templateUrl: './report-generation-dialog.component.html',
  styleUrls: ['./report-generation-dialog.component.scss']
})
export class ReportGenerationDialogComponent implements OnDestroy {

  callerLevel!: number;
  itemId!: number;
  itemVersion!: number;
  reportTemplates: PackagingUnitReportDto[] | ComponentReportDto[];
  displayedColumns: string[] = ['id', 'profileName'];
  selectedReportTemplate?: PackagingUnitReportDto;
  relevantCountries: CountryDto[] = [];
  selectedReportCountries = new FormControl([], Validators.required);
  reportName = new FormControl(null);
  packagingPartEnum = PackagingPart;

  private reportSubscription?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ReportGenerationDialogComponent>,
    private baseDataApiService: BaseDataApiService,
    private reportApiService: ReportApiService,
    private spinner: NgxSpinnerService
  ) {
    this.callerLevel = this.data.callerLevel;
    this.itemId = this.data.id;
    this.itemVersion = this.data.version;
    this.reportTemplates = data.reportTemplates;
    this.setCountriesSelection(data.countries);
  }

  private async setCountriesSelection(relevantCountryCodes: string[]) {
    const allCountries = await firstValueFrom(this.baseDataApiService.getCountries());
    relevantCountryCodes.forEach(countryCodes => {
      const country = allCountries.find(x => x.code === countryCodes);
      if (country) { this.relevantCountries.push(country); }
    });
  }

  async generateReport() {
    this.selectedReportCountries.markAllAsTouched();
    this.reportName.markAllAsTouched();
    if (this.selectedReportCountries.invalid && this.callerLevel === PackagingPart.Unit) { return; }
    this.dialogRef.close({ event: DialogActions.CONFIRM });
    this.spinner.show();
    const reportData: ReportGenerationDto = {
      id: this.itemId,
      version: this.itemVersion,
      name: this.reportName.value ?? `ID_${this.itemId}_v${this.itemVersion}`,
      profileId: this.selectedReportTemplate?.id,
      countries: (this.selectedReportCountries.value as CountryDto[]).map(x => x.code)
    };
    let observable: Observable<any> | null = null;
    switch (this.callerLevel) {
      case PackagingPart.Unit:
        observable = this.reportApiService.generatePackagingUnitReport(reportData); break;
      case PackagingPart.Component:
        observable = this.reportApiService.generateComponentReport(reportData); break;
      default: break;
    }
    if (!observable) { return; }
    const reportPdf = await observable.toPromise();
    this.spinner.hide();
    const pdfUrl = window.URL.createObjectURL(reportPdf);
    window.open(pdfUrl, '_blank');
  }

  cancel() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  setSelectedReportTemplate(template: PackagingUnitReportDto) {
    this.selectedReportTemplate = template;
  }

  ngOnDestroy(): void {
    this.reportSubscription?.unsubscribe();
  }
}
