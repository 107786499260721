import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogActions, } from 'src/app/model/dictionary';
import { CountryAuthorizationDto } from 'src/app/data-transfer/entities/country-authorization-dto';
import { formatDate } from '@angular/common';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { EditAuthorizationDialogComponent, EditAuthorizationDialogResult } from '../edit-authorization-dialog/edit-authorization-dialog.component';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { EnvironmentalEffectDto } from 'src/app/data-transfer/entities/environmental-effect-dto';
import { EnvironmentalEffectAuthorizationDto } from 'src/app/data-transfer/entities/environmental-effect-authorization-dto';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';

export interface AuthorizationDialogResult {
  event: DialogActions,
  countryAuthorizations: CountryAuthorizationDto[],
  environmentalEffectsAuthorizations: EnvironmentalEffectAuthorizationDto[]
}

type CountryDialogType = EditAuthorizationDialogComponent<CountryAuthorizationDto, CountryDto>;
type CountryResultType = EditAuthorizationDialogResult<CountryDto>;
type EnvironmentalEffectDialogType = EditAuthorizationDialogComponent<EnvironmentalEffectAuthorizationDto, EnvironmentalEffectDto>;
type EnvironmentalEffectResultType = EditAuthorizationDialogResult<EnvironmentalEffectDto>;

@Component({
  selector: 'app-authorization-dialog',
  templateUrl: './authorization-dialog.component.html',
  styleUrls: ['./authorization-dialog.component.scss']
})
export class AuthorizationDialogComponent {
  countryAuthorizationsDataSource: MatTableDataSource<CountryAuthorizationDto>;
  environmentalEffectAuthorizationsDataSource: MatTableDataSource<EnvironmentalEffectAuthorizationDto>;
  allCountries: CountryDto[];
  allEnvironmentalEffects: EnvironmentalEffectDto[];
  columns: string[] = ["name", "authorizationStart", "authorizationEnd", "edit"];
  isSaveButtonDisabled = true;
  private activeTabIndex = 0;
  private persistedStartDatesByCountryAuthorizationId = new Map<number, string>();
  private persistedEndDatesByCountryEffectAuthorizationId = new Map<number, string>();
  private persistedStartDatesByEnvironmentalEffectAuthorizationId = new Map<number, string>();
  private persistedEndDatesByEnvironmentalEffectAuthorizationId = new Map<number, string>();

  constructor(
    private dialogRef: MatDialogRef<AuthorizationDialogComponent, AuthorizationDialogResult>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: {countryAuthorizations: CountryAuthorizationDto[],
                                                          environmentalEffectAuthorizations: EnvironmentalEffectAuthorizationDto[],
                                                          allCountries: CountryDto[],
                                                          allEnvironmentalEffects: EnvironmentalEffectDto[]
                                                        },
    protected dialog: MatDialog
  ) {
      this.countryAuthorizationsDataSource = new MatTableDataSource<CountryAuthorizationDto>(data.countryAuthorizations);
      this.environmentalEffectAuthorizationsDataSource
        = new MatTableDataSource<EnvironmentalEffectAuthorizationDto>(data.environmentalEffectAuthorizations);
        this.allCountries = data.allCountries;
      this.allEnvironmentalEffects = data.allEnvironmentalEffects;

      for (const countryAuthorization of data.countryAuthorizations) {
        this.persistedStartDatesByCountryAuthorizationId
          .set(countryAuthorization.id, countryAuthorization.authorizationStart);

        this.persistedEndDatesByCountryEffectAuthorizationId
          .set(countryAuthorization.id, countryAuthorization.authorizationEnd);
      }

      for (const environmentalEffectAuthorization of data.environmentalEffectAuthorizations) {
        this.persistedStartDatesByEnvironmentalEffectAuthorizationId
          .set(environmentalEffectAuthorization.id, environmentalEffectAuthorization.authorizationStart);

        this.persistedEndDatesByEnvironmentalEffectAuthorizationId
          .set(environmentalEffectAuthorization.id, environmentalEffectAuthorization.authorizationEnd);
      }
    }

  dateToString(date: string): string {
    return formatDate(date, 'dd.MM.yyyy', "en");
  }

  countryNameByCode(code: string): string {
    return this.allCountries.filter(x => x.code == code)[0].name;
  }

  environmentalEffectNameById(id: number): string {
    return this.allEnvironmentalEffects.filter(x => x.id == id)[0].name;
  }

  editCountryAuthorization(authorization: CountryAuthorizationDto) {
    const dialogRef = this.showEditCountryAuthorizationDialog(authorization, true);

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event == DialogActions.CONFIRM) {
        authorization.countryCode = result.result.authorizationType?.code ?? "";
        authorization.authorizationStart = result.result.authorizationStart;
        authorization.authorizationEnd = result.result.authorizationEnd;
        this.isSaveButtonDisabled = false;
      }
    });
  }

  private showEditCountryAuthorizationDialog(authorization: CountryAuthorizationDto, edit: boolean)
                                                            : MatDialogRef<CountryDialogType, CountryResultType> {
    const country = this.allCountries.find(x => x.code == authorization.countryCode);

    const dialogConfig = getDialogConfig(
      {authorization: authorization,
        allAuthorizations: this.allCountries,
        initialAuthorizationType: country,
        edit: edit,
        persistedStartDate: this.persistedStartDatesByCountryAuthorizationId.get(authorization.id),
        persistedEndDate: this.persistedEndDatesByCountryEffectAuthorizationId.get(authorization.id)
      }, '700px');

    const dialogRef = this.dialog.open<CountryDialogType, any, CountryResultType>
      (EditAuthorizationDialogComponent<CountryAuthorizationDto, CountryDto>,
        dialogConfig);

    return dialogRef;
  }

  deleteCountryAuthorization(authorization: CountryAuthorizationDto) {
    this.countryAuthorizationsDataSource.data = this.countryAuthorizationsDataSource.data.filter(x => x != authorization);
    this.isSaveButtonDisabled = false;
  }

  deleteEnvironmentalEffectAuthorization(authorization: EnvironmentalEffectAuthorizationDto) {
    this.environmentalEffectAuthorizationsDataSource.data
              = this.environmentalEffectAuthorizationsDataSource.data.filter(x => x != authorization);
    this.isSaveButtonDisabled = false;
  }

  editEnvironmentalEffectAuthorization(authorization: EnvironmentalEffectAuthorizationDto) {
    const dialogRef = this.showEditEnvironmentalEffectAuthorizationDialog(authorization, true);

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event == DialogActions.CONFIRM) {
        authorization.environmentalEffectId = result.result.authorizationType?.id ?? -1;
        authorization.authorizationStart = result.result.authorizationStart;
        authorization.authorizationEnd = result.result.authorizationEnd;
        this.isSaveButtonDisabled = false;
      }
    });
  }

  private showEditEnvironmentalEffectAuthorizationDialog(authorization: EnvironmentalEffectAuthorizationDto, edit: boolean)
                                      : MatDialogRef<EnvironmentalEffectDialogType, EnvironmentalEffectResultType> {
    const environmentalEffect = this.allEnvironmentalEffects.find(x => x.id == authorization.environmentalEffectId);

    const dialogConfig = getDialogConfig(
      {authorization: authorization,
       allAuthorizations: this.allEnvironmentalEffects,
       initialAuthorizationType: environmentalEffect,
       edit: edit,
       persistedStartDate: this.persistedStartDatesByEnvironmentalEffectAuthorizationId.get(authorization.id),
       persistedEndDate: this.persistedEndDatesByEnvironmentalEffectAuthorizationId.get(authorization.id)
      }, '700px');

    type dialogType = EditAuthorizationDialogComponent<EnvironmentalEffectAuthorizationDto, EnvironmentalEffectDto>;
    type resultType = EditAuthorizationDialogResult<EnvironmentalEffectDto>;

    const dialogRef = this.dialog.open<dialogType, any, resultType>
      (EditAuthorizationDialogComponent<EnvironmentalEffectAuthorizationDto, EnvironmentalEffectDto>,
       dialogConfig);

    return dialogRef;
  }

  onAddAuthorization() {
    if (this.activeTabIndex == 0) { // Country authorizations
      const authorization: CountryAuthorizationDto =  {
        id: -1,
        countryCode: "",
        authorizationStart: "",
        authorizationEnd: "",
        bought: true
      };

      const dialogRef = this.showEditCountryAuthorizationDialog(authorization, false);

      dialogRef.afterClosed().subscribe(result => {
        if (result?.event == DialogActions.CONFIRM) {
          authorization.countryCode = result.result.authorizationType?.code ?? "";
          authorization.authorizationStart = result.result.authorizationStart
          authorization.authorizationEnd = result.result.authorizationEnd

          this.countryAuthorizationsDataSource.data.push(authorization);
          this.countryAuthorizationsDataSource._updateChangeSubscription();

          this.isSaveButtonDisabled = false;
        }
      });
    } else if (this.activeTabIndex == 1) { // Environmental effects authorizations
      const authorization: EnvironmentalEffectAuthorizationDto =  {
        id: -1,
        environmentalEffectId: -1,
        authorizationStart: "",
        authorizationEnd: "",
        bought: true
      };

      const dialogRef = this.showEditEnvironmentalEffectAuthorizationDialog(authorization, false);

      dialogRef.afterClosed().subscribe(result => {
        if (result?.event == DialogActions.CONFIRM) {
          authorization.environmentalEffectId = result.result.authorizationType?.id ?? -1,
          authorization.authorizationStart = result.result.authorizationStart,
          authorization.authorizationEnd = result.result.authorizationEnd

          this.environmentalEffectAuthorizationsDataSource.data.push(authorization);
          this.environmentalEffectAuthorizationsDataSource._updateChangeSubscription();

          this.isSaveButtonDisabled = false;
        }
      });
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    this.activeTabIndex = event.index;
  }

  sortCountryData(sort: Sort) {
    const data = this.countryAuthorizationsDataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.countryAuthorizationsDataSource.data = data;
      return;
    }

    this.countryAuthorizationsDataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(this.countryNameByCode(a.countryCode), this.countryNameByCode(b.countryCode), isAsc);
        case 'authorizationStart':
          return this.compare(a.authorizationStart, b.authorizationStart, isAsc);
        case 'authorizationEnd':
          return this.compare(a.authorizationEnd ?? "", b.authorizationEnd ?? "", isAsc);
        default:
          return 0;
      }
    });
  }

  sortEnvironmentalEffectsData(sort: Sort) {
    const data = this.environmentalEffectAuthorizationsDataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.environmentalEffectAuthorizationsDataSource.data = data;
      return;
    }

    this.environmentalEffectAuthorizationsDataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(this.environmentalEffectNameById(a.environmentalEffectId),
                              this.environmentalEffectNameById(b.environmentalEffectId),
                              isAsc);
        case 'authorizationStart':
          return this.compare(a.authorizationStart, b.authorizationStart, isAsc);
        case 'authorizationEnd':
          return this.compare(a.authorizationEnd, b.authorizationEnd, isAsc);
        default:
          return 0;
      }
    });
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  closeDialog() {
    this.dialogRef.close(
      {
        event: DialogActions.REJECT,
        countryAuthorizations: [],
        environmentalEffectsAuthorizations: []
      });
  }

  onSuccess() {
    this.dialogRef.close(
      {
        event: DialogActions.CONFIRM,
        countryAuthorizations: this.countryAuthorizationsDataSource.data.filter(x => x.bought),
        environmentalEffectsAuthorizations: this.environmentalEffectAuthorizationsDataSource.data.filter(x => x.bought)
      });
  }
}
