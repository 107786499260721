import { AdditionalPackagingComponentsParentDialogComponent, ComponentDialogData } from './../additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-closure-dialog',
  templateUrl: './closure-dialog.component.html',
  styleUrls: ['./closure-dialog.component.scss']
})
export class ClosureDialogComponent extends AdditionalPackagingComponentsParentDialogComponent implements OnInit {

  addEditClosureForm: FormGroup;

  constructor(
    protected dialogRef: MatDialogRef<ClosureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: ComponentDialogData,
    protected gtinCheckService: GtinCheckService
  ) {
    super(dialogRef, gtinCheckService);
    this.callerId = PackagingComponentTypesEnum.Closure;

    super.setCommonComponentData(this.data);
    this.addEditClosureForm = this.createFormCopy(data.baseForm);
    super.subscribeToValueChanges(this.addEditClosureForm);
  }

  ngOnInit(): void {
    if (!this.canEditForm) {
      this.addEditClosureForm.disable();
    }
    this.selectedManufacturingCountry = this.addEditClosureForm.controls.manufacturingCountry.value ?? '';
  }

  async doAction() { super.doAction(this.addEditClosureForm); }
}
