<div class="local-container">
  <div class="full-height-column">
    <mat-card>
      <mat-toolbar>
        <app-toolbar-directory #toolbarDirectory [selectedPackagingPartTab]="selectedPackagingPartTab"
          [selectedEntriesCount]="selectedEntries.length" [isAddEditAllowed]="directoryPermissions.write"
          [isGenericSelected]="isGenericSelected()" [isComparisonAllowed]="directoryPermissions.analyze"
          [isRecyclingBinSelected]="isRecyclingBinSelected" [selectedDtoFolder]="selectedDtoFolder"
          [rootDtoFolder]="rootDtoFolder" (moveClicked)="movePackagingPart()" (copyClicked)="copyPackagingPart()"
          (exportClicked)="exportPackagingPart()" (deleteClicked)="deletePackagingPart()"
          (restoreClicked)="restorePackagingPart()" (compareClicked)="comparePackagingPart()"
          (editQuantityClicked)="editPackagingPartQuantity()" (folderUpdated)="updateView()"
          (folderRemoved)="afterFolderRemoved()" (editTagsClicked)="editTags()" (addTagsClicked)="addTags()">
        </app-toolbar-directory>
      </mat-toolbar>
    </mat-card>

    <div class="full-height-row flex-row">
      <mat-card class="treeview-container">
        <app-directory-tree [rootFolder]="rootDtoFolder" [treeDataSource]="treeDataSource" [contextMenu]="contextMenu"
          (folderSelected)="onFolderSelected($event)">
        </app-directory-tree>
      </mat-card>
      <mat-card class="details-container">
        <div *ngIf="selectedDtoFolder" class="table-div">
          <div class="local-scrolling-wrapper">
            <mat-tab-group [(selectedIndex)]="selectedPackagingPartTab" (selectedTabChange)="onTabChanged()">
              <mat-tab label="{{ 'packagingSystem.packagingSystems' | translate }}">
                <app-packaging-systems-overview [dataSource]="packagingSystemsDataSource" [allTags]="allTags"
                  [displayedColumns]="displayedColumnsPackagingSystems"
                  [isRecyclingBinSelected]="isRecyclingBinSelected"
                  (packagingPartsSelected)="packagingEntriesSelected($event)"
                  (dataChanged)="packagingPartDataChanged()">
                </app-packaging-systems-overview>
              </mat-tab>
              <mat-tab label="{{ 'packagingUnit.packagingUnits' | translate }}">
                <app-packaging-units-overview [dataSource]="packagingUnitsDataSource" [allTags]="allTags"
                  [displayedColumns]="displayedColumnsPackagingUnits" [isRecyclingBinSelected]="isRecyclingBinSelected"
                  (packagingPartsSelected)="packagingEntriesSelected($event)"
                  (dataChanged)="packagingPartDataChanged()">
                </app-packaging-units-overview>
              </mat-tab>
              <mat-tab label="{{ 'component.packagingComponents' | translate }}">
                <app-packaging-components-overview [dataSource]="componentsDataSource" [allTags]="allTags"
                  [displayedColumns]="displayedColumnsComponents" [isRecyclingBinSelected]="isRecyclingBinSelected"
                  (packagingPartsSelected)="packagingEntriesSelected($event)"
                  (dataChanged)="packagingPartDataChanged()">
                </app-packaging-components-overview>
              </mat-tab>
              <mat-tab label="{{ 'material.compositeMaterials' | translate }}">
                <app-composite-materials-overview [dataSource]="materialsDataSource" [allTags]="allTags"
                  [displayedColumns]="displayedColumnsMaterials" [isRecyclingBinSelected]="isRecyclingBinSelected"
                  (packagingPartsSelected)="packagingEntriesSelected($event)"
                  (dataChanged)="packagingPartDataChanged()">
                </app-composite-materials-overview>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <ng-template #contextMenu let-item>
    <section class="context-menu">
      <div *ngIf="item.id !== recyclingBinId && directoryPermissions?.write"
        [matMenuTriggerFor]="toolbarDirectory.createMenu">
        {{ 'dataManagement.directory.toolbar.create.button' | translate }}...
      </div>
      <div *ngIf="item.id !== rootDtoFolder.id && item.id !== recyclingBinId" (click)="removeFolder()">
        {{ 'dataManagement.directory.toolbar.removeFolderButton' | translate }}
      </div>
      <div *ngIf="item.id !== rootDtoFolder.id && item.id !== recyclingBinId" (click)="renameFolder()">
        {{ 'dataManagement.directory.toolbar.renameFolderButton' | translate }}
      </div>
      <div *ngIf="item.id !== rootDtoFolder.id && item.id !== recyclingBinId" (click)="moveFolder()">
        {{ 'dataManagement.directory.toolbar.moveFolderButton' | translate }}
      </div>
      <div *ngIf="item.id === recyclingBinId" (click)="emptyRecyclingBin()">
        {{ 'dataManagement.directory.toolbar.contextMenu.emptyBin' | translate }}
      </div>
      <div *ngIf="item.id === recyclingBinId" (click)="restoreAllItemsFromRecyclingBin()">
        {{ 'dataManagement.directory.toolbar.contextMenu.restoreAll' | translate }}
      </div>
    </section>
  </ng-template>
</div>
