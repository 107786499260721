import { TranslateService } from '@ngx-translate/core';
import { DARK_THEME_FONT, LIGHT_THEME_FONT, DARK_THEME_BACKGROUND, LIGHT_THEME_BACKGROUND } from 'src/app/model/charts-color-collection';
import { MaterialMassAggregatedEvaluationResult } from './../../../../util/analyses-util/material-mass/material-mass-aggregated-evaluation-result';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import * as  Highcharts from 'highcharts';
import { PlotSeriesDataLabelsOptions } from 'highcharts';
import Accessibility from 'highcharts/modules/accessibility';
Accessibility(Highcharts);

@Component({
  selector: 'app-mass-chart',
  templateUrl: './mass-chart.component.html',
  styleUrls: ['./mass-chart.component.scss']
})
export class MassChartComponent implements OnInit, OnChanges {

  @Input() chartRawData!: MaterialMassAggregatedEvaluationResult;
  @Input() chartHeight = -1;
  @Input() chartWidth = -1;
  @Input() isDarkTheme = false;

  @Output() newAnalysisRequested = new EventEmitter();

  updateFlag = false;
  highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'pie',
      marginBottom: 100
    },
    title: {
      text: ''
    },
    lang: {
      viewFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.fullscreen'),
      exitFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.exitFullscreen'),
      downloadJPEG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadJpeg'),
      downloadPNG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPng'),
      downloadPDF: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPdf'),
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
        }
      }
    },
    navigation: {
      buttonOptions: {
        theme: {
          stroke: 'darkgrey'
        }
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        dataLabels: {
          format: '{point.name}: {point.y:.2f}%',
          style: {
            fontSize: '20px',
            'font-family': '\'Inter UI\', sans-serif'
          }
        },
      },
      pie: {
        showInLegend: false
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>'
    }
  };

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.setChartSeries();
    this.setOriginDependentChartOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.chartRawData && changes.chartRawData.currentValue !== changes.chartRawData.previousValue) ||
      (changes.isDarkTheme && changes.isDarkTheme.previousValue !== undefined &&
        changes.isDarkTheme.currentValue !== changes.isDarkTheme.previousValue)
    ) {
      this.setChartSeries();
      this.setOriginDependentChartOptions();
      this.updateFlag = true;
    }
  }

  private setOriginDependentChartOptions() {
    if (this.chartOptions.chart) {
      if (this.chartWidth) { this.chartOptions.chart.width = this.chartWidth; }
      this.chartOptions.chart.height = this.chartHeight;
      this.chartOptions.chart.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
    }
    if (this.chartOptions.navigation && this.chartOptions.navigation.buttonOptions && this.chartOptions.navigation.buttonOptions.theme) {
      this.chartOptions.navigation.buttonOptions.theme.fill = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
    }
    if (this.chartOptions.exporting && this.chartOptions.exporting.buttons &&
      this.chartOptions.exporting && this.chartOptions.exporting.buttons.contextButton) {
      this.chartOptions.exporting.buttons.contextButton.x = 0;
    }
    if (this.chartOptions.plotOptions && this.chartOptions.plotOptions.series) {
      const dataLabelsOptions = this.chartOptions.plotOptions.series.dataLabels as PlotSeriesDataLabelsOptions;
      if (dataLabelsOptions.style) {
        dataLabelsOptions.style.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
    }
  }

  private setChartSeries() {
    const chartData = [];
    if (this.chartRawData.dataSource) {
      for (const materialResult of this.chartRawData.dataSource) {
        chartData.push({
          name: materialResult.materialManifestationName,
          y: materialResult.weightPercentage * 100,
          color: this.isDarkTheme ? '#73B0D6' : '#1D4679'
        });
      }
    }
    this.chartOptions.series = [{
      type: 'pie',
      name: this.translateService.instant('analysis.massAnalysis.chartTitle'),
      // colorByPoint: true,
      data: chartData
    }];
  }

}
