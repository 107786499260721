import { PackagingCollectiveQuantityDto } from '../entities/packaging-unit-entities/packaging-quantity-dto';
import { AggregateEvaluationMassOutputDto } from '../entities/aggregate-evaluation-mass-output';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ParentApiService } from './parent-api-service';
import { environment } from 'src/environments/environment';
import { AggregateEvaluationInputDto } from '../entities/aggregate-evaluation-input-dto';
import { AggregateEvaluationRecyclabilityOutputDto, AggregateEvaluationWrapper } from '../entities/aggregate-evaluation-recyclability-output-dto';
import { AggregateEvaluationLifecycleOutputDto } from '../entities/aggregate-evaluation-lifecycle-output-dto';
import { ProblemInformationDto } from '../entities/evaluation-entities/problem-information-dto';

@Injectable({ providedIn: 'root' })
export class AggregatedAnalysisApiService extends ParentApiService {

    constructor(private http: HttpClient) { super(); }

    public performAggregateEvalRecyclability(inputData: AggregateEvaluationInputDto, stayOnPageIfError?: boolean
    ): Observable<AggregateEvaluationRecyclabilityOutputDto> {
        const url = `${environment.webApiAddress}/api/recyclability-aggregate-evaluation/evaluate`;
        return this.http.post<AggregateEvaluationRecyclabilityOutputDto>(
            url, inputData, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getRecyclabilityAggregationProblemInformation(inputData: AggregateEvaluationInputDto, stayOnPageIfError?: boolean
    ): Observable<ProblemInformationDto[]> {
        const url = `${environment.webApiAddress}/api/recyclability-aggregate-evaluation/problem-information`;
        return this.http.post<ProblemInformationDto[]>(
            url, inputData, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public performAggregateEvalLifecycle(inputData: AggregateEvaluationInputDto, stayOnPageIfError?: boolean
    ): Observable<AggregateEvaluationLifecycleOutputDto> {
        const url = `${environment.webApiAddress}/api/life-cycle-aggregate-evaluation/evaluate`;
        return this.http.post<AggregateEvaluationLifecycleOutputDto>(
            url, inputData, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getLifeCycleAggregationProblemInformation(inputData: AggregateEvaluationInputDto, stayOnPageIfError?: boolean
    ): Observable<ProblemInformationDto[]> {
        const url = `${environment.webApiAddress}/api/life-cycle-aggregate-evaluation/problem-information`;
        return this.http.post<ProblemInformationDto[]>(
            url, inputData, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public performAggregateEvalMass(inputData: AggregateEvaluationInputDto, stayOnPageIfError?: boolean
    ): Observable<AggregateEvaluationMassOutputDto> {
        const url = `${environment.webApiAddress}/api/material-aggregation/evaluate`;
        return this.http.post<AggregateEvaluationMassOutputDto>(
            url, inputData, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getAggregateRecyclabilityById(evaluationId: number): Observable<AggregateEvaluationRecyclabilityOutputDto> {
        const url = `${environment.webApiAddress}/api/recyclability-aggregate-evaluation/history/by-id?id=${evaluationId}`;
        return this.http.get<AggregateEvaluationRecyclabilityOutputDto>(url);
    }

    public getAggregateLifecycleById(evaluationId: number): Observable<AggregateEvaluationLifecycleOutputDto> {
        const url = `${environment.webApiAddress}/api/life-cycle-aggregate-evaluation/history/by-id?id=${evaluationId}`;
        return this.http.get<AggregateEvaluationLifecycleOutputDto>(url);
    }

    public getAllAggregateRecyclabilityEvaluations(): Observable<AggregateEvaluationWrapper[]> {
        const url = `${environment.webApiAddress}/api/recyclability-aggregate-evaluation/history`;
        return this.http.get<AggregateEvaluationWrapper[]>(url);
    }

    public getAllAggregateLifecycleEvaluations(): Observable<AggregateEvaluationWrapper[]> {
        const url = `${environment.webApiAddress}/api/life-cycle-aggregate-evaluation/history`;
        return this.http.get<AggregateEvaluationWrapper[]>(url);
    }

    public getAggregateRecyclabilityCost(inputData: AggregateEvaluationInputDto, stayOnPageIfError?: boolean
    ): Observable<number> {
        const url = `${environment.webApiAddress}/api/recyclability-aggregate-evaluation/costs`;
        return this.http.post<number>(url, inputData, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getAggregateLifecycleCost(inputData: AggregateEvaluationInputDto, stayOnPageIfError?: boolean
    ): Observable<number> {
        const url = `${environment.webApiAddress}/api/life-cycle-aggregate-evaluation/costs`;
        return this.http.post<number>(url, inputData, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public renameAggregateEvaluationRec(evalId: number, newName: string, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/recyclability-aggregate-evaluation/rename?id=${evalId}&name=${newName}`;
        return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public renameAggregateEvaluationLca(evalId: number, newName: string, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/life-cycle-aggregate-evaluation/rename?id=${evalId}&name=${newName}`;
        return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getAggregateRecEvalQuantities(evaluationId: number): Observable<PackagingCollectiveQuantityDto> {
        const url = `${environment.webApiAddress}/api/recyclability-aggregate-evaluation/quantities?aggregationResultId=${evaluationId}`;
        return this.http.get<PackagingCollectiveQuantityDto>(url);
    }

    public getAggregateLcaEvalQuantities(evaluationId: number): Observable<PackagingCollectiveQuantityDto> {
        const url = `${environment.webApiAddress}/api/life-cycle-aggregate-evaluation/quantities?aggregationResultId=${evaluationId}`;
        return this.http.get<PackagingCollectiveQuantityDto>(url);
    }

    public deleteAggregateRecEval(evaluationId: number, stayOnPageIfError: boolean = true): Observable<any> {
        const url = `${environment.webApiAddress}/api/recyclability-aggregate-evaluation?id=${evaluationId}`;
        return this.http.request('delete', url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public deleteAggregateLcaEval(evaluationId: number, stayOnPageIfError: boolean = true): Observable<any> {
        const url = `${environment.webApiAddress}/api/life-cycle-aggregate-evaluation?id=${evaluationId}`;
        return this.http.request('delete', url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }
}
