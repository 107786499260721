<h1 mat-dialog-title>{{ 'dataManagement.import.titleExcelImport' | translate }}</h1>

<mat-dialog-content style="min-width: 400px;">
<input type="file" accept=".xlsx" (change)="openExcelFile($event)" #hiddenFileInput
onclick="value=null" style="display: none" />

<button mat-raised-button (click)="hiddenFileInput.click()" color="primary" type="button">
  <mat-icon class="material-icons left">upload</mat-icon>
  {{ 'dataManagement.manageOrganizations.bulkUpload.loadExcelFileButton' | translate }}
</button>
<span *ngIf="excelFile">
  {{ excelFile.name }}
</span>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button type="submit" color="primary" (click)="uploadCreateUnit()" [disabled]="spinnerActive || excelFile == null">
    {{ 'common.buttons.import' | translate }}
    <div class="spinner-container" *ngIf="spinnerActive">
      <mat-spinner diameter="24" color="accent"></mat-spinner>
    </div>
  </button>

  <button mat-dialog-close mat-raised-button type="button">
    {{ 'dataManagement.manageOrganizations.bulkUpload.closeButton' | translate }} </button>

</mat-dialog-actions>
