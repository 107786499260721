<h1 mat-dialog-title>
  <mat-icon id="info-icon">info</mat-icon>{{'analysisNotPossibleInfo.title' | translate}}
</h1>
<mat-dialog-content>
  <div class="dialog-content">
    <div class="section-text">{{ generalExplanation }}</div>

    <div class="expand-collapse-all-buttons">
      <button class="right" matTooltip="{{'common.buttons.collapseAll'| translate}}" mat-icon-button (click)="nestedTreeControl.collapseAll()">
        <mat-icon class="tree-icon">keyboard_double_arrow_up</mat-icon>
      </button>

      <button class="right" matTooltip="{{'common.buttons.expandAll'| translate}}" mat-icon-button (click)="nestedTreeControl.expandAll()">
        <mat-icon class="tree-icon">keyboard_double_arrow_down</mat-icon>
      </button>
    </div>

    <mat-tree #nestedTree [dataSource]="treeDataSource" [treeControl]="nestedTreeControl" class="dir-tree">
      <mat-nested-tree-node *matTreeNodeDef="let node">
        <div class="mat-tree-node cursor"
          [ngClass]="isDarkTheme ? 'background-highlight-dark' : 'background-highlight'">

          <button mat-icon-button [attr.aria-label]="'Toggle ' + node.fieldName" matTreeNodeToggle>
            <mat-icon *ngIf="hasChild(node)">
              {{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>

            <mat-icon *ngIf="!hasChild(node)" class="tree-icon error-icon">error</mat-icon>
          </button>

          <div>{{getTreeLabel(node)}}</div>
        </div>
        <div [class.dir-tree-invisible]="!nestedTreeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>

  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-dialog-close mat-raised-button color="primary" type="submit">OK</button>
</mat-dialog-actions>
