import { AuthService } from '../../../../services/auth-service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PackagingUnitNavigationService } from 'src/app/navigation/services/navigation-services/packaging-unit-navigation.service';
import { DirectoryApiService } from 'src/app/data-transfer/services/directory-api-service';
import { PackagingUnitApiService } from 'src/app/data-transfer/services/packaging-unit-api-service';
import { PackagingSystemNavigationService } from 'src/app/navigation/services/navigation-services/packaging-system-navigation.service';
import { AnalysisMenuCaller } from 'src/app/components/shared-components/analyses-menu/analyses-menu-parent/analyses-menu-parent.component';
import { Subscription } from 'rxjs';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { LicenseService } from 'src/app/services/licensing-service';

@Component({
  selector: 'app-packaging-navigation',
  templateUrl: './packaging-navigation.component.html',
  styleUrls: ['./packaging-navigation.component.scss'],
})
export class PackagingNavigationComponent implements OnInit, OnDestroy {

  @Input() itemId = -1;
  @Input() versionId = -1;
  @Input() callerLevelId!: number;
  @Input() callerTypeId?: number;
  @Input() currentDirectoryId?: number;
  @Input() creationTimestamp!: Date;
  @Input() isGeneric = false;
  @Input() isPreview = false;
  @Input() isCurrentVersion = false;
  @Input() hasRecyclabilityResult = false;
  @Input() hasLifeCycleResult = false;
  @Input() public brandName = '';
  @Input() public productName = '';
  @Input() public articleNumber = '';

  isPackagingUnit = true;
  isWriteAllowed = true;
  isUserValidator = false;
  callerPageId = AnalysisMenuCaller.Sidenav;

  protected permissionsSubscription?: Subscription;
  protected downloadSubscription?: Subscription;

  constructor(
    private unitNavigationService: PackagingUnitNavigationService,
    private systemNavigationService: PackagingSystemNavigationService,
    private directoryApiService: DirectoryApiService,
    private packagingUnitApiService: PackagingUnitApiService,
    private authService: AuthService,
    private licenseService: LicenseService
  ) {
    this.isUserValidator = this.authService.isUserValidator();
  }

  ngOnInit(): void {
    if (this.currentDirectoryId != null) {
      this.permissionsSubscription = this.directoryApiService.determineCurrentUserPermissions(this.currentDirectoryId)
        .subscribe(result =>
          this.isWriteAllowed = result.write
        );
    }
    this.isPackagingUnit = this.callerLevelId === PackagingPart.Unit;
  }

  getProductNameText() {
    return this.itemId != null ? this.brandName + ' / ' + this.productName : '---';
  }

  getArticleNumberText() {
    return this.itemId != null ? this.articleNumber ?? '' : '';
  }

  navigateToItem(versionId?: number) {
    if (this.itemId == null) { return; }
    switch (this.callerLevelId) {
      case PackagingPart.System:
        this.systemNavigationService.navigateToPackagingSystem(this.itemId, versionId);
        break;
      case PackagingPart.Unit:
        this.unitNavigationService.navigateToPackagingUnit(this.itemId, versionId);
        break;
      default: break;
    }
  }

  downloadPackagingUnitJson() {
    this.downloadSubscription = this.packagingUnitApiService.downloadPackagingUnitsJson([{ id: this.itemId, version: this.versionId }])
      .subscribe(zipFile => {
        const zipUrl = window.URL.createObjectURL(zipFile);
        window.open(zipUrl, '_blank');
      });
  }

  ngOnDestroy(): void {
    this.permissionsSubscription?.unsubscribe();
    this.downloadSubscription?.unsubscribe();
  }

  isLcaAllowed(): boolean {
    return this.licenseService.isLcaAllowed;
  }

  isCCAllowed(): boolean {
    return this.licenseService.isCCAllowed;
  }
}
