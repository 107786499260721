<app-packaging-navigation [itemId]="packagingPartId" [callerLevelId]="callerLevelId"
  [callerTypeId]="packagingUnitTypeId" [currentDirectoryId]="currentDirectoryId" [brandName]="brandName"
  [currentDirectoryId]="currentDirectoryId" [productName]="productName" [articleNumber]="articleNumber"
  [versionId]="packagingPartVersion" [isCurrentVersion]="isCurrentVersion"
  [hasRecyclabilityResult]="hasRecyclabilityResult" [hasLifeCycleResult]="hasLifeCycleResult"
  [creationTimestamp]="creationTimestamp">

  <h1>{{ title }}</h1>

  <div class="parent-container">

    <div class="table-container">
      <mat-table [dataSource]="dataSource">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column.key">
            <mat-header-cell *matHeaderCellDef>{{column.header}}</mat-header-cell>
            <mat-cell *matCellDef="let element"
              (click)="countryClicked(element.countryCode)">{{element[column.key]}}</mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="selectedRow === row"
          [ngClass]="isDarkTheme ? 'background-highlight-dark' : 'background-highlight'"></mat-row>
      </mat-table>
    </div>

    <div class="map-container">
      <app-map-expense-calculation [chartDataSource]="chartDataSource" [isDarkTheme]="isDarkTheme"
        (countryClicked)="countryClicked($event)">
      </app-map-expense-calculation>
    </div>
  </div>
</app-packaging-navigation>