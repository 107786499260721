import { RemovabilityConditionDto } from './../../../../../data-transfer/entities/removability-condition-dto';
import { DictionaryHandler, BooleanDictionary, TriStateDictionary, DialogActions } from './../../../../../model/dictionary';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { PackagingPartTypeDto } from 'src/app/data-transfer/entities/packaging-part-type-dto';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { CustomFieldAssignmentsDto } from 'src/app/data-transfer/entities/custom-field-assignments-dto';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { CustomFieldProfileDto } from 'src/app/data-transfer/entities/custom-field-profile-dto';
import { MatDialog } from '@angular/material/dialog';
import { CustomFieldsDialogComponent, CustomFieldsDialogResult } from 'src/app/components/dialogs/custom-fields-dialog/custom-fields-dialog.component';
import { ComponentApiService } from 'src/app/data-transfer/services/component-api-service';

@Component({
  selector: 'app-component-sub-html-template',
  templateUrl: './component-sub-html-template.component.html',
  styleUrls: ['./component-sub-html-template.component.scss']
})
export class ComponentSubHtmlTemplateComponent implements OnInit {

  @Input() form!: FormGroup;
  @Input() componentTypeSelectionLabel = '';
  @Input() componentSubtypes: PackagingPartTypeDto[] = [];
  @Input() manufacturingCountries: CountryDto[] = [];
  @Input() distributionCountries?: CountryDto[];
  @Input() canEditForm = false;
  @Input() isDecoration = false;
  @Input() isInlay = false;
  @Input() isMainBody = false;
  @Input() isTracked = false;
  @Input() isIndependentComponent = false;
  @Input() removabilityConditions?: RemovabilityConditionDto[];

  @Output() componentSubtypeChanged = new EventEmitter();
  @Output() adhesivesOptionChanged = new EventEmitter();

  flexibilityDictionary: BooleanDictionary[];
  nirBarrierDictionary: TriStateDictionary[];
  componentRemovedDictionary: BooleanDictionary[];
  adhesivesUsedDictionary: BooleanDictionary[];
  attachedToMainBodyDictionary: BooleanDictionary[];
  minimalLength = 0.001;
  showSurfaceRatioInfo = false;
  nirBarrierPlaceholder : string = '';

  customFieldProfiles: CustomFieldProfileDto[] = [];

  constructor(
    private dictionaryHandler: DictionaryHandler,
    private componentTypeService: ComponentTypeService,
    private componentApiService: ComponentApiService,
    private dialog: MatDialog
  ) {
    this.flexibilityDictionary = this.dictionaryHandler.getFlexibilityDictionary();
    this.componentRemovedDictionary = this.dictionaryHandler.getYesNoDictionary();
    this.adhesivesUsedDictionary = this.dictionaryHandler.getYesNoDictionary();
    this.attachedToMainBodyDictionary = this.dictionaryHandler.getYesNoDictionary();
    this.nirBarrierDictionary = this.dictionaryHandler.getNirBarrierDictionary();
  }

  private reloadCustomFieldProfiles() {
    const componentSubtypeId = this.form.controls.packagingComponentSubtypeId.value;

    this.componentApiService.getCustomFieldProfiles(componentSubtypeId).subscribe(profiles => {
      this.customFieldProfiles = profiles;
    });
  }

  ngOnInit(): void {
    this.reloadCustomFieldProfiles();

    if (!this.isIndependentComponent && !this.isInlay && !this.isMainBody &&
      (!this.isDecoration || !this.componentTypeService.isDecorationOfTypeInMold(this.form.value.packagingComponentSubtypeId))) {
      this.setRemovedByConsumerValueAndValidators(this.form.controls.removedBeforeUsage.value);
    }
    this.addRemoveAdhesionFields();
    this.form.controls.packagingComponentSubtypeId.valueChanges.subscribe(newId => {
      this.reloadCustomFieldProfiles();
      this.form.controls.packagingComponentSubtypeName.patchValue(
        this.componentSubtypes.find(x => x.id === newId)?.name ?? '');
    });
  }

  private addRemoveAdhesionFields() {
    if (!this.isDecoration) { return; }
    if (!this.isIndependentComponent &&
      this.componentTypeService.isDecorationOfTypeLabel(this.form.getRawValue().packagingComponentSubtypeId)) {
      if (!this.form.controls.isAdhesiveUsed) {
        this.form.addControl('isAdhesiveUsed', new FormControl(null, Validators.required));
      }
      if (!this.form.controls.removabilityConditionId) {
        this.form.addControl('removabilityConditionId', new FormControl(null, Validators.required));
      }
    } else {
      if (this.form.controls.isAdhesiveUsed) {
        this.form.removeControl('isAdhesiveUsed');
      }
      if (this.form.controls.removabilityConditionId) {
        this.form.removeControl('removabilityConditionId');
      }
    }
  }

  onComponentTypeChange(subtypeId: number) {
    if (!this.isDecoration) { return; }
    this.addRemoveAdhesionFields();
    this.componentSubtypeChanged.emit(subtypeId);
  }

  onAdhesivesOptionChange(event: boolean) {
    this.adhesivesOptionChanged.emit(event);
  }

  onRemovedBeforeUsageChange(newRemovedBeforeUsage: boolean) {
    this.setRemovedByConsumerValueAndValidators(newRemovedBeforeUsage);
  }

  private setRemovedByConsumerValueAndValidators(removedBeforeUsage: boolean) {
    if (!this.canEditForm || this.isTracked) { return; }
    const removedByConsumerControl = this.form.controls.removedByConsumer;
    if (removedBeforeUsage) {
      removedByConsumerControl.disable();
      removedByConsumerControl.clearValidators();
      removedByConsumerControl.patchValue(true);

    } else {
      removedByConsumerControl.enable();
      removedByConsumerControl.setValidators(Validators.required);
    }
  }

  showCustomFields() {
    const dialogConfig = getDialogConfig({ profiles: this.customFieldProfiles,
                                           assignments: this.form.controls.customFields.value ?? new CustomFieldAssignmentsDto(),
                                           canEditForm: this.canEditForm }, '1500px');

    const dialogRef = this.dialog.open<CustomFieldsDialogComponent, any, CustomFieldsDialogResult>
                                    (CustomFieldsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event == DialogActions.CONFIRM) {
        this.form.controls.customFields.setValue(result.assignments);
      }
    });
  }
}
