import { PackagingUnitApiService } from './../../../../../data-transfer/services/packaging-unit-api-service';
import { PackagingSystemApiService } from './../../../../../data-transfer/services/packaging-system-api-service';
import { BaseDataApiService } from './../../../../../data-transfer/services/base-data-api-service';
import { PackagingSystemInfoDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { SimpleConfirmDialogComponent } from './../../../../dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CountryDto } from '../../../../../data-transfer/entities/country-dto';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { Validators, ValidatorFn, ValidationErrors, AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { PackagingUnitInfoDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogActions } from 'src/app/model/dictionary';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-edit-quantities-dialog',
  templateUrl: './edit-quantities-dialog.component.html',
  styleUrls: ['./edit-quantities-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ]
})
export class EditQuantitiesDialogComponent implements OnInit, OnDestroy {

  conutries: CountryDto[] = [];
  unitDataSource!: MatTableDataSource<PackagingUnitInfoDto>;
  systemDataSource!: MatTableDataSource<PackagingSystemInfoDto>;
  displayedColumnsUnit: string[] = ['id', 'packagingTypeName', 'brandName', 'productName', 'quantityInput'];
  displayedColumnsSystem: string[] = ['id', 'brandName', 'productName', 'quantityInput'];
  form: FormGroup;
  isLoading = true;
  isLoading$ = new Subject<boolean>();
  packagingUnits: PackagingUnitInfoDto[] = [];
  packagingSystems: PackagingSystemInfoDto[] = [];

  private getCountriesSubscription?: Subscription;
  private addCountrySubscription?: Subscription;
  private loadingSubscription?: Subscription;
  private getQuantitySubscription?: Subscription;
  private setQuantitySubscription?: Subscription;
  private quantityChangesSubscription?: Subscription;
  private dialogSubscription?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    protected baseDataApiService: BaseDataApiService,
    protected packagingUnitApiService: PackagingUnitApiService,
    protected packagingSystemApiService: PackagingSystemApiService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      quantityForms: this.formBuilder.array([]),
      selectGroup: this.formBuilder.group({
        countryCode: [null, Validators.required],
        selectedMonthAndYear: [moment(), Validators.required],
      })
    });
  }

  get countryCode(): string {
    return this.form.getRawValue().selectGroup.countryCode;
  }

  get quantityForms(): FormArray {
    return this.form.controls.quantityForms as FormArray;
  }

  get selectedMonthAndYear(): Moment {
    return this.form.getRawValue().selectGroup.selectedMonthAndYear;
  }

  get selectGroup(): FormGroup {
    return this.form.controls.selectGroup as FormGroup;
  }

  get isPackagingUnit(): boolean {
    return this.packagingUnits.length > 0;
  }

  get isPackagingSystem(): boolean {
    return this.packagingSystems.length > 0;
  }

  get packaging() {
    if (this.packagingUnits.length > 0) {
      return this.packagingUnits;
    }
    if (this.packagingSystems.length > 0) {
      return this.packagingSystems;
    }
    return null;
  }

  ngOnInit(): void {
    if (this.data.packagingUnits) {
      this.packagingUnits = this.data.packagingUnits;
      this.unitDataSource = new MatTableDataSource(this.packagingUnits);
      this.selectGroup.controls.countryCode.setValue(this.packagingUnits[0].distributionCountries[0]);
      this.createForm();

      this.getCountriesSubscription = this.baseDataApiService.getDistributionCountries(true).subscribe(countries => {
        this.conutries = countries;
        this.loadQuantities();
      });
    }
    if (this.data.packagingSystems) {
      this.packagingSystems = this.data.packagingSystems;
      this.systemDataSource = new MatTableDataSource(this.packagingSystems);
      const distributionCountries = this.packagingSystems[0]?.distributionCountries ?? [];
      this.selectGroup.controls.countryCode.setValue(distributionCountries[0]);
      this.createForm();

      this.getCountriesSubscription = this.baseDataApiService.getDistributionCountries(true).subscribe(countries => {
        this.conutries = countries;
        this.loadQuantities();
      });
    }
    this.loadingSubscription = this.isLoading$.subscribe(isLoading => {
      this.isLoading = isLoading;
      this.setEnableDisable();
    });
    this.quantityChangesSubscription = this.quantityForms.valueChanges.subscribe(() => this.setEnableDisable());
    this.disableQuantities();
  }

  private disableQuantities() {
    this.quantityForms.controls.forEach(formEntry => {
      formEntry.enable();
      let packaging: PackagingUnitInfoDto | PackagingSystemInfoDto | null = null;
      if (this.isPackagingUnit) {
        packaging = this.packagingUnits.find(x => x.id === formEntry.value.id) ?? null;
        const distributionCountries = packaging?.distributionCountries ?? [];
        if (!distributionCountries.includes(this.countryCode)) {
          formEntry.disable();
        }
      }
      if (this.isPackagingSystem) {
        packaging = this.packagingSystems.find(x => x.id === formEntry.value.id) ?? null;
        const distributionCountries = packaging?.distributionCountries ?? [];
        if (!distributionCountries.includes(this.countryCode)) {
          formEntry.disable();
        }
      }
    });
  }

  onCountryChanged() {
    this.loadQuantities();
    this.disableQuantities();
  }

  private loadQuantities() {
    this.isLoading = true;

    if (this.countryCode == null) {
      this.isLoading$.next(false);
      return;
    }

    if (this.isPackagingUnit) {
      const results = this.unitDataSource.data.map(packagingUnit => {
        if (packagingUnit.id != null && packagingUnit.version != null) {
          return this.packagingUnitApiService.getPackagingUnitQuantity(packagingUnit.id, this.selectedMonthAndYear.year(),
            +this.selectedMonthAndYear.format('M'), this.countryCode, packagingUnit.version, true);
        }
        return of(null);
      });

      this.getQuantitySubscription = forkJoin(results).subscribe(result => {
        this.unitDataSource.data.forEach((packagingUnit, idx) => {
          const form = this.quantityForms.controls.find(c => c.value.id === packagingUnit.id);
          form?.patchValue({ quantity: result[idx] });
        });
        this.form.markAsPristine();
        this.isLoading$.next(false);
      });
    }

    if (this.isPackagingSystem) {
      const results = this.systemDataSource.data.map(packagingSystem => {
        if (packagingSystem.id != null && packagingSystem.version != null) {
          return this.packagingSystemApiService.getPackagingSystemQuantity(packagingSystem.id, this.selectedMonthAndYear.year(),
            +this.selectedMonthAndYear.format('M'), this.countryCode, packagingSystem.version, true);
        }
        return of(null);
      });

      this.getQuantitySubscription = forkJoin(results).subscribe(result => {
        this.systemDataSource.data.forEach((packagingSystem, idx) => {
          const form = this.quantityForms.controls.find(c => c.value.id === packagingSystem.id);
          form?.patchValue({ quantity: result[idx] });
        });
        this.form.markAsPristine();
        this.isLoading$.next(false);
      });
    }
  }

  onMonthAndYearSelected(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.selectedMonthAndYear;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.selectGroup.controls.selectedMonthAndYear.setValue(ctrlValue);
    datepicker.close();
    this.loadQuantities();
  }

  onMonthChanged(amount: number) {
    const ctrlValue = this.selectedMonthAndYear;
    ctrlValue.add(amount, 'M');
    this.selectGroup.controls.selectedMonthAndYear.setValue(ctrlValue);
    this.loadQuantities();
  }

  onSubmit() {
    const quantityForms = this.quantityForms.controls.filter(control => !control.pristine);
    this.isLoading$.next(true);

    let responeses: Observable<any>[] = [];
    if (this.isPackagingUnit) {
      responeses = quantityForms.map(form => {
        return this.packagingUnitApiService.setPackagingUnitQuantity
          (form.value.id, this.selectedMonthAndYear.year(),
            +this.selectedMonthAndYear.format('M'), form.value.quantity, this.countryCode, form.value.version, true);
      });
    }
    if (this.isPackagingSystem) {
      responeses = quantityForms.map(form => {
        return this.packagingSystemApiService.setPackagingSystemQuantity
          (form.value.id, this.selectedMonthAndYear.year(),
            +this.selectedMonthAndYear.format('M'), form.value.quantity, this.countryCode, form.value.version, true);
      });
    }
    if (responeses.length > 0) {
      this.setQuantitySubscription = forkJoin(responeses).subscribe(_ => this.isLoading$.next(false));
    } else {
      this.isLoading$.next(false);
    }

    this.form.markAsPristine();
    this.setEnableDisable();
  }

  private createForm() {
    if (this.isPackagingUnit) {
      this.unitDataSource.data.forEach((packagingUnit, i) => {
        this.quantityForms.push(
          this.formBuilder.group({
            id: [packagingUnit.id],
            version: [packagingUnit.version],
            quantity: [null, [Validators.required, Validators.min(0)]]
          })
        );
      });
      this.selectGroup.setValidators(this.selectFormValidator());
    }
    if (this.isPackagingSystem) {
      this.systemDataSource.data.forEach((packagingSystem, i) => {
        this.quantityForms.push(
          this.formBuilder.group({
            id: [packagingSystem.id],
            version: [packagingSystem.version],
            quantity: [null, [Validators.required, Validators.min(0)]]
          })
        );
      });
      this.selectGroup.setValidators(this.selectFormValidator());
    }
  }

  private selectFormValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const countryCode = (control as FormGroup).controls.countryCode.value;
      const selectedMonthAndYear = (control as FormGroup).controls.selectedMonthAndYear.value;
      if (countryCode == null || selectedMonthAndYear == null) {
        return { allItemsRequired: true };
      }
      return null;
    };
  }

  private setEnableDisable() {
    if (!this.quantityForms.pristine || this.isLoading) {
      if (this.selectGroup.controls.countryCode.enabled || this.selectGroup.controls.selectedMonthAndYear.enabled) {
        this.selectGroup.controls.countryCode.disable();
        this.selectGroup.controls.selectedMonthAndYear.disable();
      }
    } else {
      if (this.selectGroup.controls.countryCode.disabled || this.selectGroup.controls.selectedMonthAndYear.disabled) {
        this.selectGroup.controls.countryCode.enable();
        this.selectGroup.controls.selectedMonthAndYear.enable();
      }
    }
  }

  addDistributionCountry(event: { id: number, version: number }) {
    const countryName = this.conutries.find(country => country.code === this.countryCode)?.name ?? '';
    const data: SimpleDialogData = {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('dataManagement.quantities.addDistrCountryConfirmation',
        { country: countryName, id: event.id })], icon: 'info'
    };
    const dialogConfig = getDialogConfig(data);
    const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      if (this.isPackagingUnit) {
        this.addCountrySubscription = this.baseDataApiService.addDistributionCountry(event.id, event.version, this.countryCode)
        .subscribe(_ => {
          this.packagingUnits.find(packagingUnit => packagingUnit.id === event.id)?.distributionCountries.push(this.countryCode);
          this.disableQuantities();
        });
      }
      if (this.isPackagingSystem) {
        this.addCountrySubscription = this.packagingSystemApiService.addDistributionCountry(event.id, event.version, this.countryCode)
        .subscribe(_ => {
          const distributionCountries =
            this.packagingSystems.find(packagingSystem => packagingSystem.id === event.id)?.distributionCountries ?? [];
            distributionCountries.push(this.countryCode);
          this.disableQuantities();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.getCountriesSubscription?.unsubscribe();
    this.addCountrySubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
    this.getQuantitySubscription?.unsubscribe();
    this.setQuantitySubscription?.unsubscribe();
    this.quantityChangesSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
  }
}
