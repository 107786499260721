import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { DictionaryHandler } from './../dictionary';
import { PackagingComponentPrototype, ComponentTableInfo } from './packaging-component-prototype';
import { PackagingAidEntryDto } from 'src/app/data-transfer/entities/component-entities/packaging-aid-entry-dto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PackagingAidDialogComponent } from 'src/app/components/dialogs/packaging-aid-dialog/packaging-aid-dialog.component';
import { PackagingComponentTypesEnum } from '../packaging-component-types-enum';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { PackagingAidDto } from 'src/app/data-transfer/entities/component-entities/packaging-aid-dto';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';
import { ComponentDialogData } from 'src/app/components/dialogs/additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';

export const PACKAGING_AID_NAME = 'packagingAid';

export class PackagingAidComponent extends PackagingComponentPrototype {

  constructor(
    protected dictionaryHandler: DictionaryHandler,
    private packagingAidSubtypes: ComponentSubtypeDto[],
    private countriesService: CountriesService
  ) {
    super(dictionaryHandler);
  }

  getComponentTableInfo(translate: (text: string, params?: any) => string): ComponentTableInfo {
    return {
      id: PackagingComponentTypesEnum.PackagingAid,
      label: PACKAGING_AID_NAME,
      text: translate('component.packagingAid'),
      columns: [
        {
          id: 'packagingComponentSubtypeName',
          text: translate('component.fields.type', { ofComponent: translate('component.fields.ofPackagingAid') })
        },
        { id: 'manufacturer', text: translate('component.fields.manufacturer') },
        { id: 'articleNumber', text: translate('component.fields.articleNumber') },
        { id: 'manifestationOverview', text: translate('component.fields.mainMaterialLayer') },
        { id: 'removedByConsumerValue', text: translate('component.fields.removalForDisposal') },
      ]
    };
  }

  wrapComponentInComponentEntry(componentDto: PackagingAidDto): PackagingAidEntryDto {
    const returnEntry = new PackagingAidEntryDto();
    returnEntry.removedBeforeUsage = false;
    returnEntry.removedByConsumer = false;
    returnEntry.underlyingComponent = componentDto;
    return returnEntry;
  }

  getFormFromComponentEntries(
    packagingAidEntryDtos: PackagingAidEntryDto[], handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    return formBuilder.group({
      packagingComponents: formBuilder.array(packagingAidEntryDtos.map(packagingAidEntryDto =>
        this.getFormFromSingleComponentEntry(packagingAidEntryDto, handler, formBuilder, isTracked)))
    });
  }

  getFormFromSingleComponentEntry(
    packagingAidEntryDto: PackagingAidEntryDto, handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    const packagingAidDto = packagingAidEntryDto.underlyingComponent;
    const baseForm = super.createBaseForm(packagingAidDto, formBuilder, this.packagingAidSubtypes,
      isTracked || packagingAidEntryDto.hasInternalTracking);
    const packAidFormGroup: FormGroup = formBuilder.group({
      ...baseForm.controls,
      entryId: [packagingAidEntryDto.entryId],
      removedBeforeUsage: [packagingAidEntryDto.removedBeforeUsage ?? null, [Validators.required]],
      removedByConsumer: [packagingAidEntryDto.removedByConsumer ?? null, [Validators.required]],
      manifestationOverview: [super.createManifestationOverview(packagingAidDto.multiMaterial?.layers ?? [])],
      manufacturingCountryName: [this.countriesService.getCountryNameByCode(packagingAidDto.manufacturingCountry ?? '')],
      removedByConsumerValue: [super.booleanDictionaryKeyToValue(packagingAidEntryDto.removedByConsumer ?? false)],
      hasInternalTracking: [packagingAidEntryDto.hasInternalTracking],
      errorCount: [0],
      multiMaterial: packagingAidDto.multiMaterial,
      printingCoverage: [packagingAidDto.printingCoverage != null ? +(packagingAidDto.printingCoverage * 100).toFixed(2) : null,
      [Validators.required, Validators.min(0), Validators.max(100)]],
      isRigidComponent: [packagingAidDto.isRigidComponent ?? null, [Validators.required]],
      customFields: [packagingAidEntryDto.customFields]
    });
    super.setErrorCountInPackagingComponent(packAidFormGroup);
    return packAidFormGroup;
  }

  getEmptyComponentEntry(): PackagingAidEntryDto {
    const packagingAidEntry = new PackagingAidEntryDto();
    packagingAidEntry.underlyingComponent = new PackagingAidDto();
    packagingAidEntry.underlyingComponent.packagingComponentSubtypeId = this.packagingAidSubtypes[0].id;
    return packagingAidEntry;
  }

  getComponentEntriesFromForm(formValues: any): PackagingAidEntryDto[] {
    const packagingAids: PackagingAidEntryDto[] = [];
    formValues.forEach((packagingAidGroup: any) => {
      packagingAids.push(this.getSingleComponentEntryFromForm(packagingAidGroup, false));
    });
    return packagingAids;
  }

  getSingleComponentEntryFromForm(packagingAidFormValue: any, forInternalUse: boolean): PackagingAidEntryDto {
    const packagingAidEntry = new PackagingAidEntryDto();
    this.setComponentEntryDataFromForm(packagingAidEntry, packagingAidFormValue);
    packagingAidEntry.underlyingComponent = this.getSingleComponentFromForm(packagingAidFormValue, forInternalUse);
    return packagingAidEntry;
  }

  private setComponentEntryDataFromForm(packagingAidEntry: PackagingAidEntryDto, packagingAidFormValue: any) {
    packagingAidEntry.entryId = packagingAidFormValue?.entryId ?? -1;
    packagingAidEntry.hasInternalTracking = packagingAidFormValue?.hasInternalTracking;
    packagingAidEntry.removedBeforeUsage = packagingAidFormValue?.removedBeforeUsage ?? null;
    packagingAidEntry.removedByConsumer = packagingAidFormValue?.removedByConsumer ?? null;
    packagingAidEntry.customFields = packagingAidFormValue?.customFields ?? null;
  }

  getSingleComponentFromForm(packagingAidFormValue: any, forInternalUse: boolean, component?: PackagingAidDto): PackagingAidDto {
    const packagingAidDto = component ?? new PackagingAidDto();
    packagingAidDto.id = packagingAidFormValue?.id;
    packagingAidDto.versionName = packagingAidFormValue?.newVersionName;
    packagingAidDto.packagingComponentSubtypeId = packagingAidFormValue?.packagingComponentSubtypeId;
    packagingAidDto.comment = packagingAidFormValue?.comment;
    packagingAidDto.manufacturer = packagingAidFormValue?.manufacturer;
    packagingAidDto.manufacturingCountry = packagingAidFormValue?.manufacturingCountry;
    packagingAidDto.articleName = packagingAidFormValue?.articleName;
    packagingAidDto.articleNumber = packagingAidFormValue?.articleNumber;
    packagingAidDto.gtin = packagingAidFormValue?.gtin;
    packagingAidDto.isRigidComponent = packagingAidFormValue?.isRigidComponent ?? null;
    packagingAidDto.length = packagingAidFormValue?.length;
    packagingAidDto.width = packagingAidFormValue?.width;
    packagingAidDto.height = packagingAidFormValue?.height;
    packagingAidDto.printingCoverage = forInternalUse ? packagingAidFormValue?.printingCoverage :
      packagingAidFormValue?.printingCoverage / 100;
    packagingAidDto.multiMaterial = packagingAidFormValue?.multiMaterial;
    packagingAidDto.containsNearInfraredBarrier = packagingAidFormValue?.containsNearInfraredBarrier ?? null;
    packagingAidDto.customFields = packagingAidFormValue?.customFields ?? null;
    if (packagingAidDto.multiMaterial) {
      packagingAidDto.multiMaterial.totalWeight = packagingAidFormValue?.totalWeight;
      packagingAidDto.multiMaterial.totalGrammage = packagingAidFormValue?.totalGrammage;
    }
    return packagingAidDto;
  }

  getDialogType() { return PackagingAidDialogComponent; }

  getComponentSubtypes(): ComponentSubtypeDto[] { return this.packagingAidSubtypes; }

  setComponentSpecificDialogData(_: ComponentDialogData): void { }

  setComponentSpecificTableData(formGroup: FormGroup): void {
    formGroup.controls.removedByConsumerValue.patchValue(
      super.booleanDictionaryKeyToValue(formGroup.controls.removedByConsumer.value) ?? false);
    formGroup.controls.manifestationOverview.patchValue(
      super.createManifestationOverview((formGroup.controls.multiMaterial as FormGroup).value.layers ?? []));
  }
}
