import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangeType, IMPORT_COST, ImportParentComponent } from '../import-parent/import-parent.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChangePackagingSystemCandidateDto } from 'src/app/data-transfer/entities/packaging-system-entities/change-packaging-system-candidate-dto';
import { ImportPackagingSystemCandidateDto } from 'src/app/data-transfer/entities/packaging-system-entities/import-packaging-system-candidate-dto';
import { ImportExportApiService } from 'src/app/data-transfer/services/import-export-api-service';
import { CreditsService } from 'src/app/services/credits-service';
import { ImportHtmlComponent } from '../import-html/import-html.component';
import { firstValueFrom, forkJoin } from 'rxjs';
import { PackagingSystemNavigationService } from 'src/app/navigation/services/navigation-services/packaging-system-navigation.service';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-import-packaging-system',
  templateUrl: './import-packaging-system.component.html',
  styleUrls: ['../import-packaging-unit/import-packaging-unit.component.scss']
})
export class ImportPackagingSystemComponent extends ImportParentComponent implements OnInit {

  @ViewChild('importHtml') importHtmlComponent!: ImportHtmlComponent;
  @ViewChild('changeHtml') changeHtmlComponent!: ImportHtmlComponent;

  selectedCandidates: ImportPackagingSystemCandidateDto[] = [];
  selectedChanges: ChangePackagingSystemCandidateDto[] = [];

  displayedColumns: string[] = [
    'itemId', 'exportingOrganization', 'brandName', 'productName', 'articleNumber', 'action', 'select'
  ];

  constructor(
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    private importExportApiService: ImportExportApiService,
    private creditsService: CreditsService,
    private navigationService: PackagingSystemNavigationService,
    private spinner: NgxSpinnerService,
    private analysisApiService: AnalysisApiService
  ) {
    super(dialog, route, translateService);
  }

  ngOnInit(): void {
    this.loadAllDataSources();
  }

  private loadAllDataSources() {
    this.spinner.show();
    const obsrevableImports = this.importExportApiService.getPackagingSystemImportCandidates();
    const obsrevableChanges = this.importExportApiService.getPackagingSystemTrackedChanges();

    this.loadDataSourceSubscription = forkJoin([obsrevableImports, obsrevableChanges]).subscribe(result => {
      this.dataSourceImports.data = result[0];
      this.importHtmlComponent.setDataSource(this.dataSourceImports);
      this.selectedCandidates = [];
      this.dataSourceChanges.data = result[1];
      this.changeHtmlComponent.setDataSource(this.dataSourceChanges);
      this.selectedChanges = [];
      this.spinner.hide();
    });
  }

  navigateToCandidate(candidate: ImportPackagingSystemCandidateDto) {
    this.navigationService.navigateToPackagingSystemPreview(candidate.id);
  }

  navigateToChange(candidate: ChangePackagingSystemCandidateDto, seePackagingUnitBeforeChange: boolean) {
    const id = seePackagingUnitBeforeChange ? candidate.existingPackagingSystem.id : candidate.importCandidateId;
    if (id == null) { return; }
    this.navigationService.navigateToPackagingSystemPreview(id, true, seePackagingUnitBeforeChange);
  }

  // ---------- Import ----------

  setSelectedImportCandidates(importCandidates: ImportPackagingSystemCandidateDto[]) {
    this.selectedCandidates = importCandidates;
  }

  removeCandidates() {
    const deleteFunction = (candidateId: number) => this.importExportApiService.deletePackagingSystemImportCandidate(candidateId);
    super.removeCandidates(this.selectedCandidates, deleteFunction, this.importHtmlComponent);
    this.selectedCandidates = [];
  }

  async proceedWithImport() {
    await super.selectDirectory();
    if (this.selectedImportType == null || this.selectedCandidates.length < 1 || this.targetDirectory == null) { return; }
    this.spinner.show();

    let recPossibleForAll = true;
    let lcaPossibleForAll = true;
    const observablesRec = this.selectedCandidates.map(candidate =>
      this.analysisApiService.isRecyclabilityAnalysisPossibleImport(candidate.id, PackagingPart.System));

    if (observablesRec.length > 0) {
      const isRecPossible: boolean[] = await firstValueFrom(forkJoin(observablesRec));
      recPossibleForAll = !isRecPossible.includes(false);
    }

    if (recPossibleForAll) {
      const observablesLca = this.selectedCandidates.map(candidate =>
        this.analysisApiService.isLifeCycleAnalysisPossibleImport(candidate.id, PackagingPart.System));

      if (observablesLca.length > 0) {
        const isLcaPossible: boolean[] = await firstValueFrom(forkJoin(observablesLca));
        lcaPossibleForAll = !isLcaPossible.includes(false);
      }
    }

    if (recPossibleForAll && lcaPossibleForAll) {
      this.doImport();
    } else {
      const data: SimpleDialogData = {
        title: this.translateService.instant('common.text.warning'),
        messages: [this.translateService.instant('warnings.importIncompletePackagingSystem')], icon: 'warning'
      };
      const confirmDialogConfig = getDialogConfig(data, '350px');
      const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, confirmDialogConfig);
      this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if (result.event === DialogActions.CONFIRM) {
          this.doImport();
        } else {
          this.spinner.hide();
          return;
        }
      });
    }
  }

  private async doImport() {
    const requiredCredits = this.selectedCandidates.length * IMPORT_COST;
    // TODO change to new credit type if import have a own credit type
    if (requiredCredits > this.creditsService.creditsCount.recyclabilityCredits) {
      this.navigationService.stopWhenCreditsInsufficient();
      this.spinner.hide();
      return;
    }
    const importFunction = (candidateId: number, targetDirId: number, isWithTracking: boolean) =>
      this.importExportApiService.importPackagingSystem(candidateId, targetDirId, isWithTracking);
    await super.importItems(this.selectedCandidates, importFunction);
    this.creditsService.setCreditsCount();
    this.loadAllDataSources();
  }

  // ---------- Changes ----------

  setSelectedImportChanges(changes: ChangePackagingSystemCandidateDto[]) {
    this.selectedChanges = changes;
  }

  removeChanges() {
    const deleteFunction = (changeId: number) => this.importExportApiService.ignorePackagingSystemChanges(changeId);
    const idsToDelete: number[] = this.selectedChanges.map(x => x.existingPackagingSystem.id ?? -1).filter(x => x !== -1);
    super.removeChanges(this.selectedChanges, deleteFunction, idsToDelete, this.changeHtmlComponent);
    this.selectedChanges = [];
  }

  async proceedWithChange() {
    let targetDirectoryRequired = false;
    if ([ChangeType.WithTrackingNew, ChangeType.WithoutTracking].includes(this.selectedChangeType)) {
      targetDirectoryRequired = true;
      await super.selectDirectory();
    }
    if (this.selectedChangeType == null || this.selectedChanges.length < 1 ||
      (this.targetDirectory == null && targetDirectoryRequired)) { return; }
    this.spinner.show();
    this.doChange()
  }

  private async doChange() {
    if (this.selectedChangeType === this.changeTypeEnum.WithTrackingOverwrite) {
      const changeFunction = (changeId: number) => this.importExportApiService.applyPackagingSystemChanges(changeId);
      const idsToChange: number[] = this.selectedChanges.map(x => x.existingPackagingSystem.id ?? -1).filter(x => x !== -1);
      await super.applyChangesExistingItems(idsToChange, changeFunction);
      this.selectedChanges = [];
    } else {
      const isWithTracking = this.selectedChangeType === this.changeTypeEnum.WithTrackingNew;
      const changeFunction = (candidateId: number, targetDirectoryId: number, isTracking: boolean) =>
        this.importExportApiService.importPackagingSystem(candidateId, targetDirectoryId, isTracking);
      const idsToChange: number[] = this.selectedChanges.map(x => x.importCandidateId);
      await super.applyChangesNewItems(idsToChange, isWithTracking, changeFunction);
      this.selectedChanges = [];
    }
    this.loadAllDataSources();
  }
}
